import styled from 'styled-components';
import { Button, Input, Row } from 'antd';
import Icon from '@ant-design/icons';

export const SkillInfoTitle = styled.div`
  height: 38px;
  font-size: 20px;
  font-weight: 550;
  background-color: var(--cl_gray200);
  border-radius: 5px;
  line-height: 38px;
  padding: 25px 10px 25px 10px;
  bottom: 1000px;
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SkillInfoTitleButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const SkillInfoButton = styled(Button)`
  font-size: var(--txt_font_size_body);
  border-width: 2px;
  border-color: var(--cl_primary500);
  background-color: transparent;
  color: var(--cl_primary500);
  border-radius: var(--bd_radius_default);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SkillInfoItem = styled.div`
  background-color: var(--cl_gray100);
  border-radius: 3px;
  padding: 5px 10px 5px 10px;
  margin-top: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SkillInfoItemButton = styled(Button)`
  background-color: transparent;
  border: none;
  margin-left: 20px;
`;

export const SkillNameInput = styled(Input)`
  background-color: white;
  color: var(--cl_gray900);
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  text-align: center;
`;

export const SkillDescInput = styled(Input.TextArea)`
  border-radius: 4px;
  min-height: 100px !important;
`;

export const SkillDescButtonContainer = styled.div`
  position: absolute;
  right: 5px;
  bottom: -5px;
`;

export const SkillDescButton = styled(Button)`
  border: none;
  background-color: transparent;
`;

// Mainkill
export const MainSkillInfoItemName = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 3px 30px;
  border-color: 1px solid var(--cl_gray300);
  box-sizing: border-box;
  border-radius: 4px;
  color: var(--cl_gray900);
  font-size: 14px;
  font-weight: 500;
  border-width: 2px;
`;

export const MainSkillInfoItemNameIcon = styled(Icon)`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 10px;
`;

export const MainSkillInfoItemYoe = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--cl_gray900);
  font-size: 14px;
  font-weight: 500;
  margin-left: 20px;
`;

export const MainSkillInfoItemButtonContainer = styled.div`
  margin: 0px;
`;

export const MainSkillYoeLabel = styled.h4`
  color: var(--cl_gray900);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 25px;
  text-align: right;
`;

export const MainSkillYoeInput = styled(Input)`
  background-color: var(--cl_white);
  color: var(--cl_gray900);
  font-size: 14px;
  font-weight: 500;
  border: none;
`;

export const SkillDescInputContainer = styled.div`
  margin-top: 16px;
`;

export const MainSkillContainerInfo = styled(Row)`
  cursor: pointer;
`;

// Softkill
export const SoftSkillInfoItem = styled.div`
  background-color: white;
  padding: 0px 10px 3px 10px;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  border-color: var(--cl_gray900);
  color: var(--cl_gray900);
  font-size: 14px;
  font-weight: 500;
  border: 1px solid var(--cl_gray300);
  display: inline-flex;
  margin-right: 20px;
  margin-bottom: 10px;
`;

export const SoftSkillContainerInfo = styled.div`
  cursor: pointer;
`;
