import { Col, Radio, Row } from 'antd';
import { DatePickerField, InputField, SelectField } from 'components/FormFields';
import moment from 'moment';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface IItemInput {
  label: string;
  name: string;
  required?: boolean;
  options?: Array<any>;
  fieldType: 'select' | 'input' | 'datePicker' | 'radio';
}

const PersonalInfo = ({ handleChange, data, visible }) => {
  const { t } = useTranslation(['recruitment', 'action', 'dashboard']);
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: undefined,
    defaultValues:
      { ...data, source: data?.source || 'other', dob: data?.dob ? moment(data.dob) : null } || {},
  });
  useEffect(() => {
    if (data)
      reset(
        { ...data, source: data?.source || 'other', dob: data?.dob ? moment(data.dob) : null } ||
          {},
      );
  }, [data, reset]);

  const onSubmit = (data: any) => {
    handleChange && handleChange(data);
  };

  const itemsInputLeft: Array<IItemInput> = [
    {
      label: t('recruitment:candidate_name'),
      name: 'candidateName',
      fieldType: 'input',
      required: true,
    },
    {
      label: t('recruitment:date_of_birth'),
      name: 'dob',
      fieldType: 'datePicker',
    },
    {
      label: t('recruitment:gender'),
      name: 'gender',
      fieldType: 'radio',
      options: [
        { label: t('dashboard:male'), value: 'Male' },
        { label: t('dashboard:female'), value: 'Female' },
      ],
    },
    {
      label: t('recruitment:expected_job_location'),
      name: 'expectedLocation',
      fieldType: 'input',
    },
    {
      label: 'Email',
      name: 'email',
      fieldType: 'input',
      required: true,
    },
  ];

  const itemsInputRight: Array<IItemInput> = [
    {
      label: t('recruitment:contact_number'),
      name: 'phone',
      fieldType: 'input',
      required: true,
    },
    {
      label: t('recruitment:home_address'),
      name: 'address',
      fieldType: 'input',
    },
    {
      label: t('recruitment:year_of_experience'),
      name: 'yearExperience',
      fieldType: 'input',
      required: true,
    },
    // {
    //   label: 'Job Type',
    //   name: 'jobType',
    //   fieldType: 'select',
    // },
    {
      label: t('recruitment:linkedin_profile'),
      name: 'linkedin',
      fieldType: 'input',
    },
    {
      label: t('recruitment:source'),
      name: 'source',
      fieldType: 'select',
      options: [
        { label: t('recruitment:websites'), value: 'websites' },
        { label: t('recruitment:introduce_staff'), value: 'introduce_staff' },
        { label: t('recruitment:internal_recruitment'), value: 'internal_recruitment' },
        { label: t('recruitment:headhunter'), value: 'headhunter' },
        { label: t('recruitment:other'), value: 'other' },
      ],
    },
  ];

  const renderItemEdit = (
    fieldType: 'select' | 'input' | 'datePicker' | 'radio',
    item: IItemInput,
  ) => {
    switch (fieldType) {
      case 'select':
        return (
          <SelectField
            control={control}
            options={item?.options || []}
            name={item.name}
            onChange={handleSubmit(onSubmit)}
            disabled={!visible}
          />
        );
      case 'input':
        return (
          <InputField
            control={control}
            name={item.name}
            onChange={handleSubmit(onSubmit)}
            disabled={!visible}
          />
        );
      case 'datePicker':
        return (
          <DatePickerField
            style={{ width: '100%' }}
            control={control}
            name={item.name}
            onChange={handleSubmit(onSubmit)}
            disabled={!visible}
          />
        );
      case 'radio':
        return (
          <Controller
            name={item.name}
            control={control}
            render={({ field }) => (
              <Radio.Group
                {...field}
                options={item?.options || []}
                onChange={(e) => {
                  field.onChange(e);
                  handleSubmit(onSubmit)();
                }}
                disabled={!visible}
              />
            )}
          />
        );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row gutter={64}>
        <Col span={12}>
          {itemsInputLeft.map((item) => (
            <Row key={item.name} style={{ marginBottom: 8 }}>
              <Col span={8}>
                <label>
                  {item.label}
                  {item.required && <span style={{ color: 'red' }}> *</span>}
                </label>
              </Col>
              <Col span={16}>{renderItemEdit(item.fieldType, item)}</Col>
            </Row>
          ))}
        </Col>
        <Col span={12}>
          {itemsInputRight.map((item) => (
            <Row key={item.name} style={{ marginBottom: 8 }}>
              <Col span={8}>
                <label>
                  {item.label}
                  {item.required && <span style={{ color: 'red' }}> *</span>}
                </label>
              </Col>
              <Col span={16}>{renderItemEdit(item.fieldType, item)}</Col>
            </Row>
          ))}
        </Col>
      </Row>
    </form>
  );
};
export default PersonalInfo;
