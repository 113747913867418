import { Col, InputNumber, Row } from 'antd';
import payrollDashboardApi from 'api/payrollDashboardApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { DatePickerField, InputField, SelectField, TextAreaField } from 'components/FormFields';
import { RecruitJobDetailType } from 'features/recruitment/jobs/components/types';
import { listJobTypes } from 'features/recruitment/recruitmentAction';
import { selectRecruitmentData } from 'features/recruitment/recruitmentSlice';
import moment from 'moment';
import { Dispatch, useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface IItemInput {
  label: string;
  name: string;
  options?: Array<any>;
  fieldType: 'select' | 'input' | 'datePicker' | 'salary' | 'textarea';
  required?: boolean;
}

const JobDetails = ({
  data,
  handleChange,
  visible,
  setVisible,
}: {
  data?: RecruitJobDetailType;
  handleChange: (value: any) => void;
  visible: boolean;
  setVisible: Dispatch<React.SetStateAction<boolean>>;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['recruitment']);
  const listJobTypesData = useAppSelector(selectRecruitmentData)?.listJobTypes?.result;
  const [location, setLocation] = useState([]);
  const [department, setDepartment] = useState([]);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: undefined,
    defaultValues: useMemo(() => {
      return (
        {
          ...data,
          openDate: data?.openDate ? moment(data.openDate) : null,
          closeDate: data?.closeDate ? moment(data.closeDate) : null,
          status: data?.status,
        } || {}
      );
    }, [data]),
  });

  useEffect(() => {
    const fetchData = async () => {
      const responseDataLocation = await payrollDashboardApi.getLocation();
      setLocation(responseDataLocation?.data?.result || []);
      const responseDataProject = await payrollDashboardApi.getProject();
      setDepartment(responseDataProject?.data?.result || []);
    };
    fetchData();
    dispatch(listJobTypes({}));
  }, [dispatch]);

  useEffect(() => {
    if (data)
      reset(
        {
          ...data,
          openDate: data?.openDate ? moment(data.openDate) : null,
          closeDate: data?.closeDate ? moment(data.closeDate) : null,
          status: data?.status,
        } || {},
      );
  }, [data, reset]);

  const onSubmit = (data: any) => {
    handleChange && handleChange(data);
  };

  const itemsInputLeft: Array<IItemInput> = [
    {
      label: t('recruitment:job_id'),
      name: 'jobCode',
      fieldType: 'input',
    },
    {
      label: t('recruitment:job_location'),
      name: 'jobTemplate.location',
      fieldType: 'select',
      options: location?.map((i: any) => ({ label: i?.name, value: i?.id })),
      required: true,
    },
    {
      label: t('recruitment:job_department'),
      name: 'jobTemplate.department',
      fieldType: 'select',
      options: department?.map((i: any) => ({ label: i?.name, value: i?.projectCode })),
      required: true,
    },
    {
      label: t('recruitment:headcount'),
      name: 'jobTemplate.headcount',
      fieldType: 'input',
    },
    {
      label: t('recruitment:minimum_salary'),
      name: 'jobTemplate.minimumSalary',
      fieldType: 'salary',
      required: true,
    },
    {
      label: t('recruitment:maximum_salary'),
      name: 'jobTemplate.maximumSalary',
      fieldType: 'salary',
      required: true,
    },
  ];

  const itemsInputRight: Array<IItemInput> = [
    {
      label: t('recruitment:position_name'),
      name: 'jobTemplate.positionName',
      fieldType: 'input',
      required: true,
    },
    {
      label: t('recruitment:job_type'),
      name: 'jobTemplate.jobType',
      fieldType: 'select',
      options: listJobTypesData?.map((i: any) => ({ label: i?.name, value: i?.id })),
      required: true,
    },
    {
      label: t('recruitment:open_date'),
      name: 'openDate',
      fieldType: 'datePicker',
    },
    {
      label: t('recruitment:close_date'),
      name: 'closeDate',
      fieldType: 'datePicker',
    },
    {
      label: t('recruitment:job_status'),
      name: 'status',
      fieldType: 'input',
    },
    {
      label: t('recruitment:created_by'),
      name: 'createdBy',
      fieldType: 'input',
    },
  ];

  const itemsInputBelow: Array<IItemInput> = [
    {
      label: t('recruitment:reason'),
      name: 'createdReason',
      fieldType: 'textarea',
      required: true,
    },
  ];

  const renderItemEdit = (
    fieldType: 'select' | 'input' | 'datePicker' | 'salary' | 'textarea',
    item: IItemInput,
  ) => {
    switch (fieldType) {
      case 'select':
        return (
          <SelectField
            disabled={!visible}
            onChange={handleSubmit(onSubmit)}
            control={control}
            options={item?.options || []}
            name={item.name}
          />
        );
      case 'input':
        return (
          <InputField
            disabled={item.name === 'status' || item.name === 'createdBy' ? true : !visible}
            onChange={handleSubmit(onSubmit)}
            control={control}
            name={item.name}
          />
        );
      case 'datePicker':
        return (
          <DatePickerField
            disabled={!visible}
            onChange={handleSubmit(onSubmit)}
            style={{ width: '100%' }}
            control={control}
            name={item.name}
          />
        );
      case 'salary':
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Controller
              name={item.name}
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  disabled={!visible}
                  style={{ width: '100%' }}
                  min={0}
                  placeholder="******"
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                  onChange={(value) => {
                    field.onChange(value);
                    handleSubmit(onSubmit)();
                  }}
                />
              )}
            />
            <SelectField
              style={{ width: 100 }}
              disabled={!visible}
              onChange={handleSubmit(onSubmit)}
              control={control}
              options={[
                { label: 'USD', value: 'USD' },
                { label: 'VND', value: 'VND' },
              ]}
              name="jobTemplate.currencySymbol"
            />
          </div>
        );
      case 'textarea':
        return (
          <TextAreaField
            disabled={!visible}
            onChange={handleSubmit(onSubmit)}
            style={{ width: '100%' }}
            autoSize={{ minRows: 4, maxRows: 5 }}
            control={control}
            name={item.name}
          />
        );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row gutter={64}>
        <Col span={12} style={{ paddingRight: 0 }}>
          {itemsInputLeft.map((item) => (
            <Row key={item.name} style={{ marginBottom: 8 }}>
              <Col span={8}>
                <label>
                  {item.label}
                  {item.required && <span style={{ color: 'red' }}> *</span>}
                </label>
              </Col>
              <Col span={16}>{renderItemEdit(item.fieldType, item)}</Col>
            </Row>
          ))}
        </Col>
        <Col span={12}>
          {itemsInputRight.map((item) => (
            <Row key={item.name} style={{ marginBottom: 8 }}>
              <Col span={8}>
                <label>
                  {item.label}
                  {item.required && <span style={{ color: 'red' }}> *</span>}
                </label>
              </Col>
              <Col span={16}>{renderItemEdit(item.fieldType, item)}</Col>
            </Row>
          ))}
        </Col>
      </Row>
      <Row gutter={64}>
        <Col span={24}>
          {itemsInputBelow.map((item) => (
            <Row key={item.name} style={{ marginBottom: 8 }}>
              <Col span={4}>
                <label>
                  {item.label}
                  {item.required && <span style={{ color: 'red' }}> *</span>}
                </label>
              </Col>
              <Col span={20}>{renderItemEdit(item.fieldType, item)}</Col>
            </Row>
          ))}
        </Col>
      </Row>
    </form>
  );
};
export default JobDetails;
