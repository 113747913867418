import { Checkbox, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { selectFormatMonth } from 'app/commonRedux/appSlice';
import { useAppSelector } from 'app/hooks';
import ITVTable from 'components/ITVTable';
import { selectPermissions } from 'features/auth/authSlice';
import EmployeeColumn from 'features/employee/components/EmployeeColumn';
import { formatCurrencyUnitPdf } from 'features/insurances/constants/common';
import StatusColumnCell from 'features/payroll/pages/ViewEmployee/components/StatusColumnCell';
import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { TitleColumnCenter } from 'styles/tableStyled';
import { IBonusList } from 'types';

interface BonusListProps {
  loading: boolean;
  data: IBonusList[];
  totalResult: number;
  offset: number;
  limit: number;
  setOffset: Dispatch<SetStateAction<number>>;
  setLimit: Dispatch<SetStateAction<number>>;
  onTableChange?: (
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any>,
  ) => void;
  onRefresh?: () => void;
  monthYear: string;
}

export function BonusList({
  loading,
  data,
  totalResult,
  offset,
  limit,
  setOffset,
  setLimit,
  onTableChange,
  onRefresh,
  monthYear,
}: BonusListProps) {
  const { t } = useTranslation(['employee']);

  const dataFormatMonth = useAppSelector(selectFormatMonth);
  const permissions = useAppSelector(selectPermissions);

  const COLUMNS: any = [
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('employee:employee_information_fields.id')}</TitleColumnCenter>
        );
      },
      dataIndex: 'employeeId',
      key: 'employeeId',
      ellipsis: true,
      width: 60,
      minWidth: 60,
      align: 'center',
      fixed: 'left',
      sorter: true,
      render: function (text: any, record: any, index: any) {
        const trueIndex =
          offset === 1 ? index + limit * (offset - 1) : index + limit * (offset - 1) - limit;
        const obj: any = {
          children: record?.user?.employeeId,
          props: {},
        };
        if (index >= 1 && record?.userId === data?.[trueIndex - 1]?.userId) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== data?.length && record?.userId === data?.[trueIndex + i]?.userId;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('employee:employee_information_fields.employee')}
          </TitleColumnCenter>
        );
      },
      dataIndex: 'fullName',
      key: 'fullName',
      width: 220,
      minWidth: 220,
      ellipsis: true,
      fixed: 'left',
      sorter: true,
      render: function (text: any, record: any, index: any) {
        const trueIndex =
          offset === 1 ? index + limit * (offset - 1) : index + limit * (offset - 1) - limit;
        const obj: any = {
          children: (
            <EmployeeColumn
              avatar={record?.user?.fullName}
              fullName={record?.user?.fullName}
              email={record?.user?.email}
            />
          ),
          props: {},
        };
        if (index >= 1 && record?.userId === data?.[trueIndex - 1]?.userId) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== data?.length && record?.userId === data?.[trueIndex + i]?.userId;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
    {
      title: t('employee:bonus_information_fields.bonus_name'),
      dataIndex: 'name',
      key: 'name',
      width: 200,
      minWidth: 200,
      render: function (text: any, record: any, index: any) {
        return record?.bonusHistory?.name;
      },
    },
    {
      title: t('employee:allowance_information_fields.is_pay_taxes'),
      dataIndex: 'isTaxed',
      key: 'isTaxed',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        return <Checkbox disabled checked={record?.bonusHistory?.isTaxed}></Checkbox>;
      },
    },
    {
      title: t('employee:bonus_information_fields.col_month'),
      dataIndex: 'monthYear',
      key: 'monthYear',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return text ? moment(text).format(dataFormatMonth) : null;
      },
    },
    {
      title: t('employee:allowance_information_fields.money'),
      dataIndex: 'money',
      key: 'money',
      width: 200,
      minWidth: 200,
      align: 'right',
      render: function (text: any, record: any, index: any) {
        return formatCurrencyUnitPdf(text);
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('employee:employee_information_fields.action')}</TitleColumnCenter>
        );
      },
      dataIndex: 'action',
      key: 'action',
      width: 60,
      minWidth: 60,
      align: 'center',
      fixed: 'right',
      render: (status: any, record: any, index: any) => {
        const trueIndex =
          offset === 1 ? index + limit * (offset - 1) : index + limit * (offset - 1) - limit;
        const obj: any = {
          children: (
            <StatusColumnCell
              showMenu={'bonus'}
              t={t}
              isShowFullMenu={true}
              item={record?.user}
              permissions={permissions}
              monthYear={monthYear}
              callBack={() => onRefresh?.()}
            />
          ),
          props: {},
        };
        if (index >= 1 && record?.userId === data?.[trueIndex - 1]?.userId) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== data?.length && record?.userId === data?.[trueIndex + i]?.userId;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
  ];

  return (
    <ITVTable
      columns={COLUMNS}
      data={data}
      rowKeyMerge={'userId'}
      setSelectedUser={() => {}}
      loading={loading}
      totalResult={totalResult}
      setOffset={setOffset}
      offset={offset}
      setLimit={setLimit}
      limit={limit}
      onChange={onTableChange}
    />
  );
}
