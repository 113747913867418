import moment from 'moment';
import { PROFILE_DRAFT } from 'constants/profiles';

export const EMPLOYEE_KEY_MENU = {
  employeeMenuKey: 'employee-menu-key',
  personalMenuKey: 'personal-menu-key',
  skillMenuKey: 'skill-menu-key',
  historyMenuKey: 'history-menu-key',
  allowanceMenuKey: 'allowance-menu-key',
  bonusMenuKey: 'bonus-menu-key',
  salaryMenuKey: 'salary-menu-key',
  dependantsMenuKey: 'dependants-menu-key',
  advancesMenuKey: 'advances-menu-key',
  contractMenuKey: 'contract-menu-key',
  adjustmentMenuKey: 'adjustment-menu-key',
  certificatesMenuKey: 'certificate-menu-key',
};

export const INITIAL_EMPLOYEE = {
  id: '',
  email: '',
  status: PROFILE_DRAFT,
  endDate: null,
  onBoardStatus: null,
  onBoardStatusId: '1',
  onBoardStatusValue: '',
  departments: [],
  positions: [],
  positionName: '',
  roles: [],
  roleName: '',
  level: null,
  levelId: '',
  managers: [],
  managerName: '',
  taxCode: '',
  startDate: null,
  socialInsuranceNumber: '',
  contractType: null,
  contractTypeId: '',
  contractStartDate: null,
  contractExpirationDate: null,
  projectPrimary: '',
  projectPrimaryName: '',

  firstName: '',
  lastName: '',
  dob: moment('1980-01-01'),
  phoneNumber: '',
  personalEmail: '',
  sex: null,
  sexId: '',

  placeOfBirth: '',
  originalPlace: '',
  maritalStatus: null,
  maritalStatusId: '',
  permanentAddress: '',
  temporaryAddress: '',
  nationality: null,
  religion: null,
  religionId: '',
  ethnicity: null,
  ethnicityId: '',
  citizenId: '',
  // cidIssuedDate: moment().subtract(20, 'years'),
  cidIssuedDate: null,
  cidPlaceOfIssue: '',

  school: '',
  trainingPeriodFrom: null,
  trainingPeriodTo: null,
  degree: null,
  degreeId: '',
  schoolMajor: '',

  bankAccount: '',
  bankName: '',
  bankBranch: '',

  relative: [],
  skill: {
    mainSkill: [],
    softSkill: [],
    certificates: [],
  },

  allowance: [],
  dependants: [],
  location: null,
};

export const INIT_RELATIVE_ITEM = {
  name: '',
  yearOfBirth: moment('1970/01/01'),
  occupation: '',
  relationship: null,
  phoneNumber: '',
  email: '',
};

export const INIT_MAIN_SKILL_ITEM = {
  name: '',
  description: '',
  yearOfExperience: '',
};

export const INIT_SOFT_SKILL_ITEM = {
  name: '',
  description: '',
};

export const INIT_CERT_ITEM = {
  name: '',
  file: '',
  link: '',
};

export const INIT_ALLOWANCE_ITEM = {
  name: '',
  money: 0,
  isPayTaxes: false,
  isCalculateByDay: false,
  startDate: moment(),
  endDate: moment(),
};

export const FILE_TYPE_ACCEPT_IMPORT = [
  '.csv',
  'text/csv',
  'application/csv',
  'text/comma-separated-values',
  'application/csv',
  'application/excel',
  'application/vnd.msexcel',
  'text/anytext',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
