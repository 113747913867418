import React, { useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Button, Input, Row, Col, Rate } from 'antd';
import CustomButton from 'styles/buttonStyled';
import { useTranslation } from 'react-i18next';

const SkillsField = ({
  name,
  onChange,
  disabled,
}: {
  name: string;
  onChange: (data: any) => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation(['recruitment', 'action']);
  const [isFocused, setIsFocused] = useState(false);
  const { control, handleSubmit } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const onSubmit = (data: any) => {
    onChange(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {fields.map((item, index) => (
        <Row key={item.id} gutter={8}>
          <Col span={disabled ? 12 : 10}>
            <Controller
              name={`${name}[${index}].name`}
              control={control}
              defaultValue={item.name}
              render={({ field }) => (
                <Input
                  placeholder={disabled ? undefined : 'Skill Name'}
                  {...field}
                  disabled={disabled}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => {
                    setIsFocused(false);
                    handleSubmit(onSubmit)();
                  }}
                  onChange={(value) => {
                    field.onChange(value);
                    if (!isFocused) {
                      handleSubmit(onSubmit)();
                    }
                  }}
                />
              )}
            />
          </Col>
          <Col span={disabled ? 12 : 10}>
            <Controller
              name={`${name}[${index}].yearOfExperience`}
              control={control}
              defaultValue={item.yearOfExperience}
              render={({ field }) => (
                <Input
                  placeholder={disabled ? undefined : t('recruitment:year_of_experience')}
                  {...field}
                  disabled={disabled}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => {
                    setIsFocused(false);
                    handleSubmit(onSubmit)();
                  }}
                  onChange={(value) => {
                    field.onChange(value);
                    if (!isFocused) {
                      handleSubmit(onSubmit)();
                    }
                  }}
                />
              )}
            />
          </Col>
          {!disabled && (
            <Col span={4}>
              <CustomButton
                style={{ width: 50 }}
                onClick={() => {
                  remove(index);
                  handleSubmit(onSubmit)();
                }}
              >
                x
              </CustomButton>
            </Col>
          )}
          <Col span={20}>
            <Controller
              name={`${name}[${index}].rate`}
              control={control}
              defaultValue={item.rate}
              render={({ field }) => (
                <Rate
                  {...field}
                  count={10}
                  disabled={disabled}
                  onChange={(value) => {
                    field.onChange(value);
                    handleSubmit(onSubmit)();
                  }}
                />
              )}
            />
          </Col>
        </Row>
      ))}
      <Button
        type="dashed"
        style={{ marginTop: 8 }}
        disabled={disabled}
        onClick={() => {
          append({ name: '', yearOfExperience: '', rate: 0 });
          handleSubmit(onSubmit)();
        }}
      >
        Add Skill
      </Button>
    </form>
  );
};

export default SkillsField;
