import axiosClient from './axiosClient';

const recruitApi = {
  recruitAction: (token: any) => {
    const response = axiosClient.post(
      `/recruitment/candidate-email/approve-reject-by-token`,
      token,
    );
    return response;
  },
  updateDetailCandidateInJob: (params: any) => {
    const response = axiosClient.put(
      `/recruitment/candidate/update-candidate-process/${params?.id}`,
      params?.body,
    );
    return response;
  },
};

export default recruitApi;
