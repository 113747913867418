import queryString from 'query-string';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosClient from 'api/axiosClient';
import { setGlobalLoading } from 'app/commonRedux/appSlice';
import { notificationToast } from 'components/notificationToast';
import { actions } from './overtimeSlice';
import { Notification } from 'constants/notification';

export const createProject = createAsyncThunk('/project', async (params: any, thunkApi: any) => {
  const { setErrorMessage } = actions;
  try {
    thunkApi.dispatch(setGlobalLoading({ loading: true }));
    const response = await axiosClient.post(`/project`, params?.body);
    response && params?.callBack();
    return response;
  } catch (error) {
    thunkApi.dispatch(setErrorMessage(error));
    //return thunkApi.rejectWithValue(error);
  } finally {
    thunkApi.dispatch(setGlobalLoading({ loading: false }));
  }
});

export const getProjectList = createAsyncThunk('/project', async (_, thunkApi: any) => {
  try {
    const response = await axiosClient.get(`/project`);
    return response;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getHistoryProjectManageProjectDetail = createAsyncThunk(
  '/project/history/search',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(
        `/project/history/search?${queryString.stringify(params)}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getHistoryProjectManageUserDetail = createAsyncThunk(
  '/project-detail/history/search',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(
        `/project-detail/history/search?${queryString.stringify(params)}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getHistoryPlanProjectDetail = createAsyncThunk(
  '/overtime/request-approve/history/search',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(
        `/overtime/request-approve/history/search?${queryString.stringify(params)}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getHistoryPlanUserDetail = createAsyncThunk(
  '/overtime/user-request/history/search',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(
        `/overtime/user-request/history/search?${queryString.stringify(params)}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getProjectDetail = createAsyncThunk(
  '/project/get-detail',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(`project/${params?.projectCode}`);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const editProject = createAsyncThunk('/project', async (params: any, thunkApi: any) => {
  const { setErrorMessage } = actions;
  try {
    thunkApi.dispatch(setGlobalLoading({ loading: true }));
    const response = await axiosClient.put(`/project/${params?.projectCode}`, params?.body);
    response && params?.callBack();
    return response;
  } catch (error) {
    return thunkApi.dispatch(setErrorMessage(error));
  } finally {
    thunkApi.dispatch(setGlobalLoading({ loading: false }));
  }
});

export const deleteProject = createAsyncThunk('/project', async (params: any, thunkApi: any) => {
  try {
    thunkApi.dispatch(setGlobalLoading({ loading: true }));
    const response = await axiosClient.delete(`/project/${params.projectCode}`);
    response && params?.callBack();
    return response;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  } finally {
    thunkApi.dispatch(setGlobalLoading({ loading: false }));
  }
});

//Overtime Request
export const getOtRequestHistoryProject = createAsyncThunk(
  '/overtime/request-approve/history',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.post(
        `/overtime/request-approve/history?id=${params?.projectCode}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getOtRequestHistoryUser = createAsyncThunk(
  '/overtime/user-request/history',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(
        `/overtime/user-request/history?id=${params?.projectCode}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getOtRequestList = createAsyncThunk(
  '/overtime/request-approve/user',
  async ({ userId, ...params }: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(`/overtime/request-approve/user/${userId}`, {
        params,
      });

      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getOtRequestForEdit = createAsyncThunk(
  '/overtime/request-approve/detail',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(`/overtime/request-approve/${params?.id}/detail`);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const createOtRequest = createAsyncThunk(
  '/overtime/request-approve',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.post(`/overtime/request-approve`, params?.body);
      response && params?.callBack();
      return response;
    } catch (error) {
      return thunkApi.dispatch(setErrorMessage(error));
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const editOtRequest = createAsyncThunk(
  '/overtime/request-approve',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.put(
        `/overtime/request-approve/${params?.id}`,
        params?.body,
      );
      response && params?.callBack();
      return response;
    } catch (error) {
      return thunkApi.dispatch(setErrorMessage(error));
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getProjectListCurrentUser = createAsyncThunk(
  '/project/user',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.get(
        `/project/user/${params?.id}?isProjectLeader=${Boolean(params?.isProjectLeader)}${
          params?.month && params?.year ? `&monthYear=${params?.year}-${params?.month}` : ''
        }`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const deleteRequestOt = createAsyncThunk(
  '/overtime/request-approve',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.delete(`/overtime/request-approve/${params?.id}`);
      response && params?.callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

//Overtime approve
export const getApproveOt = createAsyncThunk(
  'overtime/request-approve/search',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(
        `overtime/request-approve/search?${queryString.stringify(params)}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getUserApproveOt = createAsyncThunk(
  'overtime/user-request/search/user',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(
        `/overtime/user-request/search/user?${queryString.stringify(params)}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const approveRejectRequestOt = createAsyncThunk(
  'overtime/request-approve/update/multi',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.put(`overtime/request-approve/update/multi`, params?.body);
      response && params?.callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getOvertimeType = createAsyncThunk(
  '/overtime/type/get',
  async (params: { data: any; callBack?: any }, thunkApi: any) => {
    const { data, callBack } = params;
    try {
      const response = await axiosClient.get(
        `/overtime/type?limit=${data.limit}&offset=${data.offset}&type=${data.type}`,
      );
      response && callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const getOvertimeAllType = createAsyncThunk(
  '/overtime/type/get-all-type',
  async (params: { data: any; callBack?: any }, thunkApi: any) => {
    const { data, callBack } = params;
    try {
      const response = await axiosClient.get(
        `/overtime/type?limit=${data.limit}&offset=${data.offset}`,
      );
      response && callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const postOvertimeType = createAsyncThunk(
  '/overtime/type/post',
  async (params: { data: any; callBack?: any }, thunkApi: any) => {
    const { data, callBack } = params;
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.post(`/overtime/type`, data.body);
      response && callBack(response.data);
      thunkApi.dispatch(getOvertimeType({ data: { type: data.body.name, limit: 10, offset: 1 } }));
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.dispatch(setErrorMessage(error));
    }
  },
);
export const updateTaxCoefficient = createAsyncThunk(
  '/overtime/type/post',
  async (params: { data: any; name: string; callBack?: any }, thunkApi: any) => {
    const { data, name, callBack } = params;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.post(`/overtime/type/update-coefficient`, data);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      thunkApi.dispatch(getOvertimeType({ data: { type: name, limit: 10, offset: 1 } }));
      response && callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const updateOvertimeType = createAsyncThunk(
  '/overtime/type/update',
  async (params: { data: any; callBack?: any }, thunkApi: any) => {
    const { data, callBack } = params;
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.put(`/overtime/type/${data.id}`, data.body);
      response && callBack(response.data);
      thunkApi.dispatch(getOvertimeType({ data: { type: data.body.name, limit: 10, offset: 1 } }));
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.dispatch(setErrorMessage(error));
    }
  },
);
export const deleteOvertimeType = createAsyncThunk(
  '/overtime/type/delete',
  async (params: { id: number; name: string; callBack?: any }, thunkApi: any) => {
    const { id, name, callBack } = params;
    try {
      const response: any = await axiosClient.delete(`/overtime/type/${id}`);
      thunkApi.dispatch(getOvertimeType({ data: { type: name, limit: 10, offset: 1 } }));
      response && callBack && callBack(response.data);
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const getAllHistoryOtType = createAsyncThunk(
  '/overtime/type/history',
  async (params: { data: any; callBack?: any }, thunkApi: any) => {
    const { data, callBack } = params;
    try {
      const response = await axiosClient.get(
        `/overtime/type-history?limit=${data.limit}&offset=${data.offset}&search=${data.id}`,
      );
      response && callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const exportByProject = createAsyncThunk(
  'overtime/export-project',
  async (params: { projectIds: any }, thunkApi: any) => {
    const { projectIds } = params;
    try {
      let str: any = `Overtime_Approval_By_Project.xlsx`;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.post(
        `overtime/export-project`,
        { projectIds },
        { responseType: 'blob' },
      );
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', str);
      document.body.appendChild(link);
      link.click();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const exportByUser = createAsyncThunk(
  'overtime/export-overtime',
  async (params: { body: any }, thunkApi: any) => {
    const { body } = params;
    try {
      let str: any = `Overtime_Approval_By_User.xlsx`;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.post(
        `overtime/export-overtime`,
        { ...body },
        { responseType: 'blob' },
      );
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', str);
      document.body.appendChild(link);
      link.click();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const exportByUserDetail = createAsyncThunk(
  'overtime/export-overtime/user-detail',
  async (params: { body: any }, thunkApi: any) => {
    const { body } = params;
    try {
      let str: any = `Overtime_Approval_By_User_Detail.xlsx`;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.post(
        `overtime/export-overtime/user-detail`,
        { ...body },
        { responseType: 'blob' },
      );
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', str);
      document.body.appendChild(link);
      link.click();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getProjectByDate = createAsyncThunk(
  '/project/user/:userId/overtime-request-approve?dateRequest',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(
        `project/user/${params?.userId}/overtime-request-approve?dateRequest=${params?.dateRequest}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const approveOtRequest = createAsyncThunk(
  '/overtime/request-approve/edit-approved',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.put(
        `/overtime/request-approve/edit-approved/${params?.id}`,
        params?.body,
      );
      response && params?.callBack();
      return response;
    } catch (error) {
      return thunkApi.dispatch(setErrorMessage(error));
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
