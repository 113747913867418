import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import axiosClient from 'api/axiosClient';
import configurationApi from 'api/configurationApi';
import { setGlobalLoading } from 'app/commonRedux/appSlice';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import { actions } from 'features/configuration/configurationSlice';
import queryString from 'query-string';

// =====================================================

export const updateStatusWorkday = createAsyncThunk(
  'configuration/updateStatusWorkday',
  async (params: { configId: string | number; body: any[] }, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.put(
        `/configurations/update-status/${params.configId}`,
        params.body,
      );
      thunkApi.dispatch(getListSetupWorkday());
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const deleteHoliday = createAsyncThunk(
  'configuration/deleteHoliday',
  async (params: { configId: string | number; body: any; callBack?: any }, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.delete(
        `/configurations/delete-mutiple-config-type/${params.configId}?id=${params.body.join(',')}`,
      );
      response && params.callBack(response);
      //thunkApi.dispatch(getListSetupHolidays());
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const moveHoliday = createAsyncThunk(
  'configuration/moveHoliday',
  async (params: { year: any; holidayIds: any; callBack?: any }, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.post(`/configurations/holidays/create-multiple`, {
        year: params.year,
        holidayIds: params.holidayIds,
      });
      response && params.callBack(response);
      //thunkApi.dispatch(getListSetupHolidays());
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateStatusHolidays = createAsyncThunk(
  'configuration/updateStatusHolidays',
  async (params: { configId: string | number; body: any[]; callBack?: any }, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.put(
        `/configurations/update-status/${params.configId}`,
        params.body,
      );
      response && params.callBack();
      //thunkApi.dispatch(getListSetupHolidays());
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getListSetupWorkday = createAsyncThunk(
  'configuration/getListSetupWorkday',
  async (_, thunkApi: any) => {
    try {
      // thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.get('configurations/config-type/working_days_type');
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const autoCheckinWorkingDay = createAsyncThunk(
  'configuration/autoCheckinWorkingDay',
  async (params: { configId: string | number; body: any }, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.put(
        `configurations/update-autoCheckin/${params.configId}`,
        params.body,
      );
      thunkApi.dispatch(getListSetupWorkday());
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateWorkingDayType = createAsyncThunk(
  'configuration/updateWorkingDayType',
  async (
    params: { configId: string | number; typeId: string | number; body: any },
    thunkApi: any,
  ) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.put(
        `/configurations/update-working-days-type/${params.configId}/${params.typeId}`,
        params.body,
      );
      thunkApi.dispatch(getListSetupWorkday());
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getListSetupHolidays = createAsyncThunk(
  'configuration/getListSetupHolidays',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.get(`/configurations/holidays/${params.year}`);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const createHolidays = createAsyncThunk(
  'configuration/createHolidays',
  async (params: { configId: number; body: any; callBack?: any }, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.post(
        `configurations/create-holidays/${params.configId}`,
        params.body,
      );
      response && params.callBack(response);
      //thunkApi.dispatch(getListSetupHolidays());
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateHolidays = createAsyncThunk(
  'configuration/updateHolidays',
  async (
    params: { configId: number; typeId: number; body: any; callBack?: any },
    thunkApi: any,
  ) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.put(
        `configurations/update-holidays/${params.configId}/${params.typeId}`,
        params.body,
      );
      response && params.callBack(response);
      //thunkApi.dispatch(getListSetupHolidays());
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

// ========================================================================

export const getConfigTypes = createAsyncThunk(
  'configurations/getConfigTypes',
  async (params: any, thunkApi: any) => {
    try {
      const response: any = await configurationApi.getConfigTypes(params);
      params.callBack && params.callBack(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getPositionManage = createAsyncThunk('configurations/config-type/', async () => {
  try {
    const response: any = await configurationApi.getPositionManage();
    return response;
  } catch (error) {
    return error;
  }
});

export const createDetailSetupWorkday = createAsyncThunk(
  '/configurations/update-working-days-type/2',
  async (datas: any) => {
    try {
      const response: any = await configurationApi.createDetailSetupWorkday(datas);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const autoCheckin = createAsyncThunk(
  'configurations/update-autoCheckin/2',
  async (data: any, thunkApi: any) => {
    const res = await axiosClient.post('configurations/update-autoCheckin/2', data);
    thunkApi.dispatch(getListSetupWorkday());
    return res;
  },
);

export const syncDataCheckin = createAsyncThunk(
  '/configurations/general-setting',
  async (data: any, thunkApi: any) => {
    const { callBack, payloadSyncRequest } = data;
    try {
      const response = await axiosClient.post('/schedule/synchronized-data', payloadSyncRequest);
      response &&
        callBack(response) &&
        notificationToast(
          Notification.Type.Success,
          (response as any)?.message,
          Notification.Duration._3s,
        );
      // notification.success({
      //   duration: 3,
      //   message: (response as any).message,
      // });
      return response;
    } catch (error) {
      callBack && callBack(error);
      return error;
    }
  },
);

export const getUsersOfLeaveType = createAsyncThunk(
  '/timesheet/get-list-active-employee-config-leave-off',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { configTypeId } = params;
      const response: any = await axiosClient.get(
        `/timesheet/get-list-active-employee-config-leave-off?configTypeId=${configTypeId}`,
      );
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const plusLeaveType = createAsyncThunk(
  '/timesheet/manual-request-leave-type-total-time-addition',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { users, typeId } = params;
      const response: any = await axiosClient.post(
        `/timesheet/manual-request-leave-type-total-time-addition`,
        { users, configTypeId: typeId },
      );
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

//Config payroll
//Bonus
export const createBonus = createAsyncThunk(
  '/configurations/bonus',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { name, isTaxed } = params?.values;
      const response: any = await axiosClient.post(`/configurations/bonus`, { name, isTaxed });
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      //return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateBonus = createAsyncThunk(
  '/configurations/bonus',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { name, isTaxed, id } = params?.values;
      const response: any = await axiosClient.put(`/configurations/bonus/${id}`, { name, isTaxed });
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      //return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getAllBonus = createAsyncThunk(
  '/configurations/bonus',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(`/configurations/bonus`);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const deleteBonus = createAsyncThunk(
  '/configurations/bonus',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { id } = params?.data;
      const response: any = await axiosClient.delete(`/configurations/bonus/${id}`);
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

//Deduction
export const createDeduction = createAsyncThunk(
  '/configurations/deduction',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { name, money } = params?.values;
      const response: any = await axiosClient.post(`/configurations/deduction`, { name, money });
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateDeduction = createAsyncThunk(
  '/configurations/deduction',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { name, money, id } = params?.data;
      const response: any = await axiosClient.put(`/configurations/deduction/${id}`, {
        name,
        money,
      });
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getAllDeduction = createAsyncThunk(
  '/configurations/deduction',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(`/configurations/deduction`);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const deleteDeduction = createAsyncThunk(
  '/configurations/deduction',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { id } = params?.data;
      const response: any = await axiosClient.delete(`/configurations/deduction/${id}`);
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

//Allowance
export const createAllowance = createAsyncThunk(
  '/configurations/allowance',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { name, isTaxed, isGross } = params?.values;
      const response: any = await axiosClient.post(`/configurations/allowance`, {
        name,
        isTaxed,
        isGross,
      });
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      //return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateAllowance = createAsyncThunk(
  '/configurations/allowance',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { name, isTaxed, isGross, id } = params?.values;
      const response: any = await axiosClient.put(`/configurations/allowance/${id}`, {
        name,
        isTaxed,
        isGross,
      });
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      //return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getAllAllowance = createAsyncThunk(
  '/configurations/allowance',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(`/configurations/allowance`);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const deleteAllowance = createAsyncThunk(
  '/configurations/allowance',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { id } = params?.data;
      const response: any = await axiosClient.delete(`/configurations/allowance/${id}`);
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
//Advances
export const createAdvances = createAsyncThunk(
  '/configurations/advances',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { name, description } = params?.values;
      const response: any = await axiosClient.post(`/configurations/advance-configuration`, {
        name,
        description,
      });

      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));

      //return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const updateAdvances = createAsyncThunk(
  '/configurations/advances',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { name, description, id } = params?.values;
      const response: any = await axiosClient.put(`/configurations/advance-configuration/${id}`, {
        name,
        description,
      });
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      //return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const getAllAdvances = createAsyncThunk(
  '/configurations/advances',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(`/configurations/advance-configuration`);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const deleteAdvances = createAsyncThunk(
  '/configurations/advances',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { id } = params?.data;
      const response: any = await axiosClient.delete(`/configurations/advance-configuration/${id}`);
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
//PIT
export const createPIT = createAsyncThunk(
  '/configurations/personal-income-tax',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { minValue, maxValue, taxRate, isProbationary } = params?.values;
      const response: any = await axiosClient.post(`/configurations/personal-income-tax`, {
        minValue: minValue ?? 0,
        maxValue: maxValue,
        taxRate: taxRate ?? 0,
        isProbationary,
      });
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updatePIT = createAsyncThunk(
  '/configurations/personal-income-tax',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { minValue, maxValue, taxRate, isProbationary, id } = params?.values;
      const response: any = await axiosClient.put(`/configurations/personal-income-tax/${id}`, {
        minValue: minValue ?? 0,
        maxValue: maxValue,
        taxRate: taxRate ?? 0,
        isProbationary,
      });
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getAllPIT = createAsyncThunk(
  '/configurations/personal-income-tax',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(`/configurations/personal-income-tax`);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const deletePIT = createAsyncThunk(
  '/configurations/personal-income-tax',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { id } = params?.data;
      const response: any = await axiosClient.delete(`/configurations/personal-income-tax/${id}`);
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
//_________________________setup status____________________________
export const getAllStatus = createAsyncThunk(
  '/configurations/status',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(`/configurations/status`);
      params.callBack && response && params.callBack(response?.data);
      return response?.data?.result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getFindOneStatus = createAsyncThunk(
  '/configurations/getGroupStatus',
  async (params: any, thunkApi: any) => {
    try {
      const response: any = await axiosClient.get(`/configurations/status/${params.id}/group`);
      params.callBack && response && params.callBack(response?.data);
      return response?.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const createStatus = createAsyncThunk(
  '/configurations/status',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { groupCode, groupName, statusDetails } = params.data;
      const response: any = await axiosClient.post(`/configurations/status`, {
        groupCode,
        groupName,
        statusDetails,
      });
      params.callBack && response && params.callBack(response?.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateStatus = createAsyncThunk(
  '/configurations/status',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.put(
        `/configurations/status/${params.id}`,
        params.data,
      );
      params.callBack && response && params.callBack(response?.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
//_________________________setup action____________________________
export const getAllAction = createAsyncThunk(
  '/configurations/action',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(`/configurations/action`);
      params.callBack && response && params.callBack(response?.data);
      return response?.data?.result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getFindOneActionByScreen = createAsyncThunk(
  '/configurations/action-by-screen',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(`/configurations/action-by-screen/${params.id}`);
      notification.destroy();
      params.callBack && response && params.callBack(response);

      return response;
    } catch (error) {
      notification.destroy();
      thunkApi.dispatch(setErrorMessage(error));
      return thunkApi.rejectWithValue(error);
    } finally {
      notification.destroy();
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const getFindOneAction = createAsyncThunk(
  '/configurations/action-find-one',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(`/configurations/action/${params.id}/group`);
      notification.destroy();
      params.callBack && response && params.callBack(response?.data);
      return response?.data;
    } catch (error) {
      notification.destroy();
      thunkApi.dispatch(setErrorMessage(error));
      return thunkApi.rejectWithValue(error);
    } finally {
      notification.destroy();
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const getFindOneActionAdd = createAsyncThunk(
  '/configurations/action-find-one-add',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(
        `/configurations/action/${params.id}/workflow-type`,
      );
      notification.destroy();
      params.callBack && response && params.callBack(response?.data);
      return response?.data;
    } catch (error) {
      notification.destroy();
      thunkApi.dispatch(setErrorMessage(error));
      return thunkApi.rejectWithValue(error);
    } finally {
      notification.destroy();
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const createAction = createAsyncThunk(
  '/configurations/action',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { groupCode, groupName, actionDetails } = params.data;
      const response: any = await axiosClient.post(`/configurations/action`, {
        groupCode,
        groupName,
        actionDetails,
      });
      params.callBack && response && params.callBack(response?.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateAction = createAsyncThunk(
  '/configurations/action',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.put(
        `/configurations/action/${params.id}`,
        params.data,
      );
      params.callBack && response && params.callBack(response?.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
//_________________________setup workflow type____________________________
export const getAllWorkflowType = createAsyncThunk(
  '/configurations/workflow-type',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(`/configurations/workflow-type`);
      params.callBack && response && params.callBack(response?.data);
      return response?.data?.result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const getFindOneWorkflowType = createAsyncThunk(
  '/configurations/workflowTypeFindOne',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { id } = params;
      const response: any = await axiosClient.get(`/configurations/workflow-type/${id}/group`);
      params.callBack && response && params.callBack(response?.data);
      return response?.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const getFindOneWorkflowTypeByPayroll = createAsyncThunk(
  '/configurations/workflowTypeFindOneByPayroll',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(
        `/configurations/workflow-type/search?${queryString.stringify(params)}`,
      );
      params.callBack && response && params.callBack(response?.data);
      return response?.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const getCheckEditWorkflowByPayroll = createAsyncThunk(
  '/configurations/checkEditWorkflowByPayroll',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { id } = params;
      const response: any = await axiosClient.get(
        `/configurations/workflow-type/check-workflow-type-details/${id}`,
      );
      params.callBack && response && params.callBack(response);
      return response;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const getBusinessWorkflowType = createAsyncThunk(
  '/configurations/business-module',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(`/configurations/business-module`);
      params.callBack && response && params.callBack(response?.data);
      return response?.data?.result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const createWorkflowType = createAsyncThunk(
  '/configurations/workflow-type',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { businessModuleId, createWorkflowTypeDetail } = params.dataCreate;

      const response: any = await axiosClient.post(`/configurations/workflow-type`, {
        businessModuleId,
        createWorkflowTypeDetail,
      });
      params.callBack && response && params.callBack(response?.data);
      // thunkApi.dispatch(getAllWorkflowType({}));
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateWorkflowType = createAsyncThunk(
  '/configurations/workflow-type',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { businessModuleId, createWorkflowTypeDetail, id } = params.dataUpdate;

      const response: any = await axiosClient.put(`/configurations/workflow-type/${id}`, {
        businessModuleId,
        createWorkflowTypeDetail,
      });
      params.callBack && response && params.callBack(response?.data);
      thunkApi.dispatch(getAllWorkflowType({}));
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getAllWorkflowTypeHistory = createAsyncThunk(
  '/configurations/workflow-type-history',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(`/configurations/workflow-type/history`);
      params.callBack && response && params.callBack(response?.data);
      return response?.data?.result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getDataPayroll = createAsyncThunk(
  '/configurations/getDataPayroll',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));

      return params?.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const testPayroll = createAsyncThunk('/testPayroll', async (params: any, thunkApi: any) => {
  const { setErrorMessage } = actions;
  try {
    thunkApi.dispatch(setGlobalLoading({ loading: true }));
    const dataPayroll = params?.dataPayroll;
    const response: any = await axiosClient.post(`payroll/test-calculation`, dataPayroll);
    params.callBack && response && params.callBack(response?.data);
    // thunkApi.dispatch(getAllWorkflowType({}));
    return response.data;
  } catch (error) {
    thunkApi.dispatch(setErrorMessage(error));
    return thunkApi.rejectWithValue(error);
  } finally {
    thunkApi.dispatch(setGlobalLoading({ loading: false }));
  }
});

//Manage Menu
export const createMenuManage = createAsyncThunk(
  '/configurations/menu-manage',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.post(`/configurations/manage-menu`, params?.values);
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      //return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateMenuManage = createAsyncThunk(
  '/configurations/menu-manage',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { menuId, menuName, description, type, iconId, id } = params?.values;
      const response: any = await axiosClient.put(`/configurations/manage-menu/${id}`, {
        menuId,
        menuName,
        description,
        type,
        iconId,
      });
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      //return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const deleteMenuManage = createAsyncThunk(
  '/configurations/menu-manage',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { id } = params?.data;
      const response: any = await axiosClient.delete(`/configurations/manage-menu/${id}`);
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const getAllMenuManage = createAsyncThunk(
  '/configurations/menu-manage',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(`/configurations/manage-menu`);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
//Manage Screen
export const createScreenManage = createAsyncThunk(
  '/configurations/screen-manage',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.post(`/configurations/manage-screen`, params?.values);
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      //return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const updateScreenManage = createAsyncThunk(
  '/configurations/screen-manage',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { id, ...rest } = params?.values;
      const response: any = await axiosClient.put(`/configurations/manage-screen/${id}`, rest);
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      //return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const deleteScreenManage = createAsyncThunk(
  '/configurations/screen-manage',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { id } = params?.data;
      const response: any = await axiosClient.delete(`/configurations/manage-screen/${id}`);
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const getAllScreenManage = createAsyncThunk(
  '/configurations/screen-manage',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(`/configurations/manage-screen`);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
//Manage Menu Screen
export const createMenuScreenManage = createAsyncThunk(
  '/configurations/menu-screen-manage',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.post(`/configurations/menu-screen`, params?.values);
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      //return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const getAllMenuScreenManage = createAsyncThunk(
  '/configurations/menu-screen-manage',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(`/configurations/menu-screen`);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const getFindOneMenuScreen = createAsyncThunk(
  '/configurations/action-menu-screen',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(`/configurations/menu-screen/${params.id}/group`);
      notification.destroy();
      params.callBack && response && params.callBack(response?.data);
      return response?.data;
    } catch (error) {
      notification.destroy();
      thunkApi.dispatch(setErrorMessage(error));
      return thunkApi.rejectWithValue(error);
    } finally {
      notification.destroy();
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const updateMenuScreenManage = createAsyncThunk(
  '/configurations/menu-screen-manage',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { menuParent, createDtMenuScreen, idTable, sortId } = params?.values;
      const response: any = await axiosClient.put(`/configurations/menu-screen/${idTable}`, {
        menuParent,
        createDtMenuScreen,
        sortId,
      });
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      //return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const deleteMenuScreenManage = createAsyncThunk(
  '/configurations/menu-screen-manage',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const id = params?.data;
      const response: any = await axiosClient.delete(`/configurations/menu-screen/delete/${id}`);
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
//Upgrade action
export const createActionScreenGroup = createAsyncThunk(
  '/configurations/action-screen-group',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { groupCode, groupName, screenIds } = params.data;
      const response: any = await axiosClient.post(`/configurations/manage-action-screen-group`, {
        groupCode,
        groupName,
        screenIds,
      });
      params.callBack && response && params.callBack(response?.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const updateActionScreenGroup = createAsyncThunk(
  '/configurations/action-screen-group',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.put(
        `/configurations/manage-action-screen-group/${params.id}`,
        params.data,
      );
      params.callBack && response && params.callBack(response?.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const getFindOneActionScreenGroup = createAsyncThunk(
  '/configurations/action-screen-group-find-one',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(
        `/configurations/manage-action-screen-group/${params.id}`,
      );
      notification.destroy();
      params.callBack && response && params.callBack(response?.data);
      return response?.data;
    } catch (error) {
      notification.destroy();
      thunkApi.dispatch(setErrorMessage(error));
      return thunkApi.rejectWithValue(error);
    } finally {
      notification.destroy();
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const getAllActionScreenGroup = createAsyncThunk(
  '/configurations/action-screen-group',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(`/configurations/manage-action-screen-group`);
      params.callBack && response && params.callBack(response?.data);
      return response?.data?.result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

//performance review status
export const getAllStatusPerformanceReview = createAsyncThunk(
  '/configurations/performance-review',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(`/performance/configuration/status`);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getAllStatusTypePerformanceReview = createAsyncThunk(
  '/configurations/performance-review/status-type',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(`/performance/configuration/status/type`);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const createStatusPerformanceReview = createAsyncThunk(
  '/configurations/performance-review/status',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.post(
        `/performance/configuration/status`,
        params?.values,
      );
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      //return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateStatusPerformanceReview = createAsyncThunk(
  'configurations/performance-review/status',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    const { id, ...newParams } = params.values;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.put(
        `/performance/configuration/status/${id}`,
        newParams,
      );
      params.callBack && response && params.callBack(response?.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const deleteStatusPerformanceRiview = createAsyncThunk(
  '/configurations/performance-review/status',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { id } = params?.data;
      const response: any = await axiosClient.delete(`/performance/configuration/status/${id}`);
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getAllReviewTemplatePerformanceReview = createAsyncThunk(
  '/configurations/performance-review/review-template',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(`/performance/configuration/templates`);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const deleteReviewTemplate = createAsyncThunk(
  '/configurations/performance-review/review-template',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { id } = params?.data;
      const response: any = await axiosClient.delete(`/performance/configuration/templates/${id}`);
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const createTemplatePerformanceReview = createAsyncThunk(
  '/configurations/performance-review/review-template',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.post(
        `/performance/configuration/templates`,
        params?.values,
      );
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      //return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateTemplatePerformanceReview = createAsyncThunk(
  '/configurations/performance-review/review-template',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    const newParams = params.values;
    const id = params.id;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.put(
        `/performance/configuration/templates/${id}`,
        newParams,
      );
      params.callBack && response && params.callBack(response?.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getAllCompetenciesPerformanceReview = createAsyncThunk(
  '/configurations/performance-review/competencies',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(`/performance/configuration/competency`);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const createCompetencyPerformanceReview = createAsyncThunk(
  '/configurations/performance-review/competencies',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.post(
        `/performance/configuration/competency`,
        params?.values,
      );
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      //return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const deleteCompetencyPerformanceRiview = createAsyncThunk(
  '/configurations/performance-review/competencies',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { id } = params?.data;
      const response: any = await axiosClient.delete(`/performance/configuration/competency/${id}`);
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateCompetencyPerformanceRiview = createAsyncThunk(
  'configurations/performance-review/rating',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    const { id, ...newParams } = params.values;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.put(
        `/performance/configuration/competency/${id}`,
        newParams,
      );
      params.callBack && response && params.callBack(response?.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getAllRatingPerformanceReview = createAsyncThunk(
  '/configurations/performance-review/rating',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(`/performance/configuration/rating`);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const deleteRatingPerformanceRiview = createAsyncThunk(
  '/configurations/performance-review/rating',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { id } = params?.data;
      const response: any = await axiosClient.delete(`/performance/configuration/rating/${id}`);
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const createRatingPerformanceReview = createAsyncThunk(
  '/configurations/performance-review/rating',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.post(
        `/performance/configuration/rating`,
        params?.values,
      );
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      //return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateRatingPerformanceReview = createAsyncThunk(
  'configurations/performance-review/rating',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    const { id, ...newParams } = params.values;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.put(
        `/performance/configuration/rating/${id}`,
        newParams,
      );
      params.callBack && response && params.callBack(response?.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

//Goals
export const getAllGoals = createAsyncThunk(
  '/configurations/goals',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(`/performance/configuration/goals`);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const createGoals = createAsyncThunk(
  '/configurations/goals',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.post(
        `/performance/configuration/goals`,
        params?.values,
      );
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      //return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateGoals = createAsyncThunk(
  '/configurations/goals',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    const { id, ...newParams } = params.values;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.put(
        `/performance/configuration/goals/${id}`,
        newParams,
      );
      params.callBack && response && params.callBack(response?.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const deleteGoals = createAsyncThunk(
  '/configurations/goals',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { id } = params?.data;
      const response: any = await axiosClient.delete(`/performance/configuration/goals/${id}`);
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

//recruitment
export const getAllDataJobTemplate = createAsyncThunk(
  '/recruitment/configuration/job-template',
  async (params: any, thunkApi: any) => {
    try {
      const { limit, offset, searchName } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(
        `recruitment/configuration/job-template?limit=${limit}&offset=${offset}&searchName=${searchName}`,
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const createJobTemplate = createAsyncThunk(
  '/recruitment/configuration/job-template',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.post(
        `/recruitment/configuration/job-template`,
        params?.newValues,
      );
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      notificationToast(Notification.Type.Error, error?.message, Notification.Duration._3s);
      // thunkApi.dispatch(setErrorMessage(error));
      //return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateJobTemplate = createAsyncThunk(
  '/recruitment/configuration/job-template',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    const { id, ...newEditValue } = params?.newEditValue;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.put(
        `/recruitment/configuration/job-template/${id}`,
        newEditValue,
      );
      params.callBack && response && params.callBack(response?.data);
      return response.data;
    } catch (error) {
      // thunkApi.dispatch(setErrorMessage(error));
      notificationToast(Notification.Type.Error, error?.message, Notification.Duration._3s);
      // return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const deleteJobTemplate = createAsyncThunk(
  '/recruitment/configuration/job-template',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { id } = params?.record;
      const response: any = await axiosClient.delete(
        `/recruitment/configuration/job-template/${id}`,
      );
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      notificationToast(Notification.Type.Error, error?.message, Notification.Duration._3s);
      // return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getAllDataQuestionPool = createAsyncThunk(
  '/recruitment/configuration/question-pool',
  async (params: any, thunkApi: any) => {
    try {
      const { limit, offset, searchName } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(
        `/recruitment/configuration/questions?limit=${limit}&offset=${offset}&searchName=${searchName}`,
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const createQuestionPool = createAsyncThunk(
  '/recruitment/configuration/question-pool',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.post(
        `/recruitment/configuration/questions`,
        params?.values,
      );
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      notificationToast(Notification.Type.Error, error?.message, Notification.Duration._3s);
      // thunkApi.dispatch(setErrorMessage(error));
      //return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateQuestionPool = createAsyncThunk(
  '/recruitment/configuration/question-pool',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    const { id, ...newEditValue } = params?.newEditValue;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.put(
        `/recruitment/configuration/questions/${id}`,
        newEditValue,
      );
      params.callBack && response && params.callBack(response?.data);
      return response.data;
    } catch (error) {
      notificationToast(Notification.Type.Error, error?.message, Notification.Duration._3s);
      // thunkApi.dispatch(setErrorMessage(error));
      // return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const deleteQuestionPool = createAsyncThunk(
  '/recruitment/configuration/question-pool',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { id } = params;
      const response: any = await axiosClient.delete(`/recruitment/configuration/questions/${id}`);
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      notificationToast(Notification.Type.Error, error?.message, Notification.Duration._3s);
      // return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getAllDataInterviewTemplate = createAsyncThunk(
  '/recruitment/configuration/interview-template',
  async (params: any, thunkApi: any) => {
    try {
      const { limit, offset, searchName } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(
        `/recruitment/configuration/interview-template?limit=${limit}&offset=${offset}&searchName=${searchName}`,
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const createInterviewTemplate = createAsyncThunk(
  '/recruitment/configuration/interview-template',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.post(
        `/recruitment/configuration/interview-template`,
        params?.values,
      );
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      notificationToast(Notification.Type.Error, error?.message, Notification.Duration._3s);
      // thunkApi.dispatch(setErrorMessage(error));
      //return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateInterviewTemplate = createAsyncThunk(
  '/recruitment/configuration/interview-template',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    const { id, ...values } = params?.values;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.put(
        `/recruitment/configuration/interview-template/${id}`,
        values,
      );
      params.callBack && response && params.callBack(response?.data);
      return response.data;
    } catch (error) {
      notificationToast(Notification.Type.Error, error?.message, Notification.Duration._3s);
      // thunkApi.dispatch(setErrorMessage(error));
      // return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const deleteInterviewTemplate = createAsyncThunk(
  '/recruitment/configuration/interview-template',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { id } = params?.record;
      const response: any = await axiosClient.delete(
        `/recruitment/configuration/interview-template/${id}`,
      );
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      notificationToast(Notification.Type.Error, error?.message, Notification.Duration._3s);
      // return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getHiringDataSetupWorkflow = createAsyncThunk(
  '/recruitment/configuration/hiring-setup-workflow',
  async (params: any, thunkApi: any) => {
    try {
      const { limit, offset, searchName } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(
        `/recruitment/configuration/workflow-template?limit=${limit}&offset=${offset}&searchName=${searchName}&type=${'Hiring process'}`,
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const createHiringDataSetupWorkflow = createAsyncThunk(
  '/recruitment/configuration/hiring-setup-workflow',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.post(
        `/recruitment/configuration/workflow-template`,
        params?.values,
      );
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      notificationToast(Notification.Type.Error, error?.message, Notification.Duration._3s);
      // thunkApi.dispatch(setErrorMessage(error));
      //return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateHiringDataSetupWorkflow = createAsyncThunk(
  '/recruitment/configuration/hiring-setup-workflow',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    const { id, ...values } = params?.values;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.put(
        `/recruitment/configuration/workflow-template/${id}`,
        values,
      );
      params.callBack && response && params.callBack(response?.data);
      return response.data;
    } catch (error) {
      notificationToast(Notification.Type.Error, error?.message, Notification.Duration._3s);
      // thunkApi.dispatch(setErrorMessage(error));
      // return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const deleteHiringDataSetupWorkflow = createAsyncThunk(
  '/recruitment/configuration/hiring-setup-workflow',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { id } = params?.record;
      const response: any = await axiosClient.delete(
        `/recruitment/configuration/workflow-template/${id}`,
      );
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      notificationToast(Notification.Type.Error, error?.message, Notification.Duration._3s);
      // return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getApprovalSetupWorkflow = createAsyncThunk(
  '/recruitment/configuration/approval-setup-workflow',
  async (params: any, thunkApi: any) => {
    try {
      const { limit, offset, searchName } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.get(
        `/recruitment/configuration/workflow-template?limit=${limit}&offset=${offset}&searchName=${searchName}&type=${'Approval workflow'}`,
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const createApprovalSetupWorkflow = createAsyncThunk(
  '/recruitment/configuration/workflow-setup-workflow',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.post(
        `/recruitment/configuration/workflow-template`,
        params?.values,
      );
      response && params.callBack(response.data);
      return response.data;
    } catch (error) {
      notificationToast(Notification.Type.Error, error?.message, Notification.Duration._3s);
      // thunkApi.dispatch(setErrorMessage(error));

      //return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
