import { ExportOutlined } from '@ant-design/icons';
import { Modal, TablePaginationConfig } from 'antd';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import payrollApi from 'api/payrollApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ReasonForm } from 'components/Forms';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import {
  ALLOWANCE_SORT_FIELDS,
  ContractSalaryConfirmStatus,
  MONTH_YEAR_FORMAT,
  SORT_TYPES,
} from 'constants/payroll';
import { exportUsersHaveAllowance } from 'features/employee/employeeAction';
import { selectEmployee, setCheckExpand } from 'features/employee/employeeSlice';
import { useAllowanceList } from 'hooks';
import CustomButton from 'styles/buttonStyled';
import { ContainerBgWhite } from 'styles/containerBgWhite';
import { InitAllowanceFilters, ListFilterAllowance, Reason } from 'types';
import { getErrorMessage } from 'utils/getErrorMessage';
import { AllowanceFilters } from './AllowanceFilters';
import { AllowanceList } from './AllowanceList';

interface AllowanceProps {
  monthYear: string;
  fetchListStatusTabPayrollAPI: () => void;
}

export function Allowance({ monthYear, fetchListStatusTabPayrollAPI }: AllowanceProps) {
  const { t } = useTranslation(['action']);
  const stateSelectEmployee: any = useAppSelector(selectEmployee);
  const selectRowKey = useAppSelector((state) => state.employee.selectRowKey) as string[];
  const dispatch = useAppDispatch();

  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState<Partial<ListFilterAllowance>>({
    search: '',
    line: '',
  });
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);
  const isCheckAll: boolean = useAppSelector(selectEmployee).isCheckAll;

  const filtersMemo = useMemo(() => {
    dispatch(setCheckExpand([]));
    if (isCheckAll) {
      return {
        ...filters,
        monthYear: monthYear ?? moment(new Date()).format('YYYY-MM'),
      };
    }
    return {
      ...filters,
      monthYear: monthYear ?? moment(new Date()).format('YYYY-MM'),
      offset,
      limit,
    };
  }, [dispatch, filters, offset, limit, monthYear, isCheckAll]);

  const initAllowancePayload: Partial<InitAllowanceFilters> = {
    search: filters.search || '',
  };

  const {
    data: allowanceListResponse,
    isLoading,
    setRefresh,
    statusConfirm,
  } = useAllowanceList(filtersMemo);

  const handleFiltersChange = ({ ...payload }: InitAllowanceFilters) => {
    setOffset(1);

    setFilters((prevFilter) => ({
      ...prevFilter,
      ...payload,
      offset: 1,
    }));
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any>,
  ) => {
    if (pagination?.current === offset) {
      setOffset(1);

      setFilters((prevFilters) => ({
        ...prevFilters,
        viewBy: sorter.column
          ? ALLOWANCE_SORT_FIELDS[sorter.field as keyof typeof ALLOWANCE_SORT_FIELDS]
          : ALLOWANCE_SORT_FIELDS.fullName,
        sortBy: sorter.column
          ? SORT_TYPES[sorter.order as keyof typeof SORT_TYPES]
          : SORT_TYPES.ascend,
      }));
    }
  };

  const onExport = () => {
    if (stateSelectEmployee.selectRowKey?.length === 0) {
      return Modal.warning({
        title: t('modal:none_select'),
      });
    }

    // const userArray: any = allowanceListResponse.allowanceList
    //   ?.filter((item: any) => stateSelectEmployee.selectRowKey?.some((i: any) => i === item?.id))
    //   ?.map((item: any) => item?.user?.id);
    // const userIds = Array.from(new Set(userArray));
    const body = {
      monthYear: filtersMemo.monthYear ? filtersMemo.monthYear : null,
      userIds: stateSelectEmployee.selectRowKey,
    };

    dispatch(
      exportUsersHaveAllowance({
        ...body,
      }),
    );
  };

  const handleConfirm = async () => {
    try {
      const payload = {
        monthYear: moment(filtersMemo.monthYear).format(MONTH_YEAR_FORMAT),
        status: ContractSalaryConfirmStatus.APPROVED,
      };
      setIsLoadingConfirm(true);
      const response = await payrollApi.confirmAllowance(payload);
      setIsLoadingConfirm(false);
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      fetchListStatusTabPayrollAPI();
      setRefresh((prev) => !prev);
    } catch (error) {
      setIsLoadingConfirm(false);
      const message = getErrorMessage(error);
      notificationToast(Notification.Type.Error, message, Notification.Duration._3s);
    }
  };

  const handleConfirmAllowance = () => {
    Modal.confirm({
      title: <div style={{ fontWeight: 'bold' }}>{t('payroll:confirm_allowance_title')}</div>,
      okButtonProps: { loading: isLoadingConfirm },
      okText: t('action:ok'),
      cancelText: t('action:cancel'),
      onOk: handleConfirm,
    });
  };

  const handleReset = async (reason: string) => {
    try {
      const params = {
        monthYear: moment(filtersMemo.monthYear).format(MONTH_YEAR_FORMAT),
        timesheetType: 'allowance',
        reason,
      };

      setIsLoadingConfirm(true);
      const response = await payrollApi.resetConfirmPayroll(params);
      setIsLoadingConfirm(false);
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      fetchListStatusTabPayrollAPI();
      setRefresh((prev) => !prev);
    } catch (error) {
      setIsLoadingConfirm(false);
      const message = getErrorMessage(error);
      notificationToast(Notification.Type.Error, message, Notification.Duration._3s);
    }
  };

  const handleResetAllowance = () => {
    let reason = '';
    Modal.confirm({
      title: <div style={{ fontWeight: 'bold' }}>{t('reset_reason')}</div>,
      content: (
        <>
          <ReasonForm
            onSubmit={(payload: Reason) => {
              if (!payload.reason) return;
              reason = payload.reason;
            }}
          />
          <p style={{ margin: 0, color: 'red', fontStyle: 'italic' }}>
            {t('payroll:payroll_deleted', { monthYear })}
          </p>
        </>
      ),
      okButtonProps: { loading: isLoading },
      okText: t('action:ok'),
      cancelText: t('action:cancel'),
      onOk: () => handleReset(reason),
      onCancel: () => (reason = ''),
    });
  };

  return (
    <Container>
      <ContainerBgWhite>
        <StyledWrapper>
          <AllowanceFilters initialValues={initAllowancePayload} onSubmit={handleFiltersChange} />

          <StyledWrapperActionBtn>
            {statusConfirm === ContractSalaryConfirmStatus.APPROVED ? (
              <>
                <CustomButton loading={false} onClick={handleResetAllowance}>
                  {t('reset')}
                </CustomButton>{' '}
                <ButtonConfirmed>{t('Confirmed')}</ButtonConfirmed>
              </>
            ) : (
              <CustomButton loading={false} onClick={handleConfirmAllowance}>
                {t('Confirm')}
              </CustomButton>
            )}

            <CustomButton
              icon={<ExportOutlined />}
              onClick={() => {
                onExport();
              }}
              disabled={selectRowKey.length === 0}
            >
              {t('action:export')}
            </CustomButton>
          </StyledWrapperActionBtn>
        </StyledWrapper>

        <AllowanceList
          loading={isLoading}
          data={allowanceListResponse.allowanceList}
          totalResult={allowanceListResponse.totalResult}
          offset={offset}
          limit={limit}
          setOffset={setOffset}
          setLimit={setLimit}
          onRefresh={() => setRefresh((prev) => !prev)}
          onTableChange={handleTableChange}
          sumAllowance={allowanceListResponse.allowanceListSum}
          sumTotal={allowanceListResponse.sumTotal}
          monthYear={filtersMemo.monthYear ?? ''}
        />
      </ContainerBgWhite>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const StyledWrapperActionBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ButtonConfirmed = styled.div`
  background-color: var(--cl_success100);
  color: var(--cl_success600);
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  display: inline;
  padding: 5px 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 4px;
`;
