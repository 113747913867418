import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import Avatar from 'components/Avatar';
import { Tooltip } from 'antd';

interface EmployeeColumnProps {
  avatar: string;
  fullName: string;
  email?: string;
  text?: string;
  isMobile?: boolean;
  width?: string;
  minWidth?: string;
  clickable?: boolean;
  onClick?: () => void;
}
const TooltipTitle = (props: any) => {
  const { fullName, email } = props;
  return (
    <div>
      <div>Name: {fullName}</div>
      <div>Email: {email}</div>
    </div>
  );
};

const EmployeeColumn = (props: EmployeeColumnProps) => {
  const {
    avatar,
    fullName,
    email,
    text,
    isMobile = false,
    width,
    minWidth,
    clickable = false,
    onClick,
  } = props;
  const TooltipTitleMemo = useMemo(
    () => <TooltipTitle fullName={fullName} email={email} />,
    [fullName, email],
  );
  return (
    <Container clickable={clickable} onClick={onClick}>
      <Avatar name={avatar} />
      <Tooltip title={TooltipTitleMemo}>
        <Info isMobile={isMobile} width={width} minWidth={minWidth}>
          <InfoName clickable={clickable}>{fullName}</InfoName>
          {email && !text && <InfoEmail>{email}</InfoEmail>}
          {text && <InfoText>{text}</InfoText>}
        </Info>
      </Tooltip>
    </Container>
  );
};

export default React.memo(EmployeeColumn);

export const Container = styled.div<{ clickable?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}
`;

interface InfoProps {
  width?: string;
  minWidth?: string;
  isMobile?: boolean;
}

export const Info = styled.div`
  margin-left: 0.6rem;
  width: ${(props: InfoProps) => (props.width ? props.width : `320px`)};
  min-width: ${(props: InfoProps) => (props.minWidth ? props.minWidth : `270px`)};
  ${(props: InfoProps) =>
    props.isMobile &&
    css`
      margin-left: 10px;
      /* width: 100%; */
      width: unset;
      min-width: 120px;
    `}
`;

export const InfoName = styled.div<{ clickable?: boolean }>`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) =>
    props.clickable
      ? css`
          color: #1890ff;
        `
      : css`
          color: #111827;
        `}
`;

export const InfoEmail = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #9ca3af;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InfoText = styled.div`
  margin-top: 2px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #3b82f6;
  padding: 2px 4px;
  color: #3b82f6;
  border-radius: 5px;
  background-color: #eff6ff;
`;
