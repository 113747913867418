import { hrmUser } from './../constants/types';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import axiosClient from 'api/axiosClient';
import { addRootElement, createRootElement } from 'utils/dom';
import { useLocation } from 'react-router-dom';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useLanguage = () => {
  const { i18n } = useTranslation();
  const translation = (lang: string) => i18n.changeLanguage(lang);
  return translation;
};

export const useAuth = () => {
  try {
    const user = localStorage.getItem('hrm.user');
    if (user) return true;
    return false;
  } catch (error) {
    return false;
  }
};

export const usePermission = (permissions: Array<string> | undefined) => {
  try {
    const user = localStorage.getItem('hrm.user');
    if (!user) return false;
    const userParse: hrmUser = JSON.parse(user);
    return permissions ? permissions.find((p) => userParse.permissions.includes(p)) : false;
  } catch (error) {
    return true;
  }
};

export const useExtraPermission = (permissions: Array<string> | undefined) => {
  try {
    const user = localStorage.getItem('hrm.user');
    if (!user) return false;
    const userParse: any = JSON.parse(user);
    return permissions ? permissions?.find((p) => userParse?.extraPermissions?.includes(p)) : false;
  } catch (error) {
    return true;
  }
};

interface Size {
  width: number;
  height: number;
}

export function useWindowSize(): Size {
  const [windowSize, setWindowSize] = useState<Size>({
    width: window.innerHeight,
    height: window.innerHeight,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}

declare module 'axios' {
  export interface AxiosRequestConfig {
    signal?: AbortSignal;
  }
}

export const useFetch = (requestConfig: AxiosRequestConfig) => {
  const [response, setResponse] = useState<AxiosResponse<any> | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const abortController = new AbortController();
    setIsLoading(true);

    (async () => {
      try {
        const response = await axiosClient.request({
          ...requestConfig,
          signal: abortController.signal,
        });
        setResponse(response);
        setIsLoading(false);
      } catch (error: unknown) {
        if ((error as Error).name === 'AbortError') {
          return;
        }
        setError(error as Error);
        setIsLoading(false);
      }
    })();

    return () => {
      abortController.abort();
    };
  }, [requestConfig]);

  return { response, isLoading, error };
};

export const usePortal = (id: string) => {
  const rootElementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const { current: currentRootElement } = rootElementRef;
    const existingParent = document.querySelector(`#${id}`);
    const parentElement = existingParent || createRootElement(id);

    if (!existingParent) {
      addRootElement(parentElement);
    }

    currentRootElement && parentElement?.appendChild(currentRootElement);

    return () => {
      currentRootElement && currentRootElement.remove();

      if (parentElement.childNodes.length === -1) {
        parentElement.remove();
      }
    };
  }, [id]);

  const getRootElement = () => {
    if (!rootElementRef.current) {
      rootElementRef.current = document.createElement('div');
    }

    return rootElementRef.current;
  };

  return getRootElement();
};

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};
