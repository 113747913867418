import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Form, Modal, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { useAppSelector } from 'app/hooks';
import {
  selectPermissions,
  selectUser,
  selectUserLoginId,
  updateUser,
} from 'features/auth/authSlice';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { selectEmployee, selectEmployeeList } from 'features/employee/employeeSlice';
import { useTranslation } from 'react-i18next';

import {
  deleteEmployee,
  saveAndApproveEmployee,
  saveAndRequestApproveEmployee,
  updateEmployee,
} from 'features/employee/employeeAction';
import { useDispatch } from 'react-redux';

import {
  PROFILE_APPROVED,
  PROFILE_DENIED,
  PROFILE_DRAFT,
  PROFILE_PENDING,
} from 'constants/profiles';
import EmployeeInformationFields from 'features/employee/components/EmployeeInformationFields';
import FooterButtons from 'features/employee/components/FooterButtons';
import NewContractInformationField from 'features/employee/components/NewContractInformationFields';
import PersonalInformationFields from 'features/employee/components/PersonalInformationFields';
import RecordHistory from 'features/employee/components/RecordHistory';
import RejectFormModal from 'features/employee/components/RejectFormModal';
import SideBarMenu from 'features/employee/components/SideBarMenu';
import SkillInformationFields from 'features/employee/components/SkillInformationFields';
import { EMPLOYEE_KEY_MENU, INITIAL_EMPLOYEE } from 'features/employee/constants/common';
import {
  ContainerLoading,
  EmployeeAddModalContent,
  EmployeeAddModalContentLeft,
  EmployeeAddModalContentRight,
  EmployeeAddModalDivider,
  EmployeeModal,
  EmployeeModalForm,
  LoaderItem,
} from './styled';
import CertificatesFields from 'features/employee/components/CertificatesFields';

const {
  employeeMenuKey,
  personalMenuKey,
  skillMenuKey,
  historyMenuKey,
  allowanceMenuKey,
  bonusMenuKey,
  salaryMenuKey,
  dependantsMenuKey,
  contractMenuKey,
  certificatesMenuKey,
} = EMPLOYEE_KEY_MENU;

interface EmployeeUpdateModalProps {
  isVisible: boolean;
  isShowFullMenu: boolean;
  itemId: any;
  status?: any;
  onClose: () => void;
  paramSortFilter?: any;
}

const EmployeeUpdateModal = (props: EmployeeUpdateModalProps) => {
  const { isVisible, isShowFullMenu, itemId, status, onClose, paramSortFilter } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation(['validation']);

  const dataConfiguration = useAppSelector(selectConfiguration);
  const dataUser = useAppSelector(selectUser);
  const dataUserLoginId = useAppSelector(selectUserLoginId);
  const dataPermissions = useAppSelector(selectPermissions);
  const employee = useAppSelector(selectEmployee);
  const employeeList = useAppSelector(selectEmployeeList);
  const isLoadingData = employee.isGettingDetail;

  const [openKeys, setOpenKeys] = useState(isShowFullMenu ? employeeMenuKey : skillMenuKey);
  const [employeeInit, setEmployeeInit] = useState(INITIAL_EMPLOYEE);

  const [isRequriedCheckSave, setIsRequriedCheckSave] = useState(false);
  const [isRequriedCheckSendRequest, setIsRequriedCheckSendRequest] = useState(false);
  const [isRequriedCheckApprove, setIsRequriedCheckApprove] = useState(false);

  const [isVisibleRejectForm, setIsVisibleRejectForm] = useState(false);

  const handleOpenRejectForm = () => {
    setIsVisibleRejectForm(true);
  };

  const handleCloseRejectForm = () => {
    setIsVisibleRejectForm(false);
    handleClose();
  };

  const loadData = () => {
    const data = { ...employee?.employeeDetail };
    const onBoardStatus =
      dataConfiguration?.dataOnBoardStatus && data.onBoardStatus
        ? dataConfiguration?.dataOnBoardStatus?.find(
            (item: any) => item.value === data.onBoardStatus?.id,
          )
        : null;
    const level =
      dataConfiguration?.dataLevel && data?.level
        ? dataConfiguration?.dataLevel?.find((item: any) => item?.value === data?.level?.id)
        : null;
    const contractType =
      dataConfiguration?.dataContractType && data.contractType
        ? dataConfiguration?.dataContractType?.find(
            (item: any) => item.value === data.contractType?.id,
          )
        : null;
    const sex =
      dataConfiguration?.dataSex && data.sex
        ? dataConfiguration?.dataSex?.find((item: any) => item.value === data.sex?.id)
        : null;
    const maritalStatus =
      dataConfiguration?.dataMaritalStatus && data.maritalStatus
        ? dataConfiguration?.dataMaritalStatus?.find(
            (item: any) => item.value === data.maritalStatus?.id,
          )
        : null;
    const religion =
      dataConfiguration?.dataReligion && data.religion
        ? dataConfiguration?.dataReligion?.find((item: any) => item.value === data.religion?.id)
        : null;
    const ethnicity =
      dataConfiguration?.dataEthnicity && data.ethnicity
        ? dataConfiguration?.dataEthnicity?.find((item: any) => item.value === data.ethnicity?.id)
        : null;
    const degree =
      dataConfiguration?.dataDegree && data.degree
        ? dataConfiguration?.dataDegree?.find((item: any) => item.value === data.degree?.id)
        : null;
    const positions =
      data.positions && data.positions?.length > 0
        ? data.positions?.map((item: any) => ({ value: item.id, label: item.name }))
        : [];
    const departments =
      data.departments && data.departments?.length > 0
        ? data.departments?.map((item: any) => ({ value: item.id, label: item.name }))
        : [];
    const managers =
      data.managers && data.managers?.length > 0
        ? data.managers?.map((item: any) => ({
            value: item.employeeId,
            label: `${item.lastName} ${item.firstName}`,
          }))
        : [];
    const roles =
      data.roles && data.roles?.length > 0
        ? data.roles?.map((item: any) => ({ value: item.id, label: item.name }))
        : [];
    const location =
      dataConfiguration?.dataLocation && data?.location
        ? dataConfiguration?.dataLocation?.find((i) => i.value === data?.location?.id)
        : null;

    const initValues = data
      ? {
          ...data,
          startDate: data.startDate ? moment(data.startDate) : null,
          endDate: data.endDate ? moment(data.endDate) : null,
          dob: data.dob ? moment(data.dob) : null,
          cidIssuedDate: data.cidIssuedDate ? moment(data.cidIssuedDate) : null,
          contractStartDate: data.contractStartDate ? moment(data.contractStartDate) : null,
          contractExpirationDate: data.contractExpirationDate
            ? moment(data.contractExpirationDate)
            : null,
          trainingPeriodFrom: data.trainingPeriodFrom ? moment(data.trainingPeriodFrom) : null,
          trainingPeriodTo: data.trainingPeriodTo ? moment(data.trainingPeriodTo) : null,

          onBoardStatus: onBoardStatus,
          level: level,
          contractType: contractType,
          sex: sex,
          maritalStatus: maritalStatus,
          religion: religion,
          ethnicity: ethnicity,
          degree: degree,

          positions: positions,
          departments: departments,
          managers: managers,
          roles: roles,
          location,
        }
      : INITIAL_EMPLOYEE;
    setEmployeeInit(initValues);
    form.setFieldsValue(initValues);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee, dataConfiguration, form]);

  const initDataSubmit = (values: any) => {
    let departmentConvert = null;
    if (Array.isArray(values?.departments)) {
      departmentConvert =
        values?.departments.length > 0
          ? {
              id: values?.departments[0]?.value,
              name: values?.departments[0]?.label,
            }
          : null;
    } else {
      departmentConvert = values?.departments?.value
        ? {
            id: values?.departments?.value,
            name: values?.departments?.label,
          }
        : null;
    }

    let managerConvert = null;
    if (Array.isArray(values?.managers)) {
      managerConvert =
        values?.managers.length > 0
          ? {
              id: values?.managers[0]?.value,
              name: values?.managers[0]?.label,
            }
          : null;
    } else {
      managerConvert = values?.managers?.value
        ? {
            id: values?.managers?.value,
            name: values?.managers?.label,
          }
        : null;
    }

    let positionConvert = null;
    if (Array.isArray(values?.positions)) {
      positionConvert =
        values?.positions.length > 0
          ? {
              id: values?.positions[0]?.value,
              name: values?.positions[0]?.label,
            }
          : null;
    } else {
      positionConvert = values?.positions?.value
        ? {
            id: values?.positions?.value,
            name: values?.positions?.label,
          }
        : null;
    }

    let roleConvert = null;
    if (Array.isArray(values?.roles)) {
      roleConvert =
        values?.roles.length > 0
          ? {
              id: values?.roles[0]?.value,
              name: values?.roles[0]?.label,
            }
          : null;
    } else {
      roleConvert = values?.roles?.value
        ? {
            id: values?.roles?.value,
            name: values?.roles?.label,
          }
        : null;
    }

    const tempEmployee = {
      ...employee?.employeeDetail,
      ...values,
      startDate: values.startDate ? moment(values.startDate).toISOString() : null,
      endDate: values.endDate ? moment(values.endDate).toISOString() : null,
      dob: values.dob ? moment(values.dob).toISOString() : null,
      cidIssuedDate: values.cidIssuedDate ? moment(values.cidIssuedDate).toISOString() : null,
      contractStartDate: values.contractStartDate
        ? moment(values.contractStartDate).toISOString()
        : null,
      contractExpirationDate: values.contractExpirationDate
        ? moment(values.contractExpirationDate).toISOString()
        : null,
      trainingPeriodFrom: values.trainingPeriodFrom
        ? moment(values.trainingPeriodFrom).toISOString()
        : null,
      trainingPeriodTo: values.trainingPeriodTo
        ? moment(values.trainingPeriodTo).toISOString()
        : null,

      onBoardStatusId: values?.onBoardStatus?.value.toString(),
      levelId: values?.level?.value.toString(),
      contractTypeId: values?.contractType?.value.toString(),

      sexId: values?.sex?.value.toString(),
      maritalStatusId: values?.maritalStatus?.value.toString(),
      religionId: values?.religion?.value.toString(),
      ethnicityId: values?.ethnicity?.value.toString(),
      degreeId: values?.degree?.value.toString(),

      departments: departmentConvert ? [departmentConvert] : [],
      managers: managerConvert && managerConvert.id ? [managerConvert] : [],
      positions: positionConvert ? [positionConvert] : [],
      roles: roleConvert ? [roleConvert] : [],
      locationId: values?.location?.value,

      skill: employeeInit.skill,
      relative: employeeInit.relative,
    };
    return tempEmployee;
  };

  const handleClickMenu = (keys: any) => {
    setOpenKeys(keys.key);
  };

  const handleResetAll = () => {
    setOpenKeys(isShowFullMenu ? employeeMenuKey : skillMenuKey);
    setIsRequriedCheckSave(false);
    setIsRequriedCheckSendRequest(false);
    setIsRequriedCheckApprove(false);
    form.resetFields();
    loadData();
  };

  const handleSubmit = (typeSubmit: string, values: any) => {
    const dataSubmit = initDataSubmit(values);
    if (dataUserLoginId === dataSubmit.id) {
      // Change info header page
      const fullName = `${dataSubmit.lastName} ${dataSubmit.firstName}`;
      dispatch(
        updateUser({
          user: {
            ...dataUser,
            fullName: fullName,
          },
        }),
      );
    }
    switch (typeSubmit) {
      case 'save':
        dispatch(
          updateEmployee({
            idItem: itemId,
            dataRequest: dataSubmit,
            employeeList: employeeList,
            paramSortFilter: paramSortFilter,
          }),
        );
        break;
      case 'send_request_approve':
        dispatch(
          saveAndRequestApproveEmployee({
            dataSave: dataSubmit,
            dataSendRequest: {
              idProfile: itemId,
              idUserCreate: dataUserLoginId,
              messageRequest: '',
            },
            paramSortFilter: paramSortFilter,
          }),
        );
        break;
      case 'approve':
        dispatch(
          saveAndApproveEmployee({
            dataSave: dataSubmit,
            dataApprove: {
              idProfile: itemId,
              idUserCreate: dataUserLoginId,
              messageRequest: '',
              isApproved: true,
            },
            paramSortFilter: paramSortFilter,
          }),
        );
        break;
      default:
        break;
    }
  };

  const handleSave = () => {
    const statusRecord = employeeInit.status ? employeeInit.status : PROFILE_DRAFT;
    if (statusRecord === PROFILE_DRAFT) {
      setIsRequriedCheckSave(true);
      setIsRequriedCheckSendRequest(false);
      setIsRequriedCheckApprove(false);
    }
    if (statusRecord === PROFILE_PENDING || statusRecord === PROFILE_DENIED) {
      setIsRequriedCheckSave(false);
      setIsRequriedCheckSendRequest(true);
      setIsRequriedCheckApprove(false);
    }
    if (statusRecord === PROFILE_APPROVED) {
      setIsRequriedCheckSave(false);
      setIsRequriedCheckSendRequest(false);
      setIsRequriedCheckApprove(true);
    }

    form
      .validateFields()
      .then((success) => {
        handleSubmit('save', success);
        handleClose();
      })
      .catch((error) => {
        if (isRequriedCheckSave || error?.errorFields.length <= 0) {
          handleSubmit('save', error.values);
          handleClose();
        } else {
          Modal.error({
            title: t('modal:message_invalid'),
          });
        }
      });
  };

  const handleSendRequestApprove = () => {
    setIsRequriedCheckSave(false);
    setIsRequriedCheckSendRequest(true);
    setIsRequriedCheckApprove(false);

    form
      .validateFields()
      .then((success) => {
        handleSubmit('send_request_approve', success);
        handleClose();
      })
      .catch((error) => {
        if (error?.errorFields.length <= 0) {
          handleSubmit('send_request_approve', error.values);
          handleClose();
        } else {
          Modal.error({
            title: t('modal:message_invalid'),
          });
        }
      });
  };

  const handleApprove = () => {
    setIsRequriedCheckSave(false);
    setIsRequriedCheckSendRequest(false);
    setIsRequriedCheckApprove(true);

    form
      .validateFields()
      .then((success) => {
        handleSubmit('approve', success);
        handleClose();
      })
      .catch((error) => {
        if (error?.errorFields.length <= 0) {
          handleSubmit('approve', error.values);
          handleClose();
        } else {
          Modal.error({
            title: t('modal:message_invalid'),
          });
        }
      });
  };

  const handleRejectProfile = () => {
    handleOpenRejectForm();
  };

  const handleDeleteProfile = () => {
    Modal.confirm({
      title: t('modal:confirm_delete', { field: 'profile' }),
      icon: <ExclamationCircleOutlined />,
      okText: t('modal:btn_ok'),
      okType: 'danger',
      cancelText: t('modal:btn_cancel'),
      onOk: () => {
        dispatch(deleteEmployee({ idItem: itemId, employeeList: employeeList }));
        handleClose();
      },
    });
  };

  const handleClose = () => {
    handleResetAll();
    onClose();
  };

  return (
    <>
      <EmployeeModal
        visible={isVisible}
        maskClosable={false}
        onCancel={handleClose}
        width={1000}
        style={{ top: 20 }}
        footer={
          !(status === 'deleted')
            ? !isLoadingData &&
              isShowFullMenu && (
                <FooterButtons
                  profileStatus={employee?.employeeDetail?.status}
                  permissions={dataPermissions}
                  handleSave={handleSave}
                  handleResetAll={handleResetAll}
                  handleSaveAndSendRequestApprove={() => null}
                  handleSaveAndApproveProfile={() => null}
                  handleSendRequestApprove={handleSendRequestApprove}
                  handleApproveProfile={handleApprove}
                  handleRejectProfile={handleRejectProfile}
                  handleDeleteProfile={handleDeleteProfile}
                />
              )
            : null
        }
      >
        <EmployeeAddModalContent>
          <EmployeeAddModalContentLeft>
            <SideBarMenu
              status={status}
              isShowFullMenu={isShowFullMenu}
              isShowContract
              employeeData={employeeInit}
              openKeys={openKeys}
              employeeMenuKey={employeeMenuKey}
              personalMenuKey={personalMenuKey}
              skillMenuKey={skillMenuKey}
              historyMenuKey={historyMenuKey}
              allowanceMenuKey={allowanceMenuKey}
              bonusMenuKey={bonusMenuKey}
              salaryMenuKey={salaryMenuKey}
              dependantsMenuKey={dependantsMenuKey}
              contractMenuKey={contractMenuKey}
              certificatesMenuKey={certificatesMenuKey}
              handleClickMenu={handleClickMenu}
              onCloseModal={handleClose}
            />
          </EmployeeAddModalContentLeft>
          <EmployeeAddModalDivider type="vertical" />
          <EmployeeAddModalContentRight>
            {isLoadingData ? (
              <ContainerLoading>
                <LoaderItem>
                  <Spin size="large" />
                </LoaderItem>
              </ContainerLoading>
            ) : (
              <EmployeeModalForm
                form={form}
                id="EmployeeForm"
                layout="vertical"
                initialValues={employeeInit}
              >
                <EmployeeInformationFields
                  isShowComponents={openKeys === employeeMenuKey}
                  isRequriedCheckSave={isRequriedCheckSave}
                  isRequriedCheckSendRequest={isRequriedCheckSendRequest}
                  isRequriedCheckApprove={isRequriedCheckApprove}
                  formData={form}
                  status={status}
                />
                <PersonalInformationFields
                  isShowComponents={openKeys === personalMenuKey}
                  isMyProfile={false}
                  isRequriedCheckSave={isRequriedCheckSave}
                  isRequriedCheckSendRequest={isRequriedCheckSendRequest}
                  isRequriedCheckApprove={isRequriedCheckApprove}
                  employeeData={employeeInit}
                  setEmployeeData={setEmployeeInit}
                  formData={form}
                  status={status}
                />
                <SkillInformationFields
                  isShowComponents={openKeys === skillMenuKey}
                  isCanUpdate={isShowFullMenu}
                  employeeData={employeeInit}
                  setEmployeeData={setEmployeeInit}
                  status={status}
                />
                <CertificatesFields
                  isShowComponents={openKeys === certificatesMenuKey}
                  isCanUpdate={isShowFullMenu}
                  employeeData={employeeInit}
                  setEmployeeData={setEmployeeInit}
                  status={status}
                />
                {openKeys === contractMenuKey && <NewContractInformationField id={itemId} />}
                {openKeys === historyMenuKey && <RecordHistory itemId={itemId} />}
              </EmployeeModalForm>
            )}
          </EmployeeAddModalContentRight>
        </EmployeeAddModalContent>
      </EmployeeModal>
      <RejectFormModal
        isVisible={isVisibleRejectForm}
        itemId={itemId}
        onClose={handleCloseRejectForm}
        paramSortFilter={paramSortFilter}
      />
    </>
  );
};

export default React.memo(EmployeeUpdateModal);
