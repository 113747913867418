import { modalRedirect } from 'components/ModalRedirect';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import i18n from 'i18next';
import { getUser, removeStorage } from './auth';

let checkExpired = false;

const methodRequired = ['delete', 'post', 'put'];
export interface IError {
  [key: string]: Array<string>;
  __global: Array<string>;
}
interface IMessageResponse {
  statusCode: number;
  message: string;
  path: string;
  url: string;
  timestamp: number;
  method: string;
  fieldErrors?: IError;
}

export const handleMessage = (messageResponse: IMessageResponse | undefined) => {
  if (!messageResponse) {
    throw new NetworkError();
  }

  const { statusCode, message, url, method, fieldErrors = { __global: [''] } } = messageResponse;

  const excludeUrlToShowNoti = [
    'timesheet/working-month/request-approval-list-mobile',
    '/contract-employee/contract-management',
  ];

  // console.log(excludeUrlToShowNoti.includes(url),url)

  const statusCodeSwitch: Record<number | string, Function> = {
    200: () =>
      methodRequired.includes(method) &&
      url.slice(url.lastIndexOf('/')).search(/(search)/) < 0 &&
      !excludeUrlToShowNoti.includes(url) &&
      new SuccessMessage(message),
    201: () =>
      methodRequired.includes(method) &&
      url.slice(url.lastIndexOf('/')).search(/(search)/) < 0 &&
      !excludeUrlToShowNoti.includes(url) &&
      new SuccessMessage(message),
    202: () =>
      methodRequired.includes(method) &&
      url.slice(url.lastIndexOf('/')).search(/(search)/) < 0 &&
      !excludeUrlToShowNoti.includes(url) &&
      new SuccessMessage(message),
    400: () => {
      throw new BadRequestError(fieldErrors, message);
    },
    401: () => {
      if (getUser()) {
        checkExpired = true;
        removeStorage();
        modalRedirect(() => {
          checkExpired = false;
        });
      }
      throw new UnauthorizedError(checkExpired ? i18n.t('common:session_expired') : message);
    },
    404: () => {
      throw new NotFoundError();
    },
    500: () => {
      throw new InternalServerError();
    },
    default: () => {},
  };

  (statusCodeSwitch[statusCode || 'default'] || statusCodeSwitch['default'])();
};

class SuccessMessage {
  constructor(message: string) {
    this.showMessageForUser(message);
  }

  showMessageForUser(message: string) {
    notificationToast(Notification.Type.Success, message, Notification.Duration._3s);
  }
}

export class BadRequestError extends Error {
  private fieldErrors: IError;
  constructor(fieldErrors: IError, message: string) {
    super('Something was wrong with your Request Body');
    this.name = 'BadRequestError';
    this.fieldErrors = fieldErrors;
    this.showMessageErrorForUser(message);
  }

  getFieldErrors() {
    return this.fieldErrors;
  }

  showMessageErrorForUser(message: string) {
    notificationToast(Notification.Type.Error, message, Notification.Duration._3s);
  }
}

export class NotFoundError extends Error {
  constructor() {
    super('Nothing in here, comeback later!');
    this.name = 'NotFoundError';
    this.showMessageErrorForUser();
  }

  showMessageErrorForUser() {
    notificationToast(Notification.Type.Error, this.message, Notification.Duration._3s);
  }
}

export class InternalServerError extends Error {
  constructor() {
    super('Server is busy. Please try again later!');
    this.name = 'NotFoundError';
    this.showMessageErrorForUser();
  }

  showMessageErrorForUser() {
    notificationToast(Notification.Type.Error, this.message, Notification.Duration._3s);
  }
}

export class NetworkError extends Error {
  constructor() {
    super('Network is not working!');
    this.name = 'NetworkError';
    this.showMessageErrorForUser();
  }

  showMessageErrorForUser() {
    notificationToast(Notification.Type.Error, this.message, Notification.Duration._3s);
  }
}

export class UnauthorizedError extends Error {
  constructor(message: string) {
    super('Unauthorized, Session expired!');
    this.name = 'UnauthorizedError';
    this.showMessageErrorForUser(message);
  }

  showMessageErrorForUser(message: string) {
    notificationToast(Notification.Type.Error, message, Notification.Duration._3s);
  }
}
