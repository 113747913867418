import { PlusOutlined } from '@ant-design/icons';
import { useAppSelector } from 'app/hooks';
import ITVSearch from 'components/ITVSearch';
import PageHeader from 'components/PageHeader';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import CustomButton from 'styles/buttonStyled';
import JobsRequisitionManagementTable from './components/JobsRequisitionManagementTable';
import { Modal } from 'antd';
import CreateJob from './components/CreateJob';
import { getUser } from 'utils/auth';
import { PermissionUser } from './components/types';
import { useTranslation } from 'react-i18next';

export enum VIEW_SCREEN {
  CREATE_JOB = 'CREATE_JOB',
  COMPLETE_A_FORM = 'COMPLETE_A_FORM',
  USE_A_TEMPLATE = 'USE_A_TEMPLATE',
}
const JobsRequisitionManagement = () => {
  const { t } = useTranslation(['recruitment']);
  const location = useLocation();
  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = menuStore?.dataScreenManage?.result;
  const [titleHeader, setTitleHeader] = useState('');
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState('');
  const [currentScreen, setCurrentScreen] = useState(VIEW_SCREEN.CREATE_JOB);

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item) => location.pathname === item.path)
      ?.map((item) => item.nameScreen);

    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  return (
    <>
      <PageHeader title={titleHeader} />
      <div style={{ marginBottom: '1rem' }}>
        {getUser()?.permissions?.includes(PermissionUser.RECRUIT_JOB_CREATED) && (
          <CustomButton
            icon={<PlusOutlined />}
            onClick={() => {
              setVisible(true);
            }}
          >
            {t('recruitment:create_job')}
          </CustomButton>
        )}
      </div>
      <ITVSearch style={{ marginBottom: '1rem' }} handleSearch={setSearch} />
      <JobsRequisitionManagementTable search={search} />
      <Modal
        open={visible}
        onCancel={() => {
          setVisible(false);
        }}
        footer={null}
        destroyOnClose
        width={currentScreen === VIEW_SCREEN.COMPLETE_A_FORM ? 1000 : undefined}
      >
        <CreateJob setCurrentScreen={setCurrentScreen} setVisible={setVisible} />
      </Modal>
    </>
  );
};

export default JobsRequisitionManagement;
