import React, { useMemo } from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';
import Avatar from 'components/Avatar';
import { Tooltip } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

const TooltipTitle = (props: any) => {
  const { fullName, email } = props;
  return (
    <div>
      <div>Name: {fullName}</div>
      <div>Email: {email}</div>
    </div>
  );
};

TooltipTitle.propTypes = {
  fullName: string.isRequired,
  email: string.isRequired,
};

const UserItem = (props: any) => {
  const history = useHistory();
  const { avatar, fullName, email, onClick, id, candidateId } = props;
  const TooltipTitleMemo = useMemo(
    () => <TooltipTitle fullName={fullName} email={email} />,
    [fullName, email],
  );
  return (
    <Container>
      <Container
        onClick={() => {
          history.push(`/recruitment/candidates/detail/${id}/${candidateId}`);
        }}
      >
        <Avatar name={avatar} />
        <Tooltip title={TooltipTitleMemo}>
          <Info>
            <InfoName>{fullName}</InfoName>
            <InfoEmail>{email}</InfoEmail>
          </Info>
        </Tooltip>
      </Container>
      <EllipsisOutlined rotate={90} onClick={onClick} />
    </Container>
  );
};

UserItem.propTypes = {
  fullName: string.isRequired,
  email: string.isRequired,
  avatar: string.isRequired,
};

export default React.memo(UserItem);

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const Info = styled.div`
  margin-left: 1rem;
`;

export const InfoName = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* color: #111827; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InfoEmail = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #9ca3af;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
