import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Table, DatePicker } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import ITVTable from 'components/ITVTable';
import { selectRecruitmentData } from 'features/recruitment/recruitmentSlice';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

interface AddToJobProps {
  onAddJob: (job: any) => void;
  onRemoveJob: (job: any) => void;
  addedJobs: any[];
}

const AddToJob: React.FC<AddToJobProps> = ({ onAddJob, onRemoveJob, addedJobs }) => {
  const { t } = useTranslation(['recruitment', 'action']);
  const recruitmentData = useSelector(selectRecruitmentData);
  const listJobApproved = recruitmentData?.listJobApproved || [];
  const [selectedJobs, setSelectedJobs] = useState<any[]>([]);
  const [appliedDates, setAppliedDates] = useState<{ [key: string]: moment.Moment | null }>({});

  useEffect(() => {
    setSelectedJobs(addedJobs);
    const initialAppliedDates = addedJobs.reduce((acc, job) => {
      acc[job.id] = job.appliedDate ? moment(job.appliedDate) : null;
      return acc;
    }, {});
    setAppliedDates(initialAppliedDates);
  }, [addedJobs]);

  const handleAddJob = useCallback(
    (job: any) => {
      const updatedJob = {
        ...job,
        appliedDate: appliedDates[job.id] ? appliedDates[job.id].format('YYYY-MM-DD') : null,
      };
      setSelectedJobs((prev) => {
        const existingJobIndex = prev.findIndex((j) => j.id === job.id);
        if (existingJobIndex !== -1) {
          const updatedJobs = [...prev];
          updatedJobs[existingJobIndex] = updatedJob;
          return updatedJobs;
        }
        return [...prev, updatedJob];
      });
      onAddJob(updatedJob);
    },
    [onAddJob, appliedDates],
  );

  const handleRemoveJob = useCallback(
    (job: any) => {
      setSelectedJobs((prev) => prev.filter((j) => j.id !== job.id));
      onRemoveJob(job);
    },
    [onRemoveJob],
  );

  const handleDateChange = (date: moment.Moment | null, record: any) => {
    setAppliedDates((prev) => ({ ...prev, [record.id]: date }));
    if (selectedJobs.some((job) => job.id === record.id)) {
      const updatedJob = { ...record, appliedDate: date ? date.format('YYYY-MM-DD') : null };
      setSelectedJobs((prev) => prev.map((job) => (job.id === record.id ? updatedJob : job)));
      onAddJob(updatedJob);
    }
  };

  const columns = [
    {
      title: t('recruitment:position_name'),
      dataIndex: 'positionName',
      key: 'positionName',
      width: 300,
      minWidth: 300,
      render: (value, record) => record?.jobTemplate?.positionName,
    },
    {
      title: t('recruitment:location'),
      dataIndex: 'jobLocation',
      key: 'jobLocation',
      width: 300,
      minWidth: 300,
      render: (value, record) => record?.jobTemplate?.location?.name,
    },
    {
      title: t('recruitment:applied_date'),
      dataIndex: 'appliedDate',
      key: 'appliedDate',
      width: 150,
      minWidth: 150,
      render: (value, record) => (
        <DatePicker
          value={appliedDates[record.id] || null}
          onChange={(date) => handleDateChange(date, record)}
        />
      ),
    },
    {
      title: t('action:action'),
      key: 'action',
      width: 80,
      minWidth: 80,
      align: 'center',
      render: (_, record) => {
        const isAdded = selectedJobs.some((job) => job.id === record.id);
        return (
          <Button
            type="text"
            icon={isAdded ? <MinusOutlined /> : <PlusOutlined />}
            onClick={() => {
              if (isAdded) {
                handleRemoveJob(record);
              } else {
                handleAddJob(record);
              }
            }}
          />
        );
      },
    },
  ];

  return <ITVTable columns={columns} data={listJobApproved || []} isRowSelect={false} />;
};

export default AddToJob;
