import { Button, Collapse, Menu, Tabs } from 'antd';
import ButtonBack from 'components/ButtonBack';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import PageHeader from 'components/PageHeader';
import { useAppSelector } from 'app/hooks';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { Container } from '../styled';
import PerformanceWeightTable from './PerformanceWeightTable';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import HeaderPerformance from './HeaderPerformance';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import type { MenuProps } from 'antd';
import { getAssessmentDetail, updateAssessmentForm } from 'features/performance/performanceAction';
import { getUser } from 'utils/auth';
import { selectPerformanceData } from 'features/performance/performanceSlice';
import CustomButton from 'styles/buttonStyled';
import { STATUS_PERFORMANCE } from 'constants/commons';
import { selectRole } from 'features/role/roleSlice';

type MenuItem = Required<MenuProps>['items'][number];

const { Panel } = Collapse;

const PerformanceDetail = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation([
    'action',
    'dashboard',
    'employee',
    'onboard',
    'timesheet',
    'payroll',
    'performance',
  ]);
  const { id, detailId }: any = useParams();

  const location: any = useLocation();
  // const stateURL = location.state;
  // const id = stateURL?.detail
  const history = useHistory();
  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = menuStore?.dataScreenManage?.result;
  const performanceStore = useAppSelector(selectPerformanceData);
  const assessmentDetail = performanceStore?.assessmentDetail;
  const role = useAppSelector(selectRole).data;
  const level = menuStore.dataLevel || [];

  const roleOptions = role?.map((x: any) => ({
    label: x?.name,
    value: x?.id,
  }));

  const [disable, setDisable] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    resolver: undefined,
    defaultValues: useMemo(() => {
      return assessmentDetail;
    }, [assessmentDetail]),
  });

  useEffect(() => {
    reset(assessmentDetail);
    const checkIsSomeAllowEdit = assessmentDetail?.steps?.some((step) =>
      step?.data?.some((dt) => dt?.allowEdit),
    );
    const checkIncludeInStep = assessmentDetail?.steps?.some((step) =>
      step?.data?.some((dt) => dt?.assessorId === getUser()?.id),
    );
    if (
      !checkIsSomeAllowEdit &&
      checkIncludeInStep &&
      !(
        assessmentDetail?.status === STATUS_PERFORMANCE.WAITTNG_TO_ACCEPT &&
        getValues('isProcessStep') == false
      )
    ) {
      if (!getValues('isShowAccept')) setDisable(true);
    }
  }, [assessmentDetail]);

  const callApi = () =>
    dispatch(
      getAssessmentDetail({
        reviewCycleId: detailId ? detailId : id,
        userId: detailId ? id : getUser()?.id,
      }),
    );
  useEffect(() => {
    if (detailId || id) {
      callApi();
    }
  }, [id, detailId, dispatch]);

  const showSubmitOrSend = () => {
    const isSubmit = assessmentDetail?.steps?.some((step) =>
      step?.data?.some((dt) => dt?.stepNumber == 1 && dt?.allowEdit == true),
    );
    if (isSubmit) return t('action:submit');
    return t('onboard:send');
  };

  const onSubmit = (data: any, _?: any, isSave = false) => {
    const newRole =
      roleOptions
        ?.map((i) => ({ id: i?.value, name: i?.label }))
        ?.find((option) => option?.id === data?.newRole) || null;
    const newLevel =
      level
        ?.map((i) => ({ id: i?.id, name: i?.name }))
        .find((option) => option?.id === data?.newLevel) || null;
    const body = {
      id: data?.id,
      action: isSave ? 'Save' : 'Submit',
      totalRating: data?.totalRating,
      isPromoted: data?.isPromoted,
      newRole,
      newLevel,
      userInform: data?.userInform,
      general: data?.general,
      steps: data?.steps,
      maxRatingScore: data?.maxRatingScore,
      status: getValues('isShowAccept') ? STATUS_PERFORMANCE.ACCEPT : '',
    };
    dispatch(
      updateAssessmentForm({
        id: data?.id,
        body,
        callBack: () => {
          callApi();
        },
      }),
    );
  };

  return (
    <Container>
      {<ButtonBack handleBack={() => history.goBack()} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ display: 'flex', alignItems: 'center', gap: 16, margin: '16px 0' }}>
          {!disable && (
            <>
              <CustomButton type="primary" htmlType="submit">
                {!getValues('isShowAccept') || getValues('allowEditAsessment')
                  ? showSubmitOrSend()
                  : t('performance:accept_evaluate')}
              </CustomButton>
              {!getValues('isShowAccept') && (
                <CustomButton
                  aType="outline"
                  onClick={() => {
                    onSubmit(getValues(), undefined, true);
                  }}
                >
                  {t('action:save')}
                </CustomButton>
              )}
            </>
          )}
          <StatusWrapper style={{ color: getValues('statusColor') || 'inherit' }}>
            • {getValues('status')}
          </StatusWrapper>
        </div>
        <EmployeeInfoWrapper>
          {t('performance:pending_review.employee_name')}:{' '}
          {getValues ? getValues('user.fullName') : ''}
        </EmployeeInfoWrapper>
        {getValues('isProcessStep') === false ? (
          <>
            <HeaderPerformance
              control={control}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
            />
            <Collapse>
              {assessmentDetail?.steps?.map((step: any, index: any) => {
                return (
                  <>
                    <Panel
                      header={`${step?.competency?.name} (${t('performance:weight')}: ${
                        step?.competency?.weightPercent
                      })`}
                      key={index}
                    >
                      <PerformanceWeightTable
                        section={`steps[${index}]`}
                        control={control}
                        errors={errors}
                        getValues={getValues}
                        setValue={setValue}
                        data={step?.data}
                      />
                    </Panel>
                  </>
                );
              })}
            </Collapse>
          </>
        ) : (
          <Tabs>
            {assessmentDetail?.steps?.map((step: any, index: any) => {
              return (
                <Tabs.TabPane
                  tab={`${step?.competency?.name} (${t('performance:weight')}: ${
                    step?.competency?.weightPercent
                  })`}
                  key={index}
                >
                  <PerformanceWeightTable
                    section={`steps[${index}]`}
                    control={control}
                    errors={errors}
                    getValues={getValues}
                    setValue={setValue}
                    data={step?.data}
                  />
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        )}
      </form>
    </Container>
  );
};

export default PerformanceDetail;

export const StatusWrapper = styled.div`
  border-radius: 18px;
  padding: 5px 8px;
`;

const EmployeeInfoWrapper = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
`;
