import { Badge, Button, Col, DatePicker, Form, Modal, Radio, Row, Select, Tabs } from 'antd';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import qs from 'query-string';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import omit from 'lodash/omit';

import payrollApi from 'api/payrollApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import PageHeader from 'components/PageHeader';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import { KEY_EMPLOYEE_TAB, MONTH_YEAR_FORMAT } from 'constants/payroll';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { setCheckRow } from 'features/employee/employeeSlice';
import {
  AdjustmentSalary,
  Allowance,
  Bonus,
  ContractSalary,
  Dependent,
} from 'features/payroll/components';
import { Advance } from 'features/payroll/components/Advance';
import { ContainerBgWhite } from 'styles/containerBgWhite';
import { StatusTabPayroll } from 'types';
import { getErrorMessage } from 'utils/getErrorMessage';
import { path } from 'constants/path';
import { TAB_KEYS } from 'constants/timesheet';
import { DatePickerIcon } from 'assets/icons';
import CustomButton from 'styles/buttonStyled';
import { setGlobalLoading } from 'app/commonRedux/appSlice';
import { getAllWorkflowType } from 'features/configuration/configurationAction';

const StyledCheckCircleFilled = styled(CheckCircleFilled)`
  font-size: 12px;
  color: var(--cl_success500);
`;

const StyledCloseCircleFilled = styled(CloseCircleFilled)`
  font-size: 12px;
  color: var(--cl_error500);
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContainerHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledWrapperContainer = styled.div`
  max-width: 100px;

  .ant-picker {
    border-radius: 6px;
    border: 1px solid var(--cl_neutral300);
  }
`;

const ViewEmployee = () => {
  const { t } = useTranslation(['status', 'employee', 'onboard', 'timesheet', 'payroll']);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const history = useHistory();
  const location: any = useLocation();
  const searchParams = qs.parse(location.search);
  const tab = (searchParams.tab as string) || KEY_EMPLOYEE_TAB.ALLOWANCE;
  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = (menuStore?.dataScreenManage as any)?.result;
  const dataListWorkflowType = menuStore?.dataListWorkflowType;
  const [titleHeader, setTitleHeader] = useState('');
  const [statusTabPayroll, setStatusTabPayroll] = useState<StatusTabPayroll>();
  const [monthYear, setMonthYear] = useState(moment(new Date()).format(MONTH_YEAR_FORMAT));
  const [visible, setVisible] = useState(false);
  const [optionWF, setOptionWF] = useState([]);

  const showPayrollBtn =
    Object.values(omit(statusTabPayroll, 'monthYear')).length === 0
      ? false
      : Object.values(omit(statusTabPayroll, 'monthYear', 'idPayroll')).every((status) => status);

  useEffect(() => {
    fetchListStatusTabPayrollAPI();
    dispatch(getAllWorkflowType({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthYear]);

  const formatData = (data: any) => {
    const dataFlat = data?.map((item: any) => {
      return item?.workflowTypeDetail?.map((itemWL: any) => {
        return { ...item, workflowTypeDetail: itemWL };
      });
    });
    return dataFlat?.flat(Infinity) || [];
  };
  useEffect(() => {
    setOptionWF(formatData(dataListWorkflowType));
    form.setFieldValue('workFlowTypeId', formatData(dataListWorkflowType)[0]?.id || undefined);
  }, [dataListWorkflowType]);

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item: any) => location.pathname === item.path)
      ?.map((item: any) => item.nameScreen);

    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  const fetchListStatusTabPayrollAPI = async (isGoPayroll?: boolean) => {
    try {
      const response = await payrollApi.getStatusTabPayroll(monthYear);
      setStatusTabPayroll(response?.data);
      if (isGoPayroll && response?.data?.idPayroll) {
        history.push(`${path.payrollDetail}/${response?.data?.idPayroll}`);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      notificationToast(Notification.Type.Error, message, Notification.Duration._3s);
    }
  };

  const tabPaneList = [
    {
      tab: t('employee:insurance_information_fields.insurance'),
      key: KEY_EMPLOYEE_TAB.INSURANCE,
      children: <></>,
      isConfirm: Boolean(statusTabPayroll?.isInsurance),
    },
    {
      tab: t('employee:timesheet_information_fields.timesheet'),
      key: KEY_EMPLOYEE_TAB.TIMESHEET,
      children: <></>,
      isConfirm: Boolean(statusTabPayroll?.isTimesheet),
    },
    {
      tab: t('employee:allowance_information_fields.allowance'),
      key: KEY_EMPLOYEE_TAB.ALLOWANCE,
      children: (
        <Allowance
          monthYear={monthYear}
          fetchListStatusTabPayrollAPI={fetchListStatusTabPayrollAPI}
        />
      ),
      isConfirm: Boolean(statusTabPayroll?.isAllowance),
    },
    {
      tab: t('employee:bonus_information_fields.bonus'),
      key: KEY_EMPLOYEE_TAB.BONUS,
      children: (
        <Bonus monthYear={monthYear} fetchListStatusTabPayrollAPI={fetchListStatusTabPayrollAPI} />
      ),
      isConfirm: Boolean(statusTabPayroll?.isBonus),
    },
    {
      tab: t('employee:dependant_information_fields.dependents'),
      key: KEY_EMPLOYEE_TAB.DEPENDENTS,
      children: (
        <Dependent
          monthYear={monthYear}
          fetchListStatusTabPayrollAPI={fetchListStatusTabPayrollAPI}
        />
      ),
      isConfirm: Boolean(statusTabPayroll?.isDependant),
    },
    {
      tab: t('employee:advance_information_fields.advance'),
      key: KEY_EMPLOYEE_TAB.ADVANCES,
      children: (
        <Advance
          monthYear={monthYear}
          fetchListStatusTabPayrollAPI={fetchListStatusTabPayrollAPI}
        />
      ),
      isConfirm: Boolean(statusTabPayroll?.isAdvances),
    },
    {
      tab: t('employee:contract_information_fields.contract'),
      key: KEY_EMPLOYEE_TAB.CONTRACT_SALARY,
      children: (
        <ContractSalary
          monthYear={monthYear}
          fetchListStatusTabPayrollAPI={fetchListStatusTabPayrollAPI}
        />
      ),
      isConfirm: Boolean(statusTabPayroll?.isContractSalary),
    },
    {
      tab: t('employee:adjustment_information_fields.adjustment'),
      key: KEY_EMPLOYEE_TAB.ADJUSTMENT_SALARY,
      children: (
        <AdjustmentSalary
          monthYear={monthYear}
          fetchListStatusTabPayrollAPI={fetchListStatusTabPayrollAPI}
        />
      ),
      isConfirm: Boolean(statusTabPayroll?.isAdjustmentSalary),
    },
  ];

  const handleMonthChange = (value: Moment | null) => {
    const formattedMonth = moment(value).format(MONTH_YEAR_FORMAT);
    setMonthYear(formattedMonth);
  };

  const handleTabChange = (activeKey: string) => {
    dispatch(setCheckRow([]));

    if (activeKey === KEY_EMPLOYEE_TAB.INSURANCE) {
      history.push({
        pathname: path.compulsoryInsurance,
        search: qs.stringify({
          tab: 'recordpayment',
        }),
      });
    } else if (activeKey === KEY_EMPLOYEE_TAB.TIMESHEET) {
      history.push({
        pathname: path.employeeTimesheet,
        search: qs.stringify({
          tab: TAB_KEYS.MONTH,
        }),
      });
    } else {
      history.push({
        pathname: `${path.payroll}/view-employee`,
        search: qs.stringify({
          tab: activeKey,
        }),
      });
    }
  };

  const handleCreatePayroll = async (workFlowTypeId: any, callBack?: any) => {
    try {
      dispatch(setGlobalLoading({ loading: true }));
      const response = await payrollApi.createPayrollDetails({ monthYear, workFlowTypeId });
      dispatch(setGlobalLoading({ loading: false }));
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      fetchListStatusTabPayrollAPI(true);
      response && callBack && callBack();
    } catch (error) {
      dispatch(setGlobalLoading({ loading: false }));
      const message = getErrorMessage(error);
      notificationToast(Notification.Type.Error, message, Notification.Duration._3s);
    }
  };

  const handleGotoPayroll = () => {
    if (statusTabPayroll?.idPayroll) {
      history.push(`${path.payrollDetail}/${statusTabPayroll?.idPayroll}`);
    }
  };

  const handleFinish = (values: any) => {
    handleCreatePayroll(values?.workFlowTypeId, () => setVisible(false));
  };

  return (
    <StyledContainer>
      <ContainerHead>
        <TitleContainer>
          <PageHeader title={titleHeader} />
          <StyledWrapperContainer>
            <DatePicker
              picker="month"
              defaultValue={moment(new Date())}
              onChange={handleMonthChange}
              allowClear={false}
              suffixIcon={<DatePickerIcon />}
            />
          </StyledWrapperContainer>
        </TitleContainer>
        {showPayrollBtn ? (
          !statusTabPayroll?.idPayroll ? (
            <CustomButton
              onClick={() => {
                setVisible(true);
              }}
            >
              {t('payroll:create_payroll_detail')}
            </CustomButton>
          ) : (
            <CustomButton onClick={handleGotoPayroll}>
              {t('payroll:go_payroll_detail')}
            </CustomButton>
          )
        ) : (
          ''
        )}
      </ContainerHead>

      <ContainerBgWhite>
        <Tabs
          activeKey={tab}
          centered
          destroyInactiveTabPane
          size="large"
          onChange={handleTabChange}
        >
          {tabPaneList.map((tabPane) => (
            <Tabs.TabPane
              tab={
                <Badge
                  count={
                    tabPane.isConfirm ? <StyledCheckCircleFilled /> : <StyledCloseCircleFilled />
                  }
                  offset={[16, 0]}
                >
                  {tabPane.tab}
                </Badge>
              }
              key={tabPane.key}
            >
              {tabPane.children}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </ContainerBgWhite>
      <Modal
        open={visible}
        onCancel={() => {
          setVisible(false);
        }}
        maskClosable={false}
        footer={null}
      >
        <Form form={form} onFinish={handleFinish}>
          <div style={{ fontSize: 16, textAlign: 'center', marginBottom: 8, fontWeight: 'bold' }}>
            {'Please select Payroll workflow'}
          </div>
          <Form.Item name="workFlowTypeId">
            <Radio.Group style={{ width: 200 }} defaultValue={optionWF[0]?.id}>
              {optionWF?.map((wf: any) => (
                <Radio
                  value={wf?.id}
                >{`${wf?.workflowTypeDetail?.workflowTypeCode} - ${wf?.workflowTypeDetail?.workflowTypeName}`}</Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item noStyle>
            <Row>
              <Col
                span={24}
                style={{
                  textAlign: 'right',
                }}
              >
                <Button type="primary" htmlType="submit">
                  {t('action:save')}
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </StyledContainer>
  );
};

export default ViewEmployee;
