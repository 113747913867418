import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Drawer } from 'antd';
import UserItem from './UserItem';
import UserForm from './UserForm';
import { useAppDispatch } from 'app/hooks';
import { updateJobCandidate } from 'features/recruitment/recruitmentAction';

enum HiringProcessStatusCandidate {
  APPLICATION_RECEIVED = 'Application Received',
  SHORTLISTED = 'Shortlisted',
  IN_PROGRESS = 'In Progress',
  JOB_OFFER = 'Job Offer',
  OFFER_ACCEPTED = 'Offer Accepted',
  OFFER_REJECTED = 'Offer Rejected',
  CLOSED = 'Closed',
}

const CandidatesBoard = ({ reload, data, handleChange }) => {
  const [columns, setColumns] = useState({});
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const dispatch = useAppDispatch();

  const isDragEnabled = true;
  // getUser()?.permissions?.includes('RECRUIT_JOB_CREATED') && data?.status !== JobStatus.APPROVED;

  useEffect(() => {
    if (data && Array.isArray(data?.candidates)) {
      const newColumns = data.candidates.reduce((acc, column, index) => {
        const columnKey = `${column?.status}_${index}`;
        acc[columnKey] = {
          name: column?.status,
          items: column?.candidates?.map((candidate) => ({
            id: candidate?.candidateProcessId,
            name: candidate?.candidateName,
            status: candidate?.status,
            positionName: candidate?.positionName,
            candidateRequisitionId: candidate?.candidateRequisitionId,
            stageOrder: candidate?.stageOrder,
            type: candidate?.type,
            candidateId: candidate?.candidateId,
          })),
        };
        acc[columnKey].items.sort((a, b) => a?.stageOrder - b?.stageOrder);
        return acc;
      }, {});
      setColumns(newColumns);
    }
  }, [data]);

  const convertColumnsToPayload = (columns) => {
    const result = [];
    Object.entries(columns).forEach(([columnId, column], index) => {
      column?.items?.forEach((item) => {
        result.push({
          candidateRequisitionId: item?.candidateRequisitionId,
          stageOrder: index,
        });
      });
    });
    return result;
  };

  const onDragEnd = (result) => {
    if (!isDragEnabled || !result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination?.droppableId) {
      const sourceColumn = columns[source?.droppableId];
      const destColumn = columns[destination?.droppableId];
      const sourceItems = [...sourceColumn?.items];
      const destItems = [...destColumn?.items];
      const [removed] = sourceItems?.splice(source?.index, 1);
      destItems?.splice(destination?.index, 0, removed);
      const newColumns = {
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      };
      setColumns(newColumns);
      const movedItem = {
        candidateRequisitionId: removed?.candidateRequisitionId,
        stageOrder: Object.keys(columns)?.indexOf(destination.droppableId),
      };
      dispatch(updateJobCandidate({ body: movedItem, callBack: reload }));
      handleChange && handleChange((value: any) => ({ ...value, candidates: newColumns }));
    } else {
      const column = columns[source?.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source?.index, 1);
      copiedItems.splice(destination?.index, 0, removed);
      const newColumns = {
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      };
      setColumns(newColumns);
      const movedItem = {
        candidateRequisitionId: removed.candidateRequisitionId,
        stageOrder: Object.keys(columns)?.indexOf(source.droppableId),
      };
      handleChange && handleChange((value: any) => ({ ...value, candidates: newColumns }));
    }
  };

  const showDrawer = (user) => {
    setCurrentUser(user);
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  return (
    <div
      style={{ display: 'flex', justifyContent: 'flex-start', height: '100%', overflowX: 'auto' }}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        {Object.entries(columns)?.map(([columnId, column], index) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: '0 10px',
                whiteSpace: 'nowrap',
              }}
              key={columnId}
            >
              <h2 style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {column?.name}
              </h2>
              <div style={{ margin: 8 }}>
                <Droppable droppableId={columnId} key={columnId} isDropDisabled={!isDragEnabled}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          background: snapshot.isDraggingOver ? 'lightblue' : 'lightgrey',
                          padding: 4,
                          width: 250,
                          minHeight: 500,
                          maxHeight: 500,
                          overflowY: 'auto',
                        }}
                      >
                        {column?.items?.map((item, index) => {
                          return (
                            <Draggable
                              key={item?.id}
                              draggableId={item?.id}
                              index={index}
                              isDragDisabled={!isDragEnabled}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      userSelect: 'none',
                                      padding: 16,
                                      margin: '0 0 8px 0',
                                      minHeight: '50px',
                                      backgroundColor: snapshot.isDragging ? '#263B4A' : 'white',
                                      color: 'black',
                                      ...provided.draggableProps.style,
                                    }}
                                  >
                                    <UserItem
                                      onClick={() => showDrawer(item)}
                                      fullName={item?.name}
                                      email={item?.positionName}
                                      avatar={item?.name}
                                      id={item?.candidateId}
                                      candidateId={item?.candidateRequisitionId}
                                    />
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided?.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            </div>
          );
        })}
      </DragDropContext>
      <Drawer
        title={currentUser?.name}
        placement="right"
        onClose={onClose}
        open={
          ([
            HiringProcessStatusCandidate.JOB_OFFER,
            HiringProcessStatusCandidate.OFFER_ACCEPTED,
            HiringProcessStatusCandidate.OFFER_REJECTED,
          ]?.includes(currentUser?.status) ||
            currentUser?.type !== 'Other') &&
          drawerVisible
        }
        destroyOnClose
        forceRender
        width={600}
        // extra={<p></p>}
      >
        {<UserForm key={currentUser?.id} currentUser={currentUser} onCancel={onClose} />}
      </Drawer>
    </div>
  );
};

export default CandidatesBoard;
