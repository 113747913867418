import Icon, {
  DeleteOutlined,
  DownOutlined,
  EditTwoTone,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  SendOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Dropdown, Menu, Modal, Popover, Table } from 'antd';
import { selectCurrencyFormat, selectFormatDate } from 'app/commonRedux/appSlice';
import { useAppSelector } from 'app/hooks';
import IconAdd from 'assets/icons/ic_add_gray.svg';
import IconExport from 'assets/icons/ic_export_gray.svg';
import EmployeeColumn from 'components/EmployeeColumn';
import ITVSearch from 'components/ITVSearch';
import ITVTable from 'components/ITVTable';
import MenuAction from 'components/MenuAction';
import { getCategoryWithType } from 'features/configuration/categoryAction';
import { getDepartmentList, selectDepartment } from 'features/department/departmentSlice';
import SearchPopup from 'features/employee/components/SearchPopup';
import { selectEmployee, setCheckRow } from 'features/employee/employeeSlice';
import { HeaderRemove } from 'features/masterData/Department/styled';
import {
  exportPayrollDetailExcel,
  exportPayrollDetailPdf,
  getPayrollGroupUser,
  sendActionPayroll,
  sendPayslipManual,
  syncPayroll,
  updatePayroll,
} from 'features/payroll/payrollAction';
import { selectPayroll } from 'features/payroll/payrollSlice';
import ModalFormReason from 'features/timesheet/components/ModalFormReason';
import { TaxProfiles } from 'hrm-common/extensions/enums/personel';
import moment from 'moment';
import numeral from 'numeral';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import { TitleColumnCenter } from 'styles/tableStyled';
import { getUser } from 'utils/auth';
import { capitalizeFirstLetter, removeAccents } from 'utils/text';
import ViewPayslip from '../ViewPayroll/components/ViewPayslip';
import HistoryRequest from './HistoryRequest';
import ViewEdit from './ViewEdit';
import AddColumn from './components/AddColumn';
import ViewAddMember from './components/ViewAddMember';
import { checkPermissionCurrentUser, getActionCurrentUser } from './functionForWorkFlow';
import HeaderTop from './components/HeaderUp';
import HeaderDown from './components/HeaderDown';

enum MODE {
  VIEW = 'VIEW',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  VIEW_HISTORY = 'VIEW_HISTORY',
}
enum TypeEditPayroll {
  TITLE = 'title',
  USER = 'user',
}
const filterDefault = {
  sortBy: 'status',
  orderBy: 'asc',
};

const ManageRequestTab = (props: any) => {
  const IconExportComponents = (
    <CustomIcon type={'export'} component={() => <img src={IconExport} alt="" />} />
  );
  const { id }: any = useParams();
  const {
    showData,
    sizePage,
    setSizePage,
    currentPage,
    setCurrentPage,
    setSort,
    setSortBy,
    setSearch,
    callApi,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation([
    'timesheet',
    'action',
    'status',
    'employee',
    'dashboard',
    'payroll',
  ]);
  const [data, setData] = useState([]); //dataMock.payroll
  const [rejectModalVisible, setRejectModalVisible] = useState(false);
  const [mode, setMode] = useState<string>('');
  const [currentItem, setCurrentItem] = useState<any>(null);
  const [isVisibleAddColumn, setIsVisibleAddColumn] = useState(false);
  const [, setWidthTable] = useState(550);
  const childRefAddColumn = useRef<any>();
  const payrollGroupUser = useAppSelector(selectPayroll)?.payrollGroupUser;
  const loadingPayrollGroupUser = useAppSelector(selectPayroll)?.loadingPayrollGroupUser;
  const dateFormat = useAppSelector(selectFormatDate);
  const currencyFormat = useAppSelector(selectCurrencyFormat);
  const [selectUser, setSelectUser] = useState<any>([]);
  const dataEmployee: any = useAppSelector(selectEmployee);
  const selectRowKey = dataEmployee?.selectRowKey;
  const [showModal, setShowModal] = useState(false);
  const [allowanceColAdd, setAllowanceColAdd] = useState({
    grossTax: false,
    grossNoTax: false,
    noGrossTax: false,
    noGrossNoTax: false,
  });
  const [bonusColAdd, setBonusColAdd] = useState({
    tax: false,
    noTax: false,
  });
  const [workflowType, setupWorkflowType] = useState<any>(null);
  const [currentWorkflowStatus, setCurrentWorkflowStatus] = useState<any>(null);
  const [currentActionId, setCurrentActionId] = useState<any>(null);
  const [selectUserPayRollId, setSelectUserPayRollId] = useState<any>([]);
  const departments = useAppSelector(selectDepartment).data;
  const [, setIsFilter] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const colEmployeeTableRoot = [
    {
      label: t('payroll:salary'),
      value: 'salary',
    },
    {
      label: t('payroll:timesheet'),
      value: 'timesheet',
    },
    {
      label: t('payroll:allowance'),
      value: 'allowance',
    },
    {
      label: t('payroll:businessSalary'),
      value: 'businessSalary',
    },
    {
      label: t('payroll:salaryThisPeriod'),
      value: 'salaryThisPeriod',
    },
    {
      label: t('payroll:bonus'),
      value: 'bonus',
    },
    {
      label: t('payroll:adjustment'),
      value: 'adjust',
    },
    {
      label: t('payroll:overtime'),
      value: 'overtime',
    },
    {
      label: t('payroll:totalIncomeThisPeriod'),
      value: 'totalIncomeThisPeriod',
    },
    {
      label: t('payroll:no_of_dependents'),
      value: 'dependents',
    },
    {
      label: t('payroll:pitDeduction'),
      value: 'pitDeduction',
    },
    {
      label: t('payroll:taxableIncome'),
      value: 'taxableIncome',
    },
    {
      label: t('payroll:taxedIncome'),
      value: 'taxedIncome',
    },
    {
      label: 'PIT',
      value: 'pit',
    },
    {
      label: t('payroll:insurance'),
      value: 'insurance',
    },
    {
      label: t('payroll:advance'),
      value: 'advance',
    },
    {
      label: t('payroll:actual_take_home'),
      value: 'actual',
    },
    {
      label: t('payroll:total_cost'),
      value: 'total',
    },
  ];
  const [addColumnTableHeader, setAddColumnTableHeader] = useState(colEmployeeTableRoot);

  useEffect(() => {
    // Get dataset department
    dispatch(
      getDepartmentList({
        filters: filterDefault,
      }),
    );
    // Get dataset position
    dispatch(
      getCategoryWithType({
        typeConfig: 'position',
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    setSelectUser(
      selectRowKey?.filter(
        (item: any) => !selectUserPayRollId?.some((value: any) => value === item),
      ),
    );
    setSelectUserPayRollId(
      showModal
        ? selectRowKey?.filter((item: any) => !selectUser?.some((value: any) => value === item))
        : selectRowKey,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectRowKey]);

  const callAPiGetUserGroup = () => {
    callApi && callApi();
  };

  const formatDataIncludeUserIds = (data: any) => {
    return data?.map((item: any) => ({ ...item, id: item?.user?.id }));
  };
  useEffect(() => {
    if (payrollGroupUser) {
      setColumns(COLUMNS);
      setData(formatDataIncludeUserIds(payrollGroupUser?.payroll));
      setFilteredData(formatDataIncludeUserIds(payrollGroupUser?.payroll));
      setupWorkflowType(payrollGroupUser?.workflowType);
      setCurrentWorkflowStatus(payrollGroupUser?.payrollDetails?.status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payrollGroupUser]);

  const handleHideAddColumn = () => {
    setIsVisibleAddColumn(false);
  };
  const handleVisibleChange = (visible: any) => {
    setIsVisibleAddColumn(visible);
  };

  const MENU = [
    {
      name: t('timesheet:View_Detail'),
      icon: <EditTwoTone twoToneColor="#F1592A" />,
      checkDisabled: () =>
        !checkPermissionCurrentUser(workflowType, 'view', currentWorkflowStatus, getUser()?.id),
      handleClick: (value: any) => {
        setCurrentItem(value);
        setMode(MODE.UPDATE);
      },
    },
    {
      name: t('action:delete'),
      type: 'delete',
      icon: <DeleteOutlined />,
      checkDisabled: () =>
        !checkPermissionCurrentUser(workflowType, 'delete', currentWorkflowStatus, getUser()?.id),
      handleClick: (item: any) => {
        const currentData = payrollGroupUser?.payroll
          ?.map((item: any) => item?.user)
          ?.filter((i: any) => i?.id !== item?.user?.id)
          ?.map((item: any) => item?.id);
        const body = {
          userIds: [...currentData],
          type: TypeEditPayroll.USER,
        };
        dispatch(
          updatePayroll({
            id,
            body,
            callBack: () => {
              callAPiGetUserGroup();
              setShowModal(false);
              dispatch(setCheckRow([]));
            },
          }),
        );
      },
    },
  ];

  const handleClickAction = (actionId: any) => {
    setRejectModalVisible(true);
    setCurrentActionId(actionId);
  };

  const menu = (
    <Menu>
      {getActionCurrentUser(workflowType, currentWorkflowStatus, getUser()?.id)?.map(
        (item: any, index: any) => {
          return (
            <Menu.Item key={index} onClick={() => handleClickAction(item?.actionId)}>
              {item?.label}
            </Menu.Item>
          );
        },
      )}
    </Menu>
  );

  const COLUMNS: any = [
    {
      title: t('employee:employee_information_fields.id'),
      dataIndex: 'employeeId',
      key: 'employeeId',
      width: 100,
      minWidth: 100,
      align: 'center',
      fixed: 'left',
      sorter: {
        compare: (a: any, b: any) => {
          if (a.user?.employeeId && b.user?.employeeId)
            return a.user?.employeeId.localeCompare(b.user?.employeeId);
          return 0;
        },
        multiple: 3,
      },
      render: function (text: any, record: any, index: any) {
        return record?.user?.employeeId;
      },
    },
    {
      title: t('employee:employee_information_fields.employee'),
      dataIndex: 'employee',
      key: 'employee',
      width: 230,
      minWidth: 230,
      ellipsis: true,
      fixed: 'left',
      render: function (text: any, record: any, index: any) {
        return (
          <EmployeeColumn
            avatar={record?.user?.fullName}
            fullName={record?.user?.fullName}
            email={record?.user?.email}
          />
        );
      },
    },
    {
      title: t('employee:employee_information_fields.line'),
      dataIndex: 'projectPrimaryName',
      align: 'left',
      key: 'projectPrimaryName',
      width: 200,
      minWidth: 200,
      render: function (text: any, record: any, index: any) {
        return record?.user?.projectPrimaryName || '';
      },
    },
    {
      title: t('employee:employee_information_fields.position'),
      dataIndex: 'position',
      align: 'left',
      key: 'position',
      width: 100,
      minWidth: 100,
      ellipsis: true,
      render: function (text: any, record: any, index: any) {
        return record?.user?.positionName || '';
      },
    },
    {
      title: <HeaderDown>{t('dashboard:contract.contract_type')}</HeaderDown>,
      dataIndex: 'contract',
      align: 'left',
      key: 'contract',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        const value = record?.dataPayroll?.dataPayroll?.salary?.name || '';
        switch (value) {
          case TaxProfiles.MORE_THAN_THREE_MONTH:
            return t('employee:contract_information_fields.more_than_month');
          case TaxProfiles.LESS_THAN_THREE_MONTH:
            return t('employee:contract_information_fields.less_than_month');
          case TaxProfiles.INTERN:
            return t('employee:contract_information_fields.intern');
          case TaxProfiles.PROBATION:
            return t('employee:contract_information_fields.probation');
          default:
            return '';
        }
      },
    },
    {
      title: t('employee:salary_information_fields.col_start_date'),
      dataIndex: 'startDate',
      align: 'left',
      key: 'startDate',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        const value = record?.user?.contractStartDate;
        return value ? moment(value)?.format(dateFormat) : '';
      },
    },
    {
      title: t('employee:salary_information_fields.col_end_date'),
      dataIndex: 'endDate',
      align: 'left',
      key: 'endDate',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        const value = record?.user?.endDate;
        return value ? moment(value)?.format(dateFormat) : '';
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('employee:employee_information_fields.action')}</TitleColumnCenter>
        );
      },
      dataIndex: 'action',
      key: 'action',
      width: 60,
      minWidth: 60,
      align: 'center',
      fixed: 'right',
      render: (status: any, record: any, index: any) => {
        return <MenuAction menu={MENU} data={record} />;
      },
    },
  ];
  const [columns, setColumns] = useState(COLUMNS);

  const removeDuplicates = (arr: Array<string>) => {
    const uniq = new Set(arr.map((e) => JSON.stringify(e)));
    const res = Array.from(uniq).map((e) => JSON.parse(e));
    return res;
  };

  const getAllAllowance = () => {
    const dataAllowances = data
      ?.map((item: any) => item?.dataPayroll?.dataPayroll?.salary?.allowances)
      ?.filter((item: any) => item) //remove undefined
      ?.flat(1);
    //?.map((item) => item.name); //get all string name
    return removeDuplicates(dataAllowances);
  };

  const getAllOtRate = () => {
    const dataOtRate = data
      ?.map((item: any) => item?.dataPayroll?.dataPayroll?.overtime)
      ?.filter((item: any) => item) //remove undefined
      ?.flat(1)
      ?.map((item) => item.rate); //get all string name
    return removeDuplicates(dataOtRate);
  };

  const getAllBonus = () => {
    const dataBonus = data
      ?.map((item: any) => item?.dataPayroll?.dataPayroll?.bonus)
      ?.filter((item: any) => item) //remove undefined
      ?.flat(1);
    //?.map((item) => item.name); //get all string name
    return removeDuplicates(dataBonus);
  };

  const addColumns = (cols: any) => {
    const dataIndexs = columns.map((item: any) => item.dataIndex);
    const arr = COLUMNS.filter((item: any) => dataIndexs.includes(item.dataIndex));

    const dataAllowances = getAllAllowance();
    const dataAllowancesGross = dataAllowances.filter((item: any) => item?.isGross);
    const dataAllowancesNoGross = dataAllowances.filter((item: any) => !item?.isGross);

    const dataOtRate = getAllOtRate()?.sort();

    const dataBonus = getAllBonus();

    // eslint-disable-next-line array-callback-return
    const temp = cols.map((item: any) => {
      if (item.value === 'salary') {
        return {
          title: item.label,
          dataIndex: item.value,
          key: item.value,
          children: [
            {
              title: <HeaderTop>{t('payroll:gross')}</HeaderTop>,
              dataIndex: 'gross',
              key: 'gross',
              align: 'right',
              ellipsis: true,
              width: 100,
              minWidth: 100,
              render: function (salary: any, record: any, index: any) {
                const value = record?.dataPayroll?.dataPayroll?.salary?.grossSalary || 0;
                return numeral(value).format(currencyFormat);
              },
            },
            {
              title: <HeaderTop>{t('payroll:main')}</HeaderTop>,
              dataIndex: 'main',
              key: 'main',
              align: 'right',
              ellipsis: true,
              width: 100,
              minWidth: 100,
              render: function (salary: any, record: any, index: any) {
                const value = record?.dataPayroll?.dataPayroll?.salary?.mainSalary || 0;
                return numeral(value).format(currencyFormat);
              },
            },
          ],
        };
      }
      if (item.value === 'timesheet') {
        return {
          title: item.label,
          dataIndex: item.value,
          key: item.value,
          children: [
            {
              title: <HeaderTop margin={-16}>{t('payroll:standard_hours')}</HeaderTop>,
              dataIndex: 'paidHours',
              key: 'paidHours',
              align: 'right',
              ellipsis: false,
              width: 100,
              minWidth: 100,
              render: function (text: any, record: any, index: any) {
                const value = record?.dataPayroll?.dataPayroll?.timeSheet?.standardHours;
                return value || 0;
              },
            },
            {
              title: <HeaderTop margin={-16}>{t('payroll:leave_off_hours')}</HeaderTop>,
              dataIndex: 'paidLeave',
              key: 'paidLeave',
              align: 'right',
              ellipsis: false,
              width: 100,
              minWidth: 100,
              render: function (text: any, record: any, index: any) {
                const value = record?.dataPayroll?.dataPayroll?.timeSheet?.leaveOffHours;
                return value || 0;
              },
            },
            {
              title: <HeaderTop>{t('payroll:actual_hours')}</HeaderTop>,
              dataIndex: 'actual',
              key: 'actual',
              align: 'right',
              ellipsis: false,
              width: 100,
              minWidth: 100,
              render: function (text: any, record: any, index: any) {
                const value = record?.dataPayroll?.dataPayroll?.timeSheet?.actualHours;
                return value || 0;
              },
            },
            {
              title: <HeaderTop margin={-16}>{t('payroll:total_paid_hours')}</HeaderTop>,
              dataIndex: 'actual',
              key: 'actual',
              align: 'right',
              ellipsis: false,
              width: 100,
              minWidth: 100,
              render: function (text: any, record: any, index: any) {
                const value = record?.dataPayroll?.dataPayroll?.timeSheet?.totalPaidHours;
                return value || 0;
              },
            },
          ],
        };
      }
      if (item.value === 'allowance') {
        return {
          title: item.label,
          dataIndex: item.value,
          key: item.value,
          children: [
            {
              title: (
                <div>
                  <div>{t('payroll:gross')}</div>
                  {/* <div>{`(${dataAllowancesGross?.map((x) => x?.name).join(', ')})`}</div> */}
                </div>
              ),
              dataIndex: 'gross',
              key: 'gross',
              children: [
                {
                  title: () => (
                    <div>
                      {t('payroll:tax')}{' '}
                      {allowanceColAdd?.grossTax ? (
                        <MinusCircleOutlined
                          style={{ color: '#f1592a' }}
                          onClick={() => handleCloseColumn('grossTax', item.value)}
                        />
                      ) : (
                        <PlusCircleOutlined
                          style={{ color: '#3b82f6' }}
                          onClick={() => handleAddColumn('grossTax', item.value)}
                        />
                      )}
                    </div>
                  ),
                  dataIndex: 'tax',
                  key: 'tax',
                  width: 130,
                  minWidth: 100,
                  align: 'right',
                  children: allowanceColAdd?.grossTax
                    ? [
                        ...dataAllowancesGross
                          .filter((item: any) => item?.isTaxed)
                          ?.map((item: any) => ({
                            title: capitalizeFirstLetter(item?.name),
                            dataIndex: item?.name,
                            key: item?.name,
                            align: 'right',
                            ellipsis: true,
                            width: 130,
                            minWidth: 100,
                            render: function (text: any, record: any, index: any) {
                              const value =
                                record?.dataPayroll?.dataPayroll?.salary?.allowances?.find(
                                  (i: any) => i?.name === item?.name,
                                )?.money || 0;
                              //salary marketing
                              return numeral(value).format(currencyFormat);
                            },
                          })),
                        {
                          title: t('payroll:total'),
                          dataIndex: 'total',
                          key: 'total',
                          align: 'right',
                          ellipsis: true,
                          width: 130,
                          minWidth: 100,
                          render: function (text: any, record: any, index: any) {
                            const value =
                              record?.dataPayroll?.dataPayroll?.salary?.allowances?.reduce(
                                (prev: any, i: any) =>
                                  i?.isGross && i?.isTaxed ? prev + i?.money : prev + 0,
                                0,
                              ) || 0;
                            return numeral(value).format(currencyFormat);
                          },
                        },
                      ]
                    : [],
                  render: function (text: any, record: any, index: any) {
                    const value =
                      record?.dataPayroll?.dataPayroll?.salary?.allowances?.reduce(
                        (prev: any, i: any) =>
                          i?.isGross && i?.isTaxed ? prev + i?.money : prev + 0,
                        0,
                      ) || 0;
                    return numeral(value).format(currencyFormat);
                  },
                },
                {
                  title: () => (
                    <div>
                      {t('payroll:no_tax')}{' '}
                      {allowanceColAdd?.grossNoTax ? (
                        <MinusCircleOutlined
                          style={{ color: '#f1592a' }}
                          onClick={() => handleCloseColumn('grossNoTax', item.value)}
                        />
                      ) : (
                        <PlusCircleOutlined
                          style={{ color: '#3b82f6' }}
                          onClick={() => handleAddColumn('grossNoTax', item.value)}
                        />
                      )}
                    </div>
                  ),
                  dataIndex: 'noTax',
                  key: 'noTax',
                  width: 130,
                  minWidth: 100,
                  align: 'right',
                  children: allowanceColAdd?.grossNoTax
                    ? [
                        ...dataAllowancesGross
                          ?.filter((item: any) => !item?.isTaxed)
                          ?.map((item: any) => ({
                            title: capitalizeFirstLetter(item?.name),
                            dataIndex: item?.name,
                            key: item?.name,
                            align: 'right',
                            ellipsis: true,
                            width: 130,
                            minWidth: 100,
                            render: function (text: any, record: any, index: any) {
                              const value =
                                record?.dataPayroll?.dataPayroll?.salary?.allowances?.find(
                                  (i: any) => i?.name === item?.name,
                                )?.money || 0;
                              //salary marketing
                              return numeral(value).format(currencyFormat);
                            },
                          })),
                        {
                          title: t('payroll:total'),
                          dataIndex: 'total',
                          key: 'total',
                          align: 'right',
                          ellipsis: true,
                          width: 130,
                          minWidth: 100,
                          render: function (text: any, record: any, index: any) {
                            const value =
                              record?.dataPayroll?.dataPayroll?.salary?.allowances?.reduce(
                                (prev: any, i: any) =>
                                  i?.isGross && !i?.isTaxed ? prev + i?.money : prev + 0,
                                0,
                              ) || 0;
                            return numeral(value).format(currencyFormat);
                          },
                        },
                      ]
                    : [],
                  render: function (text: any, record: any, index: any) {
                    const value =
                      record?.dataPayroll?.dataPayroll?.salary?.allowances?.reduce(
                        (prev: any, i: any) =>
                          i?.isGross && !i?.isTaxed ? prev + i?.money : prev + 0,
                        0,
                      ) || 0;
                    return numeral(value).format(currencyFormat);
                  },
                },
              ],
            },
            {
              title: (
                <div>
                  <div>{t('payroll:no_gross')}</div>
                  {/* <div>{`(${dataAllowancesNoGross?.map((x) => x?.name).join(', ')})`}</div> */}
                </div>
              ),
              dataIndex: 'noGross',
              key: 'noGross',
              children: [
                {
                  title: () => (
                    <div>
                      {t('payroll:tax')}{' '}
                      {allowanceColAdd?.noGrossTax ? (
                        <MinusCircleOutlined
                          style={{ color: '#f1592a' }}
                          onClick={() => handleCloseColumn('noGrossTax', item.value)}
                        />
                      ) : (
                        <PlusCircleOutlined
                          style={{ color: '#3b82f6' }}
                          onClick={() => handleAddColumn('noGrossTax', item.value)}
                        />
                      )}
                    </div>
                  ),
                  dataIndex: 'tax',
                  key: 'tax',
                  width: 130,
                  minWidth: 100,
                  align: 'right',
                  children: allowanceColAdd?.noGrossTax
                    ? [
                        ...dataAllowancesNoGross
                          ?.filter((item: any) => item?.isTaxed)
                          ?.map((item: any) => ({
                            title: capitalizeFirstLetter(item?.name),
                            dataIndex: item?.name,
                            key: item?.name,
                            align: 'right',
                            ellipsis: true,
                            width: 130,
                            minWidth: 100,
                            render: function (text: any, record: any, index: any) {
                              const value =
                                record?.dataPayroll?.dataPayroll?.salary?.allowances?.find(
                                  (i: any) => i?.name === item?.name,
                                )?.money || 0;
                              return numeral(value).format(currencyFormat);
                            },
                          })),
                        {
                          title: t('payroll:total'),
                          dataIndex: 'total',
                          key: 'total',
                          align: 'right',
                          ellipsis: true,
                          width: 130,
                          minWidth: 100,
                          render: function (text: any, record: any, index: any) {
                            const value =
                              record?.dataPayroll?.dataPayroll?.salary?.allowances?.reduce(
                                (prev: any, i: any) =>
                                  !i?.isGross && i?.isTaxed ? prev + i?.money : prev + 0,
                                0,
                              ) || 0;
                            return numeral(value).format(currencyFormat);
                          },
                        },
                      ]
                    : [],
                  render: function (text: any, record: any, index: any) {
                    const value =
                      record?.dataPayroll?.dataPayroll?.salary?.allowances?.reduce(
                        (prev: any, i: any) =>
                          !i?.isGross && i?.isTaxed ? prev + i?.money : prev + 0,
                        0,
                      ) || 0;
                    return numeral(value).format(currencyFormat);
                  },
                },
                {
                  title: () => (
                    <div>
                      {t('payroll:no_tax')}{' '}
                      {allowanceColAdd?.noGrossNoTax ? (
                        <MinusCircleOutlined
                          style={{ color: '#f1592a' }}
                          onClick={() => handleCloseColumn('noGrossNoTax', item.value)}
                        />
                      ) : (
                        <PlusCircleOutlined
                          style={{ color: '#3b82f6' }}
                          onClick={() => handleAddColumn('noGrossNoTax', item.value)}
                        />
                      )}
                    </div>
                  ),
                  dataIndex: 'noTax',
                  key: 'noTax',
                  width: 130,
                  minWidth: 100,
                  align: 'right',
                  children: allowanceColAdd?.noGrossNoTax
                    ? [
                        ...dataAllowancesNoGross
                          ?.filter((item: any) => !item?.isTaxed)
                          ?.map((item: any) => ({
                            title: capitalizeFirstLetter(item?.name),
                            dataIndex: item?.name,
                            key: item?.name,
                            align: 'right',
                            ellipsis: true,
                            width: 130,
                            minWidth: 100,
                            render: function (text: any, record: any, index: any) {
                              const value =
                                record?.dataPayroll?.dataPayroll?.salary?.allowances?.find(
                                  (i: any) => i?.name === item?.name,
                                )?.money || 0;
                              return numeral(value).format(currencyFormat);
                            },
                          })),
                        {
                          title: t('payroll:total'),
                          dataIndex: 'total',
                          key: 'total',
                          align: 'right',
                          ellipsis: true,
                          width: 130,
                          minWidth: 100,
                          render: function (text: any, record: any, index: any) {
                            const value =
                              record?.dataPayroll?.dataPayroll?.salary?.allowances?.reduce(
                                (prev: any, i: any) =>
                                  !i?.isGross && !i?.isTaxed ? prev + i?.money : prev + 0,
                                0,
                              ) || 0;
                            return numeral(value).format(currencyFormat);
                          },
                        },
                      ]
                    : [],
                  render: function (text: any, record: any, index: any) {
                    const value =
                      record?.dataPayroll?.dataPayroll?.salary?.allowances?.reduce(
                        (prev: any, i: any) =>
                          !i?.isGross && !i?.isTaxed ? prev + i?.money : prev + 0,
                        0,
                      ) || 0;
                    return numeral(value).format(currencyFormat);
                  },
                },
              ],
            },
          ],
        };
      }
      if (item.value === 'businessSalary') {
        return {
          title: <HeaderDown>{item.label}</HeaderDown>,
          dataIndex: item.value,
          key: item.value,
          width: 100,
          minWidth: 100,
          align: 'right',
          render: function (text: any, record: any, index: any) {
            const value = record?.dataPayroll?.marketingSalary || 0;
            //salary marketing
            return numeral(value).format(currencyFormat);
          },
        };
      }
      if (item.value === 'salaryThisPeriod') {
        return {
          title: <HeaderDown>{item.label}</HeaderDown>,
          dataIndex: item.value,
          key: item.value,
          width: 100,
          minWidth: 100,
          align: 'right',
          render: function (text: any, record: any, index: any) {
            const value = record?.dataPayroll?.salaryPeriodForActualWork || 0;
            return <StyledSalary>{numeral(value).format(currencyFormat)}</StyledSalary>;
          },
        };
      }
      if (item.value === 'bonus') {
        return {
          title: item.label,
          dataIndex: item.value,
          key: item.value,
          width: 130,
          minWidth: 100,
          align: 'right',
          children: [
            {
              title: () => (
                <div style={{ marginTop: bonusColAdd?.tax ? 0 : -30 }}>
                  {t('payroll:tax')}{' '}
                  {bonusColAdd?.tax ? (
                    <MinusCircleOutlined
                      style={{ color: '#f1592a' }}
                      onClick={() => handleCloseColumn('tax', item.value)}
                    />
                  ) : (
                    <PlusCircleOutlined
                      style={{ color: '#3b82f6' }}
                      onClick={() => handleAddColumn('tax', item.value)}
                    />
                  )}
                </div>
              ),
              dataIndex: 'tax',
              key: 'tax',
              width: 100,
              minWidth: 100,
              align: 'right',
              children: bonusColAdd?.tax
                ? [
                    ...dataBonus
                      ?.filter((item: any) => item?.isTaxed)
                      ?.map((item: any) => ({
                        title: capitalizeFirstLetter(item?.name),
                        dataIndex: item?.name,
                        key: item?.name,
                        align: 'right',
                        ellipsis: true,
                        width: 100,
                        minWidth: 100,
                        render: function (text: any, record: any, index: any) {
                          const value =
                            record?.dataPayroll?.dataPayroll?.bonus?.find(
                              (i: any) => i?.name === item?.name,
                            )?.money || 0;
                          return numeral(value).format(currencyFormat);
                        },
                      })),
                    {
                      title: t('payroll:total'),
                      dataIndex: 'total',
                      key: 'total',
                      align: 'right',
                      ellipsis: true,
                      width: 100,
                      minWidth: 100,
                      render: function (text: any, record: any, index: any) {
                        const value =
                          record?.dataPayroll?.dataPayroll?.bonus?.reduce(
                            (prev: any, i: any) => (i?.isTaxed ? prev + i?.money : prev + 0),
                            0,
                          ) || 0;
                        return numeral(value).format(currencyFormat);
                      },
                    },
                  ]
                : [],
              render: function (text: any, record: any, index: any) {
                const value =
                  record?.dataPayroll?.dataPayroll?.bonus?.reduce(
                    (prev: any, i: any) => (i?.isTaxed ? prev + i?.money : prev + 0),
                    0,
                  ) || 0;
                return numeral(value).format(currencyFormat);
              },
            },
            {
              title: () => (
                <div style={{ marginTop: bonusColAdd?.noTax ? 0 : -30 }}>
                  {t('payroll:no_tax')}{' '}
                  {bonusColAdd?.noTax ? (
                    <MinusCircleOutlined
                      style={{ color: '#f1592a' }}
                      onClick={() => handleCloseColumn('noTax', item.value)}
                    />
                  ) : (
                    <PlusCircleOutlined
                      style={{ color: '#3b82f6' }}
                      onClick={() => handleAddColumn('noTax', item.value)}
                    />
                  )}
                </div>
              ),
              dataIndex: 'noTax',
              key: 'noTax',
              width: 100,
              minWidth: 100,
              align: 'right',
              children: bonusColAdd?.noTax
                ? [
                    ...dataBonus
                      ?.filter((item: any) => !item?.isTaxed)
                      ?.map((item: any) => ({
                        title: capitalizeFirstLetter(item?.name),
                        dataIndex: item?.name,
                        key: item?.name,
                        align: 'right',
                        ellipsis: true,
                        width: 100,
                        minWidth: 100,
                        render: function (text: any, record: any, index: any) {
                          const value =
                            record?.dataPayroll?.dataPayroll?.bonus?.find(
                              (i: any) => i?.name === item?.name,
                            )?.money || 0;
                          return numeral(value).format(currencyFormat);
                        },
                      })),
                    {
                      title: t('payroll:total'),
                      dataIndex: 'total',
                      key: 'total',
                      align: 'right',
                      ellipsis: true,
                      width: 100,
                      minWidth: 100,
                      render: function (text: any, record: any, index: any) {
                        const value =
                          record?.dataPayroll?.dataPayroll?.bonus?.reduce(
                            (prev: any, i: any) => (!i?.isTaxed ? prev + i?.money : prev + 0),
                            0,
                          ) || 0;
                        return numeral(value).format(currencyFormat);
                      },
                    },
                  ]
                : [],
              render: function (text: any, record: any, index: any) {
                const value =
                  record?.dataPayroll?.dataPayroll?.bonus?.reduce(
                    (prev: any, i: any) => (!i?.isTaxed ? prev + i?.money : prev + 0),
                    0,
                  ) || 0;
                return numeral(value).format(currencyFormat);
              },
            },
          ],
        };
      }
      if (item.value === 'adjust') {
        return {
          title: item.label,
          dataIndex: item.value,
          key: item.value,
          width: 100,
          minWidth: 100,
          align: 'right',
          render: function (text: any, record: any, index: any) {
            const value = record?.dataPayroll?.totalIncomeAdjustment || 0;
            return numeral(value).format(currencyFormat);
          },
        };
      }
      if (item.value === 'overtime') {
        return {
          title: item.label,
          dataIndex: item.value,
          key: item.value,
          children: [
            ...dataOtRate?.map((item: any) => ({
              title: <HeaderTop>{item}</HeaderTop>,
              dataIndex: item,
              key: item,
              align: 'right',
              ellipsis: true,
              width: 100,
              minWidth: 100,
              render: function (text: any, record: any, index: any) {
                const value = record?.dataPayroll?.dataPayroll?.overtime?.find(
                  (i: any) => i?.rate === item,
                )?.overtimeHours;
                return value || 0;
              },
            })),
            {
              title: <HeaderTop>{t('payroll:taxable')}</HeaderTop>,
              dataIndex: 'taxable',
              key: 'taxable',
              align: 'right',
              ellipsis: true,
              width: 100,
              minWidth: 100,
              render: function (text: any, record: any, index: any) {
                const value = record?.dataPayroll?.taxableOvertimePayment || 0;
                return numeral(value).format(currencyFormat);
              },
            },
            {
              title: <HeaderTop>{t('payroll:non_taxable')}</HeaderTop>,
              dataIndex: 'nonTaxable',
              key: 'nonTaxable',
              align: 'right',
              ellipsis: true,
              width: 100,
              minWidth: 100,
              render: function (text: any, record: any, index: any) {
                const value = record?.dataPayroll?.nonTaxableOvertimePayment || 0;
                return numeral(value).format(currencyFormat);
              },
            },
          ],
        };
      }
      if (item.value === 'totalIncomeThisPeriod') {
        return {
          title: <HeaderDown>{item.label}</HeaderDown>,
          dataIndex: item.value,
          key: item.value,
          align: 'right',
          width: 100,
          minWidth: 100,
          render: function (text: any, record: any, index: any) {
            const value = record?.dataPayroll?.totalIncomeBeforeTax || 0;
            return <StyledSalary>{numeral(value).format(currencyFormat)}</StyledSalary>;
          },
        };
      }
      if (item.value === 'dependents') {
        return {
          title: <HeaderDown>{item.label}</HeaderDown>,
          dataIndex: item.value,
          key: item.value,
          width: 100,
          minWidth: 100,
          align: 'right',
          render: function (text: any, record: any, index: any) {
            const value = record?.dataPayroll?.dataPayroll?.deduction?.totalPersonDependant;
            return value || 0;
          },
        };
      }
      if (item.value === 'pitDeduction') {
        return {
          title: <HeaderDown>{item.label}</HeaderDown>,
          dataIndex: item.value,
          key: item.value,
          width: 100,
          minWidth: 100,
          align: 'right',
          render: function (text: any, record: any, index: any) {
            const value = record?.dataPayroll?.totalNonTaxPersonIncome || 0;
            return numeral(value).format(currencyFormat);
          },
        };
      }
      if (item.value === 'taxableIncome') {
        return {
          title: <HeaderDown>{item.label}</HeaderDown>,
          dataIndex: item.value,
          key: item.value,
          width: 100,
          minWidth: 100,
          align: 'right',
          render: function (text: any, record: any, index: any) {
            const value = record?.dataPayroll?.taxableIncome || 0;
            return numeral(value).format(currencyFormat);
          },
        };
      }
      if (item.value === 'taxedIncome') {
        return {
          title: <HeaderDown>{item.label}</HeaderDown>,
          dataIndex: item.value,
          key: item.value,
          width: 100,
          minWidth: 100,
          align: 'right',
          render: function (text: any, record: any, index: any) {
            const value = record?.dataPayroll?.taxedIncome || 0;
            return numeral(value).format(currencyFormat);
          },
        };
      }
      if (item.value === 'pit') {
        return {
          title: item.label,
          dataIndex: item.value,
          key: item.value,
          width: 100,
          minWidth: 100,
          align: 'right',
          render: function (text: any, record: any, index: any) {
            const value = record?.dataPayroll?.totalTaxPersonIncome || 0;
            return numeral(value).format(currencyFormat);
          },
        };
      }
      if (item.value === 'insurance') {
        return {
          title: item.label,
          dataIndex: item.value,
          key: item.value,
          width: 100,
          minWidth: 100,
          align: 'right',
          render: function (text: any, record: any, index: any) {
            const value =
              record?.dataPayroll?.dataPayroll?.insuranceIncomeTax?.contribuEmployee || 0;
            return numeral(value).format(currencyFormat);
          },
        };
      }
      if (item.value === 'advance') {
        return {
          title: item.label,
          dataIndex: item.value,
          key: item.value,
          width: 100,
          minWidth: 100,
          align: 'right',
          render: function (text: any, record: any, index: any) {
            const value = record?.dataPayroll?.totalAdvance || 0;
            return numeral(value).format(currencyFormat);
          },
        };
      }
      if (item.value === 'actual') {
        return {
          title: item.label,
          dataIndex: item.value,
          key: item.value,
          width: 100,
          minWidth: 100,
          align: 'right',
          render: function (text: any, record: any, index: any) {
            const value = record?.dataPayroll?.actualReceiveSalary || 0;
            return numeral(value).format(currencyFormat);
          },
        };
      }
      if (item.value === 'total') {
        return {
          title: item.label,
          dataIndex: item.value,
          key: item.value,
          width: 130,
          minWidth: 100,
          align: 'right',
          render: function (text: any, record: any, index: any) {
            const value = record?.dataPayroll?.totalSalaryCost || 0;
            return numeral(value).format(currencyFormat);
          },
        };
      }
    });
    setColumns([...arr.slice(0, arr.length - 1), ...temp, arr[arr.length - 1]]);
    setWidthTable((width: any) => width + cols.length * 150);
  };

  useEffect(() => {
    if (workflowType) {
      // setColumns(COLUMNS);
      addColumns(addColumnTableHeader);
    }
    if (childRefAddColumn?.current) {
      addColumns(addColumnTableHeader);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bonusColAdd, allowanceColAdd, payrollGroupUser, workflowType, t]);

  useEffect(() => {
    setAddColumnTableHeader(colEmployeeTableRoot);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisibleAddColumn]);

  const handleAddColumn = (key: any, parent: any) => {
    switch (parent) {
      case 'allowance': {
        switch (key) {
          case 'grossTax':
            setAllowanceColAdd({
              ...allowanceColAdd,
              grossTax: true,
            });
            break;
          case 'grossNoTax':
            setAllowanceColAdd({
              ...allowanceColAdd,
              grossNoTax: true,
            });
            break;
          case 'noGrossTax':
            setAllowanceColAdd({
              ...allowanceColAdd,
              noGrossTax: true,
            });
            break;
          case 'noGrossNoTax':
            setAllowanceColAdd({
              ...allowanceColAdd,
              noGrossNoTax: true,
            });
            break;
          default:
            break;
        }
        break;
      }
      case 'bonus': {
        switch (key) {
          case 'tax':
            setBonusColAdd({
              ...bonusColAdd,
              tax: true,
            });
            break;
          case 'noTax':
            setBonusColAdd({
              ...bonusColAdd,
              noTax: true,
            });
            break;
          default:
            break;
        }
        break;
      }
      default:
        break;
    }
  };

  const handleCloseColumn = (key: any, parent: any) => {
    switch (parent) {
      case 'allowance': {
        switch (key) {
          case 'grossTax':
            setAllowanceColAdd({
              ...allowanceColAdd,
              grossTax: false,
            });
            break;
          case 'grossNoTax':
            setAllowanceColAdd({
              ...allowanceColAdd,
              grossNoTax: false,
            });
            break;
          case 'noGrossTax':
            setAllowanceColAdd({
              ...allowanceColAdd,
              noGrossTax: false,
            });
            break;
          case 'noGrossNoTax':
            setAllowanceColAdd({
              ...allowanceColAdd,
              noGrossNoTax: false,
            });
            break;
          default:
            break;
        }
        break;
      }
      case 'bonus': {
        switch (key) {
          case 'tax':
            setBonusColAdd({
              ...bonusColAdd,
              tax: false,
            });
            break;
          case 'noTax':
            setBonusColAdd({
              ...bonusColAdd,
              noTax: false,
            });
            break;
          default:
            break;
        }
        break;
      }
      default:
        break;
    }
  };

  const IconAddComponents = (
    <CustomIcon type={'add'} component={() => <img src={IconAdd} alt="" />} />
  );

  const handleSearch = (value: string) => {
    if (setSearch) {
      // const temp = payrollGroupUser?.payroll?.filter((item: any) => {
      //   return (
      //     item?.user?.fullName && removeAccents(item?.user?.fullName?.toLowerCase()).includes(text)
      //   );
      // });
      // setData(formatDataIncludeUserIds(temp));
      setSearch(value);
    }
  };

  const handleActionSave = (values: any) => {
    const body = {
      payrollDetailsId: Number(id),
      actionId: currentActionId,
      note: values?.reason || '',
    };
    dispatch(
      sendActionPayroll({
        body,
        callBack: () => {
          setRejectModalVisible(false);
          callAPiGetUserGroup();
        },
      }),
    );
  };

  const handleExportExcel = () => {
    const body = {
      userIds: selectUserPayRollId,
    };
    dispatch(exportPayrollDetailExcel({ body, id: +id }));
  };

  const handleExportPdf = () => {
    const body = {
      userIds: selectUserPayRollId,
    };
    dispatch(exportPayrollDetailPdf({ body, id: +id }));
  };

  const handleSendMail = () => {
    const body = {
      userIds: selectUserPayRollId,
      period: filteredData[0]['monthYear'],
    };
    dispatch(sendPayslipManual({ body }));
  };

  const menuExport = (
    <Menu>
      {
        <Menu.Item key="1" onClick={handleExportExcel}>
          Excel
        </Menu.Item>
      }
      {
        <Menu.Item key="2" onClick={handleExportPdf}>
          PDF
        </Menu.Item>
      }
    </Menu>
  );

  // handle onFilter clicked
  const onFilterPopupConfirm = (selectedKeys: any, confirm: any) => {
    // confirm && confirm();
  };

  // filter columns
  function getColumnSearchProps(dataIndex: any, options: any) {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <SearchPopup
          searchText={selectedKeys[0]}
          onPressEnter={() => confirm()}
          options={options}
          confirm={confirm}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          onSearchTextChange={(e: any) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onClickFilter={(checkedValues: any) => onFilterPopupConfirm(selectedKeys, confirm)}
        />
      ),
      filterIcon: (filtered: any) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value: any, record: any) => {
        return record?.user?.[dataIndex] === value;
      },
    };
  }

  const handleChange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: { currentDataSource: Array<any>[] },
  ) => {
    if (!filters?.departmentName?.length && !filters?.positionName?.length) {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    setFilteredData((extra.currentDataSource as any) || []);

    //call api to sort here
    if (sorter.order) {
      // const array: any = [
      //   {
      //     [sorter.columnKey]: sorter.order === 'ascend' ? 'ASC' : 'DESC',
      //   },
      // ];
      setSort(sorter.order === 'ascend' ? 'ASC' : 'DESC');
      setSortBy(sorter.columnKey);
    } else {
      setSort('');
      setSortBy('');
    }
  };

  const handleSync = () => {
    dispatch(
      syncPayroll({
        body: {
          id,
          userIds: selectUserPayRollId,
          type: 'user',
        },
        callBack: () => {
          callApi && callApi();
        },
      }),
    );
  };

  return (
    <>
      <Container>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <SearchContainer>
            <ITVSearch handleSearch={handleSearch} style={{ width: '210px' }} />
          </SearchContainer>
          <div style={{ display: 'flex', gap: 5 }}>
            <CustomButton
              disabled={!selectUserPayRollId?.length}
              onClick={() => {
                handleSync();
                // callApi && callApi()
              }}
            >
              <SyncOutlined />
              Synchronize
            </CustomButton>
            <Popover
              placement="bottom"
              content={
                <AddColumn
                  ref={childRefAddColumn}
                  handleVisibleAddColumn={handleHideAddColumn}
                  addColumns={addColumns}
                  colEmployeeTableRoot={addColumnTableHeader}
                  isHistory={true}
                />
              }
              trigger="click"
              open={isVisibleAddColumn}
              onOpenChange={handleVisibleChange}
            >
              <CustomButton icon={IconAddComponents} aType="text">
                {t('action:add_column')}
              </CustomButton>
            </Popover>
            {currentWorkflowStatus === 'close' && (
              <CustomButton disabled={!selectUserPayRollId?.length} onClick={handleSendMail}>
                <SendOutlined />
                {t('payroll:send_payslip')}
              </CustomButton>
            )}
            <Dropdown
              overlay={menu}
              disabled={
                !getActionCurrentUser(workflowType, currentWorkflowStatus, getUser()?.id)?.length
              }
            >
              <CustomButton aType="outline">
                {t('employee:employee_information_fields.action')} <DownOutlined />
              </CustomButton>
            </Dropdown>
            {
              <>
                <CustomButton
                  disabled={
                    !checkPermissionCurrentUser(
                      workflowType,
                      'create',
                      currentWorkflowStatus,
                      getUser()?.id,
                    )
                  }
                  type="primary"
                  onClick={() => setShowModal(true)}
                  //   disabled={isDisableButton}
                >
                  <PlusOutlined /> {t('payroll:addUser')}
                </CustomButton>
                <Dropdown overlay={menuExport} disabled={!selectUserPayRollId?.length}>
                  <HeaderRemove icon={IconExportComponents}>
                    {t('action:export')} <DownOutlined />
                  </HeaderRemove>
                </Dropdown>
              </>
            }
            {/* <HeaderRemove
              style={{ marginLeft: 5 }}
              icon={<UploadOutlined />}
              onClick={handleExport}
            >
              {t('action:export')}
            </HeaderRemove> */}
          </div>
        </div>
        <ITVTable
          loading={loadingPayrollGroupUser}
          columns={columns}
          data={data}
          filteredData={filteredData}
          onChange={handleChange}
          setSelectedUser={setSelectUserPayRollId}
          height={550}
          totalResult={payrollGroupUser?.totalResult || undefined}
          setOffset={setCurrentPage}
          offset={currentPage}
          setLimit={setSizePage}
          limit={sizePage}
        />
        {/* che */}
        {showData && (
          <div
            style={{
              position: 'absolute',
              top: '174px',
              right: '16px',
              width: '6px',
              height: '38px',
              background: '#D6D9E0',
              zIndex: 999,
            }}
          ></div>
        )}

        {mode !== '' && (
          <Modal
            style={{ top: 0 }}
            open={mode !== ''}
            title={
              mode === MODE.VIEW_HISTORY
                ? 'View History'
                : mode === MODE.UPDATE
                ? t('timesheet:View_Detail')
                : t('timesheet:View_Detail')
            }
            okText={'OK'}
            cancelText={'Cancel'}
            width={mode === MODE.VIEW_HISTORY ? '80%' : 1050}
            maskClosable={false}
            onCancel={() => {
              setMode('');
              setCurrentItem(null);
            }}
            footer={null}
            centered
          >
            {mode === MODE.UPDATE ? (
              <ViewEdit
                status={currentWorkflowStatus}
                workflow={workflowType}
                currentItem={currentItem}
                callBack={callAPiGetUserGroup}
              />
            ) : mode === MODE.VIEW_HISTORY ? (
              <HistoryRequest />
            ) : (
              <ViewPayslip record={currentItem} />
            )}
          </Modal>
        )}
        <Modal
          open={showModal}
          style={{ top: 0 }}
          title={t('overtime:add_member')}
          okText={'Apply'}
          cancelButtonProps={{ style: { display: 'none' } }}
          width={800}
          maskClosable={false}
          onCancel={() => {
            setShowModal(false);
            setSelectUser([]);
          }}
          okButtonProps={{ disabled: !selectUser || selectUser?.length === 0 }}
          onOk={() => {
            const currentData = data?.map(({ user }: any) => user?.id);
            const body = {
              userIds: [...selectUser, ...currentData],
              type: TypeEditPayroll.USER,
            };
            dispatch(
              updatePayroll({
                id,
                body,
                callBack: () => {
                  callAPiGetUserGroup();
                  setShowModal(false);
                  setSelectUser([]);
                },
              }),
            );
          }}
          destroyOnClose
        >
          <ViewAddMember dataOrigin={data} setSelectUser={setSelectUser} />
        </Modal>
        <ModalFormReason
          title={'Note'}
          isModalReason={rejectModalVisible}
          setIsModalReason={setRejectModalVisible}
          handleFinish={handleActionSave}
        />
      </Container>
    </>
  );
};

export default ManageRequestTab;
export const SearchContainer = styled.div`
  width: 200px;
  margin-bottom: 20px;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  background: white;
  width: 100%;
  height: max-content;
  .summary-last-column {
    right: 6px;
  }
  position: relative;
`;
export const Cell = styled(Table.Summary.Cell)`
  text-align: right;
  .ant-table-cell {
    background: red !important;
    width: 100% !important;
    text-align: right;
  }
`;
export const CustomIcon = styled(Icon)`
  ${(props) =>
    props.type === 'search' &&
    css`
      img {
        height: 15px;
        width: 15px;
      }
    `}
  ${(props) =>
    props.type === 'add' &&
    css`
      img {
        padding-bottom: 6px;
        height: 15px;
        width: 15px;
      }
    `}
  ${(props) =>
    props.type === 'export' &&
    css`
      img {
        padding-bottom: 6px;
        height: 20px;
        width: 16px;
      }
    `}
`;

const StyledSalary = styled.div`
  color: var(--cl_success500);
`;
