import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Select } from 'antd';
import { DeleteTwoTone, EditTwoTone, EyeTwoTone, PlusOutlined } from '@ant-design/icons';
import { useAppSelector } from 'app/hooks';
import ITVTable from 'components/ITVTable';
import MenuAction from 'components/MenuAction';
import PageHeader from 'components/PageHeader';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import CustomButton from 'styles/buttonStyled';
import { getCycleList, getPendingReviewPerformance } from '../performanceAction';
import { actions, selectPerformanceData } from '../performanceSlice';
import { getUser } from 'utils/auth';
import EmployeeColumn from 'components/EmployeeColumn';
import ITVSearch from 'components/ITVSearch';
import { useTranslation } from 'react-i18next';
import { TitleColumnCenter } from 'styles/tableStyled';

export default function PerformancePendingReviewPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = menuStore?.dataScreenManage?.result;
  const performanceStore = useAppSelector(selectPerformanceData);
  const pendingReviewPerformance = performanceStore?.pendingReviewPerformance;
  const cycleList = performanceStore?.cycleList || [];

  const { t } = useTranslation(['performance', 'timesheet']);
  const [titleHeader, setTitleHeader] = useState('');
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemCycle, setItemCycle] = useState<any>([]);
  const [cycleId, setCycleId] = useState(null);
  const [searchText, setSearchText] = useState('');
  const { resetpendingReviewPerformance } = actions;

  useEffect(() => {
    if (cycleId === null) {
      dispatch(resetpendingReviewPerformance({}));
    }
  }, [cycleId]);

  useEffect(() => {
    dispatch(getCycleList({}));
  }, [dispatch]);

  useEffect(() => {
    if (cycleList) {
      setItemCycle(
        cycleList?.map((i: any) => ({ value: i?.reviewCycleId, label: i?.reviewCycleName })),
      );
    }
  }, [cycleList]);

  useEffect(() => {
    if (cycleId) {
      const body = {
        limit: sizePage,
        offset: currentPage,
        managerId: getUser()?.employeeId,
        reviewCycleId: cycleId,
        searchName: searchText,
      };
      dispatch(getPendingReviewPerformance(body));
    }
  }, [dispatch, currentPage, sizePage, cycleId, searchText]);

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item) => location.pathname === item.path)
      ?.map((item) => item.nameScreen);
    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  const menu = [
    {
      name: t('timesheet:View_Detail'),
      icon: <EyeTwoTone />,
      handleClick: (data) => {
        history.push({
          pathname: `/performance/pending-review/${data?.userId}/${data?.reviewCycleId}`,
        });
      },
    },
    // Uncomment these if you want to use Edit and Delete actions
    // {
    //   name: 'Edit',
    //   icon: <EditTwoTone twoToneColor="#F1592A" />,
    //   handleClick: (value) => {},
    // },
    // {
    //   name: 'Delete',
    //   icon: <DeleteTwoTone twoToneColor="#B7B7B7" />,
    //   type: 'delete',
    //   handleClick: (data) => {},
    // },
  ];

  const columns = [
    {
      title: () => {
        return <TitleColumnCenter>{t('performance:pending_review.col_id')}</TitleColumnCenter>;
      },
      key: 'employeeCode',
      dataIndex: 'employeeCode',
      width: 80,
      minWidth: 80,
      align: 'center',
      render: (text, record, index) => {
        const trueIndex = index + sizePage * (currentPage - 1);
        return <div>{trueIndex + 1}</div>;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('performance:pending_review.employee_name')}</TitleColumnCenter>
        );
      },
      key: 'fullName',
      dataIndex: 'fullName',
      width: 100,
      minWidth: 100,
      render: (_, record) => (
        <EmployeeColumn
          avatar={
            record?.fullName ? `${record?.fullName}` : `${record?.lastName} ${record?.firstName}`
          }
          fullName={
            record?.fullName ? `${record?.fullName}` : `${record?.lastName} ${record?.firstName}`
          }
          email={record?.email}
        />
      ),
    },
    {
      title: () => {
        return <TitleColumnCenter>{t('performance:pending_review.employee_id')}</TitleColumnCenter>;
      },
      key: 'employeeId',
      dataIndex: 'employeeId',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text) => text,
    },
    {
      title: () => {
        return <TitleColumnCenter>{t('performance:pending_review.job_title')}</TitleColumnCenter>;
      },
      key: 'jobTitle',
      dataIndex: 'jobTitle',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text) => text,
    },
    {
      title: t('performance:pending_review.direct_manager'),
      key: 'managerName',
      dataIndex: 'managerName',
      width: 100,
      minWidth: 100,
      align: 'center',
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('performance:pending_review.current_level')}</TitleColumnCenter>
        );
      },
      key: 'currentLevel',
      dataIndex: 'currentLevel',
      width: 100,
      minWidth: 100,
      align: 'center',
    },
    {
      title: () => {
        return <TitleColumnCenter>{t('performance:pending_review.action')}</TitleColumnCenter>;
      },
      key: 'action',
      dataIndex: 'action',
      width: 80,
      minWidth: 80,
      align: 'center',
      render: (_, record) => <MenuAction menu={menu} data={record} />,
    },
  ];

  const handleSearch = (text: string) => {
    setSearchText(text);
  };

  return (
    <>
      <PageHeader title={titleHeader} />
      <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        <Select
          style={{ width: 300 }}
          placeholder={t('performance:pending_review.select_pending_cycle_review_placeholver')}
          options={itemCycle}
          onSelect={(value) => setCycleId(value)}
        />
        <ITVSearch style={{ marginBottom: 16 }} handleSearch={handleSearch} />
      </div>
      <ITVTable
        data={pendingReviewPerformance?.result || []}
        columns={columns}
        isRowSelect
        setOffset={setCurrentPage}
        offset={currentPage}
        setLimit={setSizePage}
        limit={sizePage}
        totalResult={pendingReviewPerformance?.totalResult || undefined}
      />
    </>
  );
}
