import { PlusOutlined } from '@ant-design/icons';
import CustomButton from 'styles/buttonStyled';
import NoJob from 'assets/images/noJobFound.png';
import { Image, Modal, Table } from 'antd';
import AddToJob from './AddToJob';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'app/hooks';
import { getListJob } from 'features/recruitment/recruitmentAction';
import ITVTable from 'components/ITVTable';
import moment from 'moment';
import { YYYY_MM_DD } from 'constants/commons';
import { getUser } from 'utils/auth';
import { useTranslation } from 'react-i18next';

enum JobStatus {
  NEW = 'New',
  WAITING_FOR_APPROVAL = 'Waiting for approval',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  CANCELED = 'Canceled',
}

const AppliedJobs = ({ handleChange, data }) => {
  const { t } = useTranslation(['recruitment', 'action']);
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);
  const [appliedJobs, setAppliedJobs] = useState([]);
  const isCandidateInterview = getUser()?.permissions?.includes('RECRUIT_CANDIDATE_INTERVIEW');

  useEffect(() => {
    dispatch(getListJob({ status: JobStatus.APPROVED }));
  }, [dispatch]);

  useEffect(() => {
    if (data && data.jobs) {
      setAppliedJobs(data.jobs);
    }
  }, [data]);

  const handleAddJob = (job) => {
    setAppliedJobs((prev) => {
      const updatedJobs = [...prev];
      const existingIndex = updatedJobs.findIndex((j) => j.id === job.id);
      if (existingIndex !== -1) {
        updatedJobs[existingIndex] = job;
      } else {
        updatedJobs.push(job);
      }
      handleChange && handleChange({ jobs: updatedJobs });
      return updatedJobs;
    });
  };

  const handleRemoveJob = (job) => {
    setAppliedJobs((prev) => {
      const updatedJobs = prev.filter((j) => j.id !== job.id);
      handleChange && handleChange({ jobs: updatedJobs });
      return updatedJobs;
    });
  };

  const columns = [
    {
      title: t('recruitment:position_name'),
      dataIndex: 'positionName',
      key: 'positionName',
      render: (value, record) => record?.jobTemplate?.positionName,
    },
    {
      title: t('recruitment:job_location'),
      dataIndex: 'jobLocation',
      key: 'jobLocation',
      render: (value, record) => value || record?.jobTemplate?.location?.name,
    },
    {
      title: t('recruitment:job_department'),
      dataIndex: 'jobDepartment',
      key: 'jobDepartment',
      render: (value, record) => value || record?.jobTemplate?.department?.name,
    },
    {
      title: t('recruitment:job_stage'),
      dataIndex: 'jobStage',
      key: 'jobStage',
      render: (value, record) => value || record?.hiringProcess?.[0]?.name,
    },
    {
      title: t('recruitment:applied_date'),
      dataIndex: 'appliedDate',
      key: 'appliedDate',
      render: (value, record) => (value ? moment(value).format(YYYY_MM_DD) : ''),
    },
  ];

  return (
    <div>
      {isCandidateInterview && (
        <>
          {appliedJobs.length === 0 ? (
            <div style={{ display: 'flex', flexDirection: 'column', background: 'white' }}>
              <div style={{ display: 'flex', justifyContent: 'center', marginLeft: 35 }}>
                <Image src={NoJob} preview={false} width={465} />
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {t('recruitment:applied_job_long_text')}
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', margin: 20 }}>
                <CustomButton onClick={() => setVisible(true)} icon={<PlusOutlined />}>
                  {t('recruitment:add_to_job')}
                </CustomButton>
              </div>
            </div>
          ) : (
            <>
              <CustomButton onClick={() => setVisible(true)} icon={<PlusOutlined />}>
                {t('recruitment:add_to_job')}
              </CustomButton>
              <ITVTable columns={columns} data={appliedJobs} isRowSelect={false} />
            </>
          )}
        </>
      )}
      <Modal
        open={visible}
        onCancel={() => {
          setVisible(false);
        }}
        width={1000}
        footer={null}
        destroyOnClose
      >
        <AddToJob onAddJob={handleAddJob} onRemoveJob={handleRemoveJob} addedJobs={appliedJobs} />
      </Modal>
    </div>
  );
};

export default AppliedJobs;
