import queryString from 'query-string';
import {
  ApproveRequestInsurancePayload,
  SuccessResponse,
  ConfirmInsurancePayload,
  ApproveRequestInsuranceConfirmPayload,
} from 'types';
import axiosClient from './axiosClient';

const insurancesApi = {
  getTypeInsurance: async () => {
    const response = await axiosClient.get(`/insurance/type-insurance`);
    return response;
  },
  getCheckIDUser: async (params: any) => {
    const response = await axiosClient.get(`/employee/employee-code/${params.userId}`);
    return response;
  },
  getTypeIncrease: async () => {
    const response = await axiosClient.get(`/insurance/type-increase`);
    return response;
  },
  createIncreaseDecrease: async (params: any) => {
    const response = await axiosClient.post(`insurance/schedule-premium`, params);
    return response;
  },
  getSchedulePremium: async (params: any) => {
    const response = await axiosClient.get(
      `/insurance/schedule-premium?${queryString.stringify(params)}`,
    );
    return response;
  },
  getRecordPayment: async (params: any) => {
    const response = await axiosClient.get(
      `/insurance/record-payment?${queryString.stringify(params)}`,
    );
    return response;
  },
  getRecordPaymentYear: async (params: any) => {
    const response = await axiosClient.get(
      `/insurance/list-record-payment?${queryString.stringify(params)}`,
    );
    return response;
  },
  getRecordPay: async (params: any) => {
    const response = await axiosClient.get(
      `/insurance/list-record-payment?${queryString.stringify(params)}`,
    );
    return response;
  },
  deleteSchedulePremium: async (params: any) => {
    const response = await axiosClient.delete(`/insurance/schedule-premium`, {
      data: {
        listId: params.listId,
      },
    });
    return response;
  },
  resetSchedulePremium: async (params: any) => {
    const response = await axiosClient.post(`/insurance/reset-schedule-premium`, params);
    return response;
  },
  postApproved: async (params: any) => {
    const response = await axiosClient.post(`/insurance/update-list-request`, params);
    return response;
  },
  postEditSchedule: async (params: any) => {
    const response = await axiosClient.post(
      `/insurance/register-schedule-premium/${params.id}`,
      params,
    );
    return response;
  },
  getPaymentHistory: async (params: any) => {
    const response = await axiosClient.get(
      `/insurance/record-payment-history?${queryString.stringify(params)}`,
    );
    return response;
  },
  getExportParticipant: async ({ str, ...body }: any) => {
    const response: any = await axiosClient.post(`/insurance/export-participant`, body, {
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', str);
    document.body.appendChild(link);
    link.click();
    return response;
  },
  getExportRecordPayment: async ({ str, ...body }: any) => {
    const response: any = await axiosClient.post(`insurance/export-record-payment`, body, {
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', str);
    document.body.appendChild(link);
    link.click();
    return response;
  },
  getExportPaymentByYear: async ({ str, ...body }: any) => {
    const response: any = await axiosClient.post(`/insurance/export-record-payment-by-year`, body, {
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', str);
    document.body.appendChild(link);
    link.click();
    return response;
  },
  getPaymentHistoryRecord: async (params: any) => {
    const response = await axiosClient.get(`insurance/payment-history/${params.id}`);
    return response;
  },
  getTotalSchedule: async () => {
    const response = await axiosClient.get(`insurance/total-employee`);
    return response;
  },
  getRejectedHistory: async (params: any) => {
    const response = await axiosClient.get(
      `/insurance/rejected-history?${queryString.stringify(params)}`,
    );
    return response;
  },
  getEmployeeSearch: async (params: any) => {
    const response = await axiosClient.post(
      `/employee/search?offset=1&limit=10&status=${params.status}`,
      params,
    );
    return response;
  },
  getAutoIncreaseDecrease: async (params: any) => {
    const response = await axiosClient.get(
      `insurance/schedule-premium-increase?${queryString.stringify(params)}`,
    );
    return response;
  },
  getCreateAuto: async (params: any) => {
    const response = await axiosClient.post(`insurance/schedule-premium-increase`, params);
    return response;
  },
  postExportPdf: async (params: any) => {
    const { str, ...body } = params;
    const response: any = await axiosClient.post(`insurance/export-pdf`, body, {
      responseType: 'blob',
    });
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', str);
    document.body.appendChild(link);
    link.click();
    return response;
  },
  approveRequestInsuranceByEmail(
    payload: ApproveRequestInsurancePayload,
  ): Promise<SuccessResponse<{ message: string; statusCode: number }>> {
    return axiosClient.post('/insurance/update-list-request-by-token', payload);
  },
  confirmRecordPayment(
    payload: ConfirmInsurancePayload,
  ): Promise<SuccessResponse<{ message: string; statusCode: number }>> {
    return axiosClient.post('/confirm/insurance', payload);
  },
  approveRequestInsuranceConfirmRecordPayByEmail(
    payload: ApproveRequestInsuranceConfirmPayload,
  ): Promise<SuccessResponse<{ message: string; statusCode: number }>> {
    return axiosClient.post('/confirm/insurance/approve-by-token', payload);
  },
  getPtiParticipant: async (params: any) => {
    const response = await axiosClient.get(`/pti-insurance?${queryString.stringify(params)}`);
    return response;
  },
  createPTIPaticipant: async (payload: any) => {
    const response = await axiosClient.post(`/pti-insurance`, payload);
    return response;
  },
  updatePTIPaticipant: async (payload: any) => {
    const { id, body } = payload;
    const response = await axiosClient.put(`/pti-insurance/${id}`, body);
    return response;
  },
  exportPti: async (payload: any) => {
    const { body } = payload;
    const response = await axiosClient.post(`/pti-insurance/export-pti`, body);
    return response;
  },
  sendMailFile: async (payload: any) => {
    const { body } = payload;
    const response = await axiosClient.post(`/pti-insurance/send-mail`, body);
    return response;
  },
};

export default insurancesApi;
