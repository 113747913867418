import { IEmployee, LineCategoryResponse, ListParams, ListResponse, SuccessResponse } from 'types';
import axiosClient from './axiosClient';
import queryString from 'query-string';

const employeeApi = {
  getEmployeeList: (params: any) => {
    const response = axiosClient.get(`/employee?${queryString.stringify(params)}`);
    return response;
  },
  getManager: (employeeId: string) => {
    const response = axiosClient.get(`/employee/manager/${employeeId}`);

    return response;
  },
  getEmployeeByPermission: (params: any) => {
    const response = axiosClient.get(
      `/employee/user-by-permission?${queryString.stringify(params)}`,
    );
    return response;
  },
  getEmployeeListApproved: () => {
    const response = axiosClient.get(`/employee?status=approved`);
    return response;
  },
  getEmployeeListNotifyLeaveOff: async () => {
    const response = await axiosClient.get(`/employee/list-employee-notify-leave-off`);
    return response;
  },
  getEmployeeDetail: (idItem: any) => {
    const response = axiosClient.get(`/employee/detail/${idItem}`);
    return response;
  },
  getEmployeeRecordHistory: (idItem: any) => {
    const response = axiosClient.get(`/employee-history/employee-edited/${idItem}`);
    return response;
  },
  getEmployeeHaveManager: () => {
    const response = axiosClient.get(`/employee/list-employee-have-manager`);
    return response;
  },
  getEmployeeCanMakeManager: (employeeId: string) => {
    const response = axiosClient.get(`/employee/list-manager/${employeeId}`);
    return response;
  },
  createEmployee: (dataRequest: any) => {
    const response = axiosClient.post(`/employee`, dataRequest);
    return response;
  },
  updateEmployee: (idItem: any, dataRequest: any) => {
    const response = axiosClient.put(`/employee/${idItem}`, dataRequest);
    return response;
  },
  updateEmployeeId: (idItem: any, dataRequest: any) => {
    const response = axiosClient.put(`/employee/update-employee-id/${idItem}`, dataRequest);
    return response;
  },
  deleteEmployee: (idItem: any) => {
    const response = axiosClient.put(`/employee/delete/${idItem}`);
    return response;
  },
  requestApproveEmployee: (dataRequest: any) => {
    const response = axiosClient.post(`/employee/request-approve-employee`, dataRequest);
    return response;
  },
  approveEmployee: (dataRequest: any) => {
    const response = axiosClient.post(`/employee/approve-employee`, dataRequest);
    return response;
  },
  activeDeactiveEmployee: (dataRequest: any) => {
    const response = axiosClient.post(`/employee/make-active-employee`, dataRequest);
    return response;
  },
  searchEmployee: ({ ...params }: any) => {
    const response = axiosClient.post(`/employee/search`, params);
    return response;
  },
  importEmployee: () => {
    const response = axiosClient.post(`/employee/import-employee`);
    return response;
  },
  filterSortEmployeeList: (param: any) => {
    const response = axiosClient.post(`/employee/search-employee`, param);
    return response;
  },
  getLineCategory(params?: Partial<ListParams>): Promise<SuccessResponse<LineCategoryResponse>> {
    return axiosClient.get('/project', {
      params: params,
    });
  },
  getEmployeeListV2: (
    payload: Partial<ListParams>,
  ): Promise<SuccessResponse<ListResponse<IEmployee>>> => {
    return axiosClient.get(`/employee/list`, { params: payload });
  },
  getManagerList: () => {
    const response = axiosClient.get(`/employee/list-user-manager`);
    return response;
  },

  alertDeactiveEmployee: (params: { userId: string }) => {
    const response = axiosClient.get(`/employee/alert-deactivate?${queryString.stringify(params)}`);
    return response;
  },
};

export default employeeApi;
