import { setGlobalLoading } from 'app/commonRedux/appSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import configurationApi from 'api/configurationApi';
import payrollDashboardApi from 'api/payrollDashboardApi';

export const getCategoryList = createAsyncThunk('configuration/getCategoryList', async () => {
  const configurationsList: any = await configurationApi.getCategoryList();
  return configurationsList;
});

export const getCategoryWithId = createAsyncThunk(
  'configuration/getCategoryWithId',
  async ({ idConfig }: any) => {
    const configurationsDetail: any = await configurationApi.getCategoryWithId(idConfig);
    return configurationsDetail;
  },
);

export const getCategoryWithType = createAsyncThunk(
  'configuration/getCategoryWithType',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await configurationApi.getCategoryWithType(params.typeConfig);
      callBack && callBack(response);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getLocations = createAsyncThunk(
  'locations',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await payrollDashboardApi.getLocation();
      callBack && callBack(response);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
