import { Input, Select, Switch, Tabs } from 'antd';
import ButtonBack from 'components/ButtonBack';
import ITVTable from 'components/ITVTable';
import { searchEmployee } from 'features/employee/employeeAction';
import { getEquipmentsByEmployee } from 'features/equipment/equipmentSlice';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import PageHeader from 'components/PageHeader';
import { useAppSelector } from 'app/hooks';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { Container } from '../styled';
import styled from 'styled-components';
import TextArea from 'antd/lib/input/TextArea';
import { useTranslation } from 'react-i18next';

const { TabPane } = Tabs;

const ContentCell = (props: any) => {
  const { t } = useTranslation(['performance']);
  const {
    rating,
    content,
    setValue,
    nameRating,
    nameContent,
    disabled = false,
    editParent,
  } = props;

  return (
    <div>
      <div>{t('performance:rating_point')}</div>
      <Input
        type="number"
        disabled={disabled}
        defaultValue={rating}
        onChange={(e) => {
          setValue(nameRating, e.target.value);
          setValue(editParent, true);
        }}
      />
      <div>{t('performance:accomplishment')}</div>
      <TextArea
        disabled={disabled}
        defaultValue={content}
        onChange={(e) => {
          setValue(nameContent, e.target.value);
          setValue(editParent, true);
        }}
      />
    </div>
  );
};

export default ContentCell;
