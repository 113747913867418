// src/FormTableComponent.jsx

import React, { useEffect, useState } from 'react';
import { Table, Input, Button, Row, Col } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { DatePickerField, InputField } from 'components/FormFields';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'app/hooks';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { useDispatch } from 'react-redux';
import PageHeader from 'components/PageHeader';
import { DATE_FORMAT, YYYY_MM_DD } from 'constants/commons';
import moment from 'moment';
import { getCompanyInfo, saveCompanyInfo, selectApp } from 'app/commonRedux/appSlice';

const BusinessRegistrationForm = () => {
  const dispatch = useDispatch();
  const location: any = useLocation();
  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = menuStore?.dataScreenManage?.result;
  const companyInfo = useAppSelector(selectApp).companyInfo;
  const [titleHeader, setTitleHeader] = useState('');
  console.log(companyInfo, 'companyInfo');

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item: any) => location.pathname === item.path)
      ?.map((item: any) => item.nameScreen);

    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  useEffect(() => {
    dispatch(getCompanyInfo({}));
  }, []);

  // Initialize the form with react-hook-form
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: undefined,
    defaultValues: {
      companyName: '',
      taxCode: '',
      dateOfEstablishment: '',
      businessRegistrationNumber: '',
      dateOfIssue: '',
      placeOfIssue: '',
      legalRepresentative: '',
      legalRepresentativeJobTitile: '',
      address: '',
      district: '',
      city: '',
      phone: '',
      fax: '',
      email: '',
      website: '',
    },
  });

  function setValueDefault(object: any, key: any, value: any) {
    if (object.hasOwnProperty(key)) {
      object[key] = value;
    } else {
      console.log('Key not found in object.');
    }
  }

  function setCompanyInfo(companyInfo: any) {
    const keys = Object.keys(companyInfo);
    for (const key of keys) {
      if (
        (key === 'dateOfIssue' && companyInfo[key]) ||
        (key === 'dateOfEstablishment' && companyInfo[key])
      ) {
        setValue(key, moment(companyInfo[key]));
      } else setValue(key, companyInfo[key]);
    }
  }

  useEffect(() => {
    if (companyInfo) {
      setCompanyInfo(companyInfo);
    }
  }, [companyInfo]);

  // Form submit handler
  const onSubmit = (data: any) => {
    const payload = {
      ...data,
      dateOfEstablishment: data?.dateOfEstablishment
        ? moment(data?.dateOfEstablishment).format(YYYY_MM_DD)
        : '',
      dateOfIssue: data?.dateOfIssue ? moment(data?.dateOfIssue).format(YYYY_MM_DD) : '',
    };
    dispatch(saveCompanyInfo({ payload }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ marginRight: 32 }}>
      <PageHeader title={titleHeader} />

      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          Company
        </Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="companyName">Full Name</label>
        </Col>
        <Col span={12}>
          <InputField name="companyName" control={control} />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={6}></Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="taxCode">Tax Code</label>
        </Col>
        <Col span={12}>
          <InputField name="taxCode" control={control} />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={6}></Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="dateOfEstablishment">Date of establishment</label>
        </Col>
        <Col span={12}>
          <DatePickerField
            style={{ width: '100%' }}
            picker="date"
            format={YYYY_MM_DD}
            name="dateOfEstablishment"
            control={control}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 32 }}>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          BUSINESS REGISTRATION
        </Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="businessRegistrationNumber">Business registration number</label>
        </Col>
        <Col span={12}>
          <InputField name="businessRegistrationNumber" control={control} />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={6}></Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="dateOfIssue">Date of issue</label>
        </Col>
        <Col span={12}>
          <DatePickerField
            style={{ width: '100%' }}
            picker="date"
            format={YYYY_MM_DD}
            name="dateOfIssue"
            control={control}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={6}></Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="placeOfIssue">Place of issue</label>
        </Col>
        <Col span={12}>
          <InputField name="placeOfIssue" control={control} />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={6}></Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="legalRepresentative">Legal representative</label>
        </Col>
        <Col span={12}>
          <InputField name="legalRepresentative" control={control} />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={6}></Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="legalRepresentativeJobTitile">Job title</label>
        </Col>
        <Col span={12}>
          <InputField name="legalRepresentativeJobTitile" control={control} />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 32 }}>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          CONTACT
        </Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="address">Address</label>
        </Col>
        <Col span={12}>
          <InputField name="address" control={control} />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={6}></Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="district">District</label>
        </Col>
        <Col span={12}>
          <InputField name="district" control={control} />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={6}></Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="city">City</label>
        </Col>
        <Col span={12}>
          <InputField name="city" control={control} />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={6}></Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="phone">Phone</label>
        </Col>
        <Col span={12}>
          <InputField name="phone" control={control} />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={6}></Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="fax">Fax</label>
        </Col>
        <Col span={12}>
          <InputField name="fax" control={control} />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={6}></Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="email">Email</label>
        </Col>
        <Col span={12}>
          <InputField name="email" control={control} />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={6}></Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="website">Website</label>
        </Col>
        <Col span={12}>
          <InputField name="website" control={control} />
        </Col>
      </Row>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button type="primary" htmlType="submit" style={{ marginTop: 16 }}>
          Submit
        </Button>
      </div>
    </form>
  );
};

export default BusinessRegistrationForm;
