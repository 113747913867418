import { EditOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { useForm } from 'react-hook-form';
import JobDetails from './JobDetails';
import JobRequirements from './JobRequirements';
import { useState } from 'react';
import { JobStatus, isInterviewer } from 'features/recruitment/jobs/components/types';
import { getUser } from 'utils/auth';
import { useTranslation } from 'react-i18next';

const Summary = ({ data, handleChange }) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: undefined,
  });
  const { t } = useTranslation([
    'validation',
    'masterConfig',
    'setup_menu_screen',
    'insurance',
    'common',
    'overtime',
    'employee',
    'menu',
    'recruitment',
    'action',
  ]);
  const [visibleDetail, setVisibleDetail] = useState(false);
  const [visibleRequirement, setVisibleRequirement] = useState(false);

  const contents = [
    {
      key: 'job',
      title: t('recruitment:job_detail'),
      component: (
        <JobDetails
          data={data}
          handleChange={handleChange}
          visible={visibleDetail}
          setVisible={setVisibleDetail}
        />
      ),
    },
    {
      key: 'requirement',
      title: t('recruitment:job_requirement'),
      component: (
        <JobRequirements
          data={data}
          handleChange={handleChange}
          visible={visibleRequirement}
          setVisible={setVisibleRequirement}
        />
      ),
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      {(isInterviewer()
        ? contents.filter((content) => content.key === 'requirement')
        : contents
      ).map(({ component, title, key }) => (
        <Card
          title={title}
          bordered={false}
          headStyle={{
            fontSize: 24,
            fontWeight: 500,
          }}
          extra={
            !(data?.status === JobStatus.APPROVED) && !isInterviewer() ? (
              <a
                onClick={() =>
                  key === 'job'
                    ? setVisibleDetail(!visibleDetail)
                    : setVisibleRequirement(!visibleRequirement)
                }
              >
                {' '}
                <EditOutlined style={{ color: '#1890FF', marginRight: 8 }} />
                {t('action:edit')}
              </a>
            ) : null
          }
        >
          {component}
        </Card>
      ))}
    </div>
  );
};

export default Summary;
