import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import axiosClient from 'api/axiosClient';
import moment, { Moment } from 'moment';
import { getUser } from 'utils/auth';
import { setGlobalLoading } from 'app/commonRedux/appSlice';
import { ILeaveTypeOff, IUserRecord } from './interfaces/leaveManageInterfaces';
import { addUserSameLeaveOffType } from './leaveOffSlice';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import { paramSortFilter } from 'types';

export const editRecord = createAsyncThunk(
  'timesheet/update-leave-type-employee',
  async (params: { id: number; total_leave_off: number; user: any }, thunkApi) => {
    try {
      const { id, total_leave_off, user } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const res = await axiosClient.post('/timesheet/update-leave-type-employee', {
        id: id,
        availableDays: total_leave_off,
      });
      thunkApi.dispatch(
        getListSlideLeaveoff({
          user,
        }),
      );
      res &&
        notificationToast(
          Notification.Type.Success,
          'Update successfully',
          Notification.Duration._5s,
        );
      return res;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const deleteRecordLeave = createAsyncThunk(
  'delete-request-leave-type-employee',
  async (params: { id: number | string; user: any }, thunkApi) => {
    try {
      const { id, user } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const res = await axiosClient.delete(`timesheet/delete-request-leave-type-employee/${id}`);

      thunkApi.dispatch(
        getListSlideLeaveoff({
          user,
        }),
      );
      return res;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const assignStaffManager = createAsyncThunk(
  'assignStaffManager',
  async (
    params: {
      startDate?: string | undefined;
      endDate?: string | undefined;
      userIds: any;
      typeId: string | number;
      monthYear: Moment;
    },
    thunkApi,
  ) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { startDate, endDate, userIds, typeId, monthYear } = params;
      const res = await axiosClient.post('timesheet/auto-assign-leave-off-employee', {
        typeId,
        userIds,
        monthYear: monthYear.format('YYYY-MM'),
      });

      if (startDate && endDate) {
        thunkApi.dispatch(
          getListManage({
            startDate: monthYear.startOf('month').format('YYYY-MM-DD'),
            endDate: monthYear.endOf('month').format('YYYY-MM-DD'),
            type: typeId,
          }),
        );
      }
      res &&
        notificationToast(
          Notification.Type.Success,
          'Assign successfully',
          Notification.Duration._5s,
        );
    } catch (error) {
      thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const assignManualStaffManager = createAsyncThunk(
  'assignManualStaffManager',
  async (
    params: {
      startDate?: string | undefined;
      endDate?: string | undefined;
      users: { userId: string; dateAddLeaveDays: Moment }[];
      typeId: string | number;
      monthYear: Moment;
    },
    thunkApi,
  ) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { startDate, endDate, users, typeId, monthYear } = params;
      const res = await axiosClient.post('timesheet/assign-leave-off-employee-date', {
        typeId,
        users,
        monthYear: monthYear.format('YYYY-MM'),
      });

      if (startDate && endDate) {
        thunkApi.dispatch(
          getListManage({
            startDate: monthYear.startOf('month').format('YYYY-MM-DD'),
            endDate: monthYear.endOf('month').format('YYYY-MM-DD'),
            type: typeId,
          }),
        );
      }
      res &&
        notificationToast(
          Notification.Type.Success,
          'Assign successfully',
          Notification.Duration._5s,
        );
    } catch (error) {
      thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const assignStaffManagerPopup = createAsyncThunk(
  'assignStaffManager',
  async (
    params: {
      arrStaff: any;
      type: string | number;
      userEdited?: IUserRecord;
    },
    thunkApi,
  ) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { arrStaff, type, userEdited } = params;
      const res = await axiosClient.post('timesheet/assign-leave-off-employee', {
        leaveTypeId: type,
        employeeIds: arrStaff,
      });
      thunkApi.dispatch(
        getListSlideLeaveoff({
          user: arrStaff[0],
          userEdited,
          leaveTypeId: type as number,
        }),
      );
      res &&
        notificationToast(
          Notification.Type.Success,
          'Assign successfully',
          Notification.Duration._5s,
        );
    } catch (error) {
      thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const exportLeaveOffTypeByRequest = createAsyncThunk(
  'timesheet/exportLeaveOffTypeByRequest',
  async (
    params: {
      startDate: string;
      endDate: string;
      employeeIds: any;
    },
    thunkApi,
  ) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { startDate, endDate, employeeIds } = params;

      const res: any = await axiosClient.post(
        'timesheet/export-request-leave',
        {
          employeeIds,
          fromDate: startDate,
          toDate: endDate,
        },
        {
          responseType: 'blob',
        },
      );
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Request_Leave_Off_${params.startDate}_${params.endDate}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const exportLeaveOffTypeByEmployee = createAsyncThunk(
  'timesheet/exportLeaveOffTypeByEmployee',
  async (
    params: {
      employeeIds: any;
      leaveTypeId: any;
      monthYear: Moment;
    },
    thunkApi,
  ) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { monthYear, employeeIds, leaveTypeId } = params;

      const res: any = await axiosClient.post(
        'timesheet/export-leave-off-type-by-employee',
        {
          employeeIds,
          leaveTypeId,
          monthYear: monthYear.format('YYYY-MM'),
        },
        {
          responseType: 'blob',
        },
      );
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Leave_Off_By_Employee_${monthYear.startOf('month').format('DD/MM/YYYY')}_${monthYear
          .endOf('month')
          .format('DD/MM/YYYY')}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const exportByTime = createAsyncThunk(
  'timesheet/exportByTime',
  async (params: { endDate: any; startDate: any }, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const res: any = await axiosClient.get(
        `/timesheet/export-request-leave?fromDate=${params.startDate}&toDate=${params.endDate}`,
        { responseType: 'blob' },
      );
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Leave_Off_By_Time_${params.startDate}_${params.endDate}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const exportOverView = createAsyncThunk(
  'timesheet/exportOverView',
  async (params: { endDate: any; startDate: any }, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const res: any = await axiosClient.get(
        `/timesheet/export-leave-off-overview?fromDate=${params.startDate}&toDate=${params.endDate}`,
        { responseType: 'blob' },
      );
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Leave_Off_Overview_${params.startDate}_${params.endDate}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getListSlideLeaveoff = createAsyncThunk(
  'timesheet/leave-type-off-employee-detail',
  async (
    params: { user: any; year?: string; userEdited?: IUserRecord; leaveTypeId?: number },
    thunkApi,
  ) => {
    try {
      const { user, year, userEdited, leaveTypeId } = params;

      if (getUser()?.id) {
        const res = await axiosClient.get(
          `timesheet/leave-type-off-employee-detail/${user ? user : getUser()?.id}?${
            year ? 'year=' + year : ''
          }`,
        );

        userEdited &&
          leaveTypeId &&
          thunkApi.dispatch(
            addUserSameLeaveOffType({
              user: userEdited,
              leaveType:
                res.data.find(
                  (leaveType: ILeaveTypeOff) => leaveType.configTypeId === leaveTypeId,
                ) || null,
            }),
          );
        return res;
      }
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  },
);
export const getMultiGroupLeaveoff = createAsyncThunk(
  'timesheet/multi-group-leave-type-off-employee-detail',
  async (params: { user: any; monthYear?: string }, thunkApi) => {
    try {
      const { user, monthYear } = params;

      if (getUser()?.id) {
        const res = await axiosClient.get(
          `timesheet/multi-group-leave-type-off-employee-detail/${
            user ? `?userId=${user}` : `?userId=${getUser()?.id}`
          }&${monthYear ? 'monthYear=' + monthYear : ''}`,
        );
        return res;
      }
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  },
);
export const getHistoryLeaveOff = createAsyncThunk(
  'timesheet/request-leave-off-employee-history',
  async (params: { year: any; month: any }, thunkApi) => {
    const { year, month } = params;
    try {
      if (getUser()?.id) {
        const response = axiosClient.get(
          `timesheet/request-leave-off-employee-history/${
            getUser()?.id
          }?fromDate=${year}-${month}-01&toDate=${moment(new Date(year + '-' + month))
            .endOf('months')
            .format('YYYY-MM-DD')}`,
        );
        return response;
      }
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  },
);

export const getTotalLeaveOffPaid = createAsyncThunk(
  'timesheet/getTotalLeaveOffPaid',
  async (params: { year: any; month: any }, thunkApi: any) => {
    try {
      if (getUser()?.id) {
        const { year, month } = params;
        const response = month
          ? axiosClient.get(
              `timesheet/total-leave-off-by-paid/${
                getUser()?.id
              }?fromDate=${year}-${month}-01&toDate=${moment(new Date(year + '-' + month))
                .endOf('months')
                .format('YYYY-MM-DD')}`,
            )
          : axiosClient.get(
              `/timesheet/total-leave-off-by-paid/${
                getUser()?.id
              }?fromDate=${year}-01-01&toDate=${year}-12-31`,
            );
        return response;
      }
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  },
);

export const getTotalYearLeaveOffPaid = createAsyncThunk(
  'timesheet/total-leave-off-by-paid',
  async (params: { year: any; month: any }, thunkApi: any) => {
    try {
      if (getUser()?.id) {
        const { year, month } = params;
        const response = month
          ? axiosClient.get(
              `timesheet/total-leave-off-by-paid/${
                getUser()?.id
              }?fromDate=${year}-${month}-01&toDate=${moment(new Date(year + '-' + month))
                .endOf('months')
                .format('YYYY-MM-DD')}`,
            )
          : axiosClient.get(
              `/timesheet/total-leave-off-by-paid/${
                getUser()?.id
              }?fromDate=${year}-01-01&toDate=${year}-12-31`,
            );
        return response;
      }
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  },
);

export const cancelRequestLeave = createAsyncThunk(
  '/timesheet/cancel-request',
  async (params: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const res = await axiosClient.post('timesheet/cancel-request', params);
      res &&
        notificationToast(
          Notification.Type.Success,
          'Cancel request leave successfully',
          Notification.Duration._5s,
        );
      params.callback();
      return res;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

// ================ FOR MANAGER ===================

export const getListManage = createAsyncThunk(
  'timesheet/list-mamager-leave-off-employee',
  async (
    params: {
      startDate: string;
      endDate: string;
      type: number | string;
      groupId?: number;
      limit?: number;
      offset?: number;
      keyword?: any;
    },
    thunkApi,
  ) => {
    try {
      const { startDate, endDate, type, groupId, keyword, offset, limit } = params;
      if (groupId)
        return await axiosClient.get(
          `timesheet/list-mamager-leave-off-employee?status=true&leaveTypeId=${type}&fromDate=${startDate}&toDate=${endDate}&configTypeId=${groupId}${
            keyword ? `&keyword=${keyword}` : ''
          }${limit ? `&limit=${limit}` : ''}${offset ? `&offset=${offset}` : ''}`,
        );
      else
        return await axiosClient.get(
          `timesheet/list-mamager-leave-off-employee?status=true&leaveTypeId=${type}&fromDate=${startDate}&toDate=${endDate}${
            keyword ? `&keyword=${keyword}` : ''
          }${limit ? `&limit=${limit}` : ''}${offset ? `&offset=${offset}` : ''}`,
        );
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  },
);

export const getListManageExclude = createAsyncThunk(
  'timesheet/getListManageExclude',
  async (params: any, thunkApi) => {
    try {
      const response = await axiosClient.post(
        `timesheet/list-non-assign-leave-off-employee`,
        params,
      );
      notification.destroy();
      return response;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  },
);

export const getHistoryManager = createAsyncThunk(
  'getHistoryManager',
  async (
    params: {
      startDate: string;
      endDate: string;
      status: string;
      leaveType: string;
      viewBy: string;
      objectId?: string;
      paramSortFilter: paramSortFilter;
    },
    thunkApi,
  ) => {
    try {
      const { startDate, endDate, status, leaveType, viewBy, objectId, paramSortFilter } = params;
      const reponse = await axiosClient.post(`timesheet/list-all-history-leave-off`, {
        fromDate: startDate,
        toDate: endDate,
        filter: {
          status: [status],
          leaveType: [leaveType],
        },
        sort: paramSortFilter.sort,
        limit: paramSortFilter.limit,
        offset: paramSortFilter.offset,
        search: paramSortFilter.search,
        viewBy: viewBy,
        objectId: objectId,
      });
      notification.destroy();
      return reponse;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  },
);

export const getLeaveTypeList = createAsyncThunk(
  'timesheet/list-leave-type-config-active',
  async (params: { monthYear: string }, thunkApi) => {
    try {
      const { monthYear } = params;
      const res = await axiosClient.get(
        `timesheet/list-leave-type-config-active?monthYear=${monthYear}`,
      );
      return res;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  },
);

export const assignStaff = createAsyncThunk(
  'timesheet/assign-leave-off-employee',
  async (params: { arrStaff: any; type: string | number; typeMessage: string }, thunkApi) => {
    try {
      const { arrStaff, type, typeMessage } = params;
      const res = await axiosClient.post('timesheet/assign-leave-off-employee', {
        leaveTypeId: type,
        employeeIds: arrStaff,
      });
      res && typeMessage
        ? notificationToast(
            Notification.Type.Success,
            'Add leave successfully',
            Notification.Duration._5s,
          )
        : notificationToast(
            Notification.Type.Success,
            'Assign successfully',
            Notification.Duration._5s,
          );
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  },
);

export const autoAssignStaff = createAsyncThunk(
  'configurations/config-type/auto_assign',
  async (_, thunkApi) => {
    try {
      const res = await axiosClient.put('configurations/config-type/auto_assign');
      res &&
        notificationToast(
          Notification.Type.Success,
          'Assign successfully',
          Notification.Duration._5s,
        );
      return res;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  },
);
