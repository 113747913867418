import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Row, Col, Tag } from 'antd';
import Select from 'react-select';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'app/hooks';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { selectRole } from 'features/role/roleSlice';
import { selectEmployee } from 'features/employee/employeeSlice';
import { selectPermissions } from 'features/auth/authSlice';
import { selectFormatDate } from 'app/commonRedux/appSlice';

import { PROFILE_STATUS_COLORS, PROFILE_STATUSES } from 'constants/profiles';
import { REGEX_NUMBER } from 'constants/regex';
import { PERMISSION_PERSONNEL_RECORD_APPROVAL } from 'constants/permissions';
import { DATE_FORMAT } from 'constants/commons';

import CustomFormItem from 'styles/formStyled';
import CustomInput from 'styles/inputStyled';
import CustomDatePicker from 'styles/datePickerStyled';

const EmployeeInformationFields = (props: any, ref: any) => {
  const {
    isShowComponents,
    isRequriedCheckSave,
    isRequriedCheckSendRequest,
    isRequriedCheckApprove,
    isInvalidData,
    formData,
    status,
  } = props;
  const { t } = useTranslation(['validation', 'employee']);

  useEffect(() => {
    if (isRequriedCheckSendRequest || isRequriedCheckApprove) {
      formData.validateFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequriedCheckSendRequest, isRequriedCheckApprove]);

  const dataFormatDate = useAppSelector(selectFormatDate) || DATE_FORMAT;
  const permissions = useAppSelector(selectPermissions);
  const dataConfiguration = useAppSelector(selectConfiguration);
  const dataRole = useAppSelector(selectRole);
  const dataEmployee = useAppSelector(selectEmployee);

  const [roleOptions, setRoleOptions] = useState<any[]>([]);
  const [managerOptions, setManagerOptions] = useState([]);
  const defaultLocation = formData?.getFieldValue('location');

  useEffect(() => {
    if (dataEmployee && dataEmployee?.employeeListApproved) {
      const tmpManager = dataEmployee?.employeeListApproved.map((item: any) => {
        return {
          value: item.employeeId,
          label: `${item.lastName} ${item.firstName}`,
        };
      });
      tmpManager.push({
        value: null,
        label: t('employee:employee_information_fields.direct_manager_placeholder'),
      });
      setManagerOptions(tmpManager);
    }
    if (dataRole) {
      setRoleOptions(
        dataRole?.data.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
          };
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEmployee, dataRole]);

  const disabledStartDate = (_Current: any) => {
    if (permissions?.indexOf(PERMISSION_PERSONNEL_RECORD_APPROVAL) >= 0) {
      return false;
    }
    return _Current < moment().endOf('day');
  };

  const disabledContractStartDate = (_Current: any) => {
    if (formData.getFieldValue('startDate')) {
      return (
        _Current &&
        _Current < moment(formData.getFieldValue('startDate')).add(-1, 'days').endOf('day')
      );
    } else {
      return true;
    }
  };

  const disabledContractExpirationDate = (_Current: any) => {
    if (formData.getFieldValue('contractStartDate')) {
      return (
        _Current && _Current < moment(formData.getFieldValue('contractStartDate')).endOf('day')
      );
    } else {
      return true;
    }
  };

  const onChangeStartDate = () => {
    formData.setFieldsValue({
      ...formData.getFieldsValue(),
      contractStartDate: null,
      contractExpirationDate: null,
      endDate: null,
    });
  };

  // Custom CSS Components Select When Error
  const [customStyleInvalidOnBoardStatus, setCustomStyleInvalidOnBoardStatus] = useState({});
  const [customStyleInvalidPosition, setCustomStyleInvalidPosition] = useState({});
  const [customStyleInvalidRole, setCustomStyleInvalidRole] = useState({});
  const [customStyleInvalidLevel, setCustomStyleInvalidLevel] = useState({});
  const [customStyleInvalidManager, setCustomStyleInvalidManager] = useState({});
  const [customStyleInvalidContractType, setCustomStyleInvalidContractType] = useState({});
  const [customStyleInvalidLocation, setCustomStyleInvalidLocation] = useState({});

  useImperativeHandle(ref, () => ({
    resetStyleSelect: () => {
      setCustomStyleInvalidOnBoardStatus({
        control: (base: any, state: any) => ({
          ...base,
          borderColor: '#d9d9d9',
        }),
      });
      setCustomStyleInvalidPosition({
        control: (base: any, state: any) => ({
          ...base,
          borderColor: '#d9d9d9',
        }),
      });
      setCustomStyleInvalidRole({
        control: (base: any, state: any) => ({
          ...base,
          borderColor: '#d9d9d9',
        }),
      });
      setCustomStyleInvalidLevel({
        control: (base: any, state: any) => ({
          ...base,
          borderColor: '#d9d9d9',
        }),
      });
      setCustomStyleInvalidManager({
        control: (base: any, state: any) => ({
          ...base,
          borderColor: '#d9d9d9',
        }),
      });
      setCustomStyleInvalidContractType({
        control: (base: any, state: any) => ({
          ...base,
          borderColor: '#d9d9d9',
        }),
      });
    },
  }));

  const onUpdateStyleSelect = () => {
    if (formData.getFieldError('onBoardStatus')?.length > 0) {
      setCustomStyleInvalidOnBoardStatus({
        control: (base: any, state: any) => ({
          ...base,
          borderColor: '#ff4d4f',
        }),
      });
    } else {
      setCustomStyleInvalidOnBoardStatus({
        control: (base: any, state: any) => ({
          ...base,
          borderColor: '#d9d9d9',
        }),
      });
    }
    if (formData.getFieldError('positions')?.length > 0) {
      setCustomStyleInvalidPosition({
        control: (base: any, state: any) => ({
          ...base,
          borderColor: '#ff4d4f',
        }),
      });
    } else {
      setCustomStyleInvalidPosition({
        control: (base: any, state: any) => ({
          ...base,
          borderColor: '#d9d9d9',
        }),
      });
    }
    if (formData.getFieldError('roles')?.length > 0) {
      setCustomStyleInvalidRole({
        control: (base: any, state: any) => ({
          ...base,
          borderColor: '#ff4d4f',
        }),
      });
    } else {
      setCustomStyleInvalidRole({
        control: (base: any, state: any) => ({
          ...base,
          borderColor: '#d9d9d9',
        }),
      });
    }
    if (formData.getFieldError('level')?.length > 0) {
      setCustomStyleInvalidLevel({
        control: (base: any, state: any) => ({
          ...base,
          borderColor: '#ff4d4f',
        }),
      });
    } else {
      setCustomStyleInvalidLevel({
        control: (base: any, state: any) => ({
          ...base,
          borderColor: '#d9d9d9',
        }),
      });
    }
    if (formData.getFieldError('managers')?.length > 0) {
      setCustomStyleInvalidManager({
        control: (base: any, state: any) => ({
          ...base,
          borderColor: '#ff4d4f',
        }),
      });
    } else {
      setCustomStyleInvalidManager({
        control: (base: any, state: any) => ({
          ...base,
          borderColor: '#d9d9d9',
        }),
      });
    }
    if (formData.getFieldError('contractType')?.length > 0) {
      setCustomStyleInvalidContractType({
        control: (base: any, state: any) => ({
          ...base,
          borderColor: '#ff4d4f',
        }),
      });
    } else {
      setCustomStyleInvalidContractType({
        control: (base: any, state: any) => ({
          ...base,
          borderColor: '#d9d9d9',
        }),
      });
    }
    if (formData.getFieldError('location')?.length > 0) {
      setCustomStyleInvalidLocation({
        control: (base: any, state: any) => ({
          ...base,
          borderColor: '#ff4d4f',
        }),
      });
    } else {
      setCustomStyleInvalidLocation({
        control: (base: any, state: any) => ({
          ...base,
          borderColor: '#d9d9d9',
        }),
      });
    }
  };

  useEffect(() => {
    onUpdateStyleSelect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, isInvalidData]);

  const onChangeSelect = () => {
    onUpdateStyleSelect();
  };
  //////////////////////////////////////////////////////////////////////////

  return (
    <>
      <div style={isShowComponents ? {} : { display: 'none' }}>
        <Row gutter={[16, 16]}>
          <Col md={12} xs={24}>
            <CustomFormItem
              label={t('employee:employee_information_fields.work_email')}
              required={true}
              name="email"
              rules={[
                {
                  required:
                    isRequriedCheckSave || isRequriedCheckSendRequest || isRequriedCheckApprove,
                  message: t('HRM_VAD_REQUIRED', {
                    field: t('employee:employee_information_fields.work_email'),
                  }),
                },
                {
                  type: 'email',
                  message: t('HRM_VAD_FORMAT', {
                    field: t('employee:employee_information_fields.work_email'),
                  }),
                },
                {
                  min: 6,
                  message: t('HRM_VAD_LEAST_CHARACTERS', {
                    field: t('employee:employee_information_fields.work_email'),
                    numberLeast: 6,
                  }),
                },
                {
                  max: 64,
                  message: t('HRM_VAD_MAXIMUM_CHARACTERS', {
                    field: t('employee:employee_information_fields.work_email'),
                    numberLeast: 64,
                  }),
                },
                {
                  whitespace: true,
                  message: t('HRM_VAD_BLANK_CHARACTERS', {
                    field: t('employee:employee_information_fields.work_email'),
                  }),
                },
              ]}
            >
              <CustomInput
                disabled={status === 'deleted' ? true : false}
                placeholder={t('employee:employee_information_fields.work_email_placeholder')}
              />
            </CustomFormItem>
          </Col>
          <Col md={4} xs={24}>
            <CustomFormItem
              label={t('employee:employee_information_fields.record_status')}
              name="status"
            >
              <Tag color={PROFILE_STATUS_COLORS[formData.getFieldValue('status')]}>
                {PROFILE_STATUSES[formData.getFieldValue('status')]}
              </Tag>
            </CustomFormItem>
          </Col>
          <Col md={8} xs={24}>
            <CustomFormItem
              label={t('employee:employee_information_fields.employment_end_date')}
              name="endDate"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value && !getFieldValue('startDate')) {
                      return Promise.reject(
                        new Error(
                          t('HRM_VAD_REQUIRED', {
                            field: t('employee:employee_information_fields.employment_start_date'),
                          }),
                        ),
                      );
                    }
                    if (value && getFieldValue('startDate') >= value) {
                      return Promise.reject(
                        new Error(
                          t('HRM_VAD_DATE_GREATER_THAN', {
                            fromDate: t(
                              'employee:employee_information_fields.employment_start_date',
                            ),
                            toDate: t('employee:employee_information_fields.employment_end_date'),
                          }),
                        ),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <CustomDatePicker
                disabled={status === 'deleted' ? true : false}
                placeholder={dataFormatDate.toString().toLowerCase()}
                format={dataFormatDate}
                //disabledDate={disabledEndDate}
              />
            </CustomFormItem>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col md={12} xs={24}>
            <CustomFormItem
              label={t('employee:employee_information_fields.onboard_status')}
              required={true}
              name="onBoardStatus"
              rules={[
                {
                  // required: isRequriedCheckSendRequest || isRequriedCheckApprove,
                  required: true,
                  message: t('HRM_VAD_REQUIRED', {
                    field: t('employee:employee_information_fields.onboard_status'),
                  }),
                },
              ]}
            >
              <Select
                isDisabled={status === 'deleted' ? true : false}
                placeholder={t('employee:employee_information_fields.onboard_status_placeholder')}
                maxMenuHeight={200}
                options={
                  dataConfiguration.dataOnBoardStatus ? dataConfiguration.dataOnBoardStatus : []
                }
                cacheOptions
                defaultOptions
                styles={customStyleInvalidOnBoardStatus}
                onChange={onChangeSelect}
              />
            </CustomFormItem>
          </Col>
          <Col md={12} xs={24}>
            <CustomFormItem
              label={t('employee:employee_information_fields.line_category')}
              name="projectPrimaryName"
            >
              <CustomInput disabled />
            </CustomFormItem>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col md={12} xs={24}>
            <CustomFormItem
              label={t('employee:employee_information_fields.position')}
              required={true}
              name="positions"
              rules={[
                {
                  // required: isRequriedCheckSendRequest || isRequriedCheckApprove,
                  required: true,
                  message: t('HRM_VAD_REQUIRED', {
                    field: t('employee:employee_information_fields.position'),
                  }),
                },
              ]}
            >
              <Select
                isDisabled={status === 'deleted' ? true : false}
                placeholder={t('employee:employee_information_fields.position_placeholder')}
                maxMenuHeight={200}
                options={dataConfiguration.dataPosition ? dataConfiguration.dataPosition : []}
                cacheOptions
                defaultOptions
                styles={customStyleInvalidPosition}
                onChange={onChangeSelect}
              />
            </CustomFormItem>
          </Col>
          <Col md={6} xs={24}>
            <CustomFormItem
              label={t('employee:employee_information_fields.role')}
              required={false}
              name="roles"
            >
              <Select
                isDisabled={status === 'deleted' ? true : false}
                placeholder={t('employee:employee_information_fields.role_placeholder')}
                options={roleOptions}
                cacheOptions
                defaultOptions
                styles={customStyleInvalidRole}
                onChange={onChangeSelect}
              />
            </CustomFormItem>
          </Col>
          <Col md={6} xs={24}>
            <CustomFormItem
              label={t('employee:employee_information_fields.level')}
              required={false}
              name="level"
            >
              <Select
                isDisabled={status === 'deleted' ? true : false}
                placeholder={t('employee:employee_information_fields.level_placeholder')}
                maxMenuHeight={200}
                options={dataConfiguration.dataLevel ? dataConfiguration.dataLevel : []}
                cacheOptions
                defaultOptions
                styles={customStyleInvalidLevel}
                onChange={onChangeSelect}
              />
            </CustomFormItem>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col md={12} xs={24}>
            <CustomFormItem
              label={t('employee:employee_information_fields.direct_manager')}
              required={false}
              name="managers"
            >
              <Select
                isDisabled={status === 'deleted' ? true : false}
                placeholder={t('employee:employee_information_fields.direct_manager_placeholder')}
                maxMenuHeight={200}
                options={managerOptions}
                cacheOptions
                defaultOptions
                styles={customStyleInvalidManager}
                onChange={onChangeSelect}
              />
            </CustomFormItem>
          </Col>
          <Col md={12} xs={24}>
            <CustomFormItem
              label={t('employee:employee_information_fields.tax_code')}
              required={false}
              name="taxCode"
              rules={[
                {
                  pattern: new RegExp(REGEX_NUMBER),
                  message: t('HRM_VAD_FORMAT', {
                    field: t('employee:employee_information_fields.tax_code'),
                  }),
                },
                {
                  max: 30,
                  message: t('HRM_VAD_MAXIMUM_CHARACTERS', {
                    field: t('employee:employee_information_fields.tax_code'),
                    numberLeast: 30,
                  }),
                },
                {
                  whitespace: true,
                  message: t('HRM_VAD_BLANK_CHARACTERS', {
                    field: t('employee:employee_information_fields.tax_code'),
                  }),
                },
              ]}
            >
              <CustomInput
                disabled={status === 'deleted' ? true : false}
                placeholder={t('employee:employee_information_fields.tax_code_placeholder')}
              />
            </CustomFormItem>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col md={12} xs={24}>
            <CustomFormItem
              label={t('employee:employee_information_fields.employment_start_date')}
              required={false}
              name="startDate"
            >
              <CustomDatePicker
                disabled={status === 'deleted' ? true : false}
                placeholder={dataFormatDate.toString().toLowerCase()}
                format={dataFormatDate}
                disabledDate={disabledStartDate}
                onChange={onChangeStartDate}
              />
            </CustomFormItem>
          </Col>
          <Col md={12} xs={24}>
            <CustomFormItem
              label={t('employee:employee_information_fields.social_insurance_number')}
              required={false}
              name="socialInsuranceNumber"
              rules={[
                {
                  pattern: new RegExp(REGEX_NUMBER),
                  message: t('HRM_VAD_FORMAT', {
                    field: t('employee:employee_information_fields.social_insurance_number'),
                  }),
                },
                {
                  max: 30,
                  message: t('HRM_VAD_MAXIMUM_CHARACTERS', {
                    field: t('employee:employee_information_fields.social_insurance_number'),
                    numberLeast: 30,
                  }),
                },
                {
                  whitespace: true,
                  message: t('HRM_VAD_BLANK_CHARACTERS', {
                    field: t('employee:employee_information_fields.social_insurance_number'),
                  }),
                },
              ]}
            >
              <CustomInput
                disabled={status === 'deleted' ? true : false}
                placeholder={t(
                  'employee:employee_information_fields.social_insurance_number_placeholder',
                )}
              />
            </CustomFormItem>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col md={12} xs={24}>
            <CustomFormItem
              label={t('employee:employee_information_fields.location')}
              required={true}
              name="location"
              rules={[
                {
                  // required: isRequriedCheckSendRequest || isRequriedCheckApprove,
                  required: true,
                  message: t('HRM_VAD_REQUIRED', {
                    field: t('employee:employee_information_fields.location'),
                  }),
                },
              ]}
            >
              <Select
                isDisabled={status === 'deleted' ? true : false}
                placeholder={t('employee:employee_information_fields.location_placeholder')}
                maxMenuHeight={200}
                options={dataConfiguration?.dataLocation || []}
                cacheOptions
                defaultOptions
              />
            </CustomFormItem>
          </Col>
        </Row>
        {/* tạm thời ẩn phần này */}
        <Row gutter={[16, 16]} style={{ display: 'none' }}>
          <Col md={12} xs={24}>
            <CustomFormItem
              label={t('employee:employee_information_fields.contract_type')}
              required={false}
              name="contractType"
            >
              <Select
                isDisabled={status === 'deleted' ? true : false}
                placeholder={t('employee:employee_information_fields.contract_type_placeholder')}
                maxMenuHeight={200}
                options={
                  dataConfiguration.dataContractType ? dataConfiguration.dataContractType : []
                }
                cacheOptions
                defaultOptions
                menuPlacement="top"
                styles={customStyleInvalidContractType}
                onChange={onChangeSelect}
              />
            </CustomFormItem>
          </Col>
          <Col md={6} xs={24}>
            <CustomFormItem
              label={t('employee:employee_information_fields.contract_start_date')}
              required={false}
              name="contractStartDate"
            >
              <CustomDatePicker
                disabled={status === 'deleted' ? true : false}
                placeholder={dataFormatDate.toString().toLowerCase()}
                format={dataFormatDate}
                disabledDate={disabledContractStartDate}
              />
            </CustomFormItem>
          </Col>
          <Col md={6} xs={24}>
            <CustomFormItem
              label={t('employee:employee_information_fields.expiration_date')}
              name="contractExpirationDate"
            >
              <CustomDatePicker
                disabled={status === 'deleted' ? true : false}
                placeholder={dataFormatDate.toString().toLowerCase()}
                format={dataFormatDate}
                disabledDate={disabledContractExpirationDate}
              />
            </CustomFormItem>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default forwardRef(EmployeeInformationFields);
