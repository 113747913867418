import { Col, Row, Select } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { FC } from 'react';
import styled from 'styled-components';
import Avatar from './Avatar';
import 'styles/dropdownStyled.scss';
import { DefaultOptionType } from 'antd/lib/select';

const { Option } = Select;

type TITVSelect = {
  loading?: boolean;
  disabled?: boolean;
  dataSource: any[];
  placeholder?: string;
  valueSelected: string;
  width?: number;
  mode?: 'multiple' | 'tags';
  size?: SizeType;
  defaultOption?: { value: string; valueContent: string };
  filterOption?: boolean | ((inputValue: string, option?: DefaultOptionType) => boolean);
  onChangeSelect?: (value: string, key: DefaultOptionType | DefaultOptionType[]) => void;
  [propName: string]: any;
};

const ITVSelect: FC<TITVSelect> = ({
  dataSource,
  placeholder,
  loading,
  disabled,
  valueSelected,
  width,
  mode,
  size = 'large',
  defaultOption,
  filterOption,
  onChangeSelect,
  ...propName
}) => {
  return (
    <WrapperSelect mode={mode} width={width}>
      <Select
        {...propName}
        mode={mode}
        showSearch
        placeholder={placeholder}
        optionFilterProp="children"
        filterOption={filterOption}
        style={{ width: width ? `${width}px` : '300px' }}
        value={valueSelected}
        size={size}
        loading={loading}
        disabled={disabled}
        optionLabelProp={mode && 'label'}
        onChange={onChangeSelect}
        popupClassName={mode && 'dropdown-custom'}
      >
        {defaultOption?.value && (
          <Option value={defaultOption.value}>{defaultOption.valueContent}</Option>
        )}
        {dataSource?.map((itemEmployee: any) => (
          <Option key={itemEmployee.id} value={itemEmployee.id} label={itemEmployee.fullName}>
            <Row>
              <Col span={4} style={{ alignItems: 'center' }}>
                <div style={{ marginTop: '5px' }}>
                  <Avatar name={itemEmployee.fullName} />
                </div>
              </Col>
              <Col span={18}>
                <div
                  data-id="title"
                  style={{
                    fontWeight: 'bold',
                    fontSize: 14,
                    position: 'absolute',
                    top: '2px',
                    left: !mode && width ? `-${(width - 300) / 5}px` : '-4px',
                  }}
                >
                  {itemEmployee.fullName || 'O'}
                </div>
                <div
                  data-id="sub-title"
                  style={{
                    color: '#9CA3AF',
                    fontSize: 12,
                    position: 'absolute',
                    top: '17px',
                    left: !mode && width ? `-${(width - 300) / 5}px` : '-4px',
                  }}
                >
                  {itemEmployee.email}
                </div>
              </Col>
            </Row>
          </Option>
        ))}
      </Select>
    </WrapperSelect>
  );
};

const WrapperSelect = styled.div<{ mode?: 'multiple' | 'tags'; width?: number }>`
  .ant-select div.ant-select-selector {
    height: ${(props) => !props.mode && '45px !important'};
    padding: ${(props) => !props.mode && '0 7px !important'};

    span.ant-select-selection-item {
      line-height: ${(props) => !props.mode && '0'};
      margin-top: 2px;

      div.ant-col.ant-col-4 {
        div {
          margin-top: 3px !important;
        }
      }

      div.ant-col.ant-col-18 {
        position: relative;
        div[data-id='title'] {
          top: -6px !important;
          left: ${(props) => props.width && `-${(props.width - 300) / 5}px`} !important;
        }
        div[data-id='sub-title'] {
          top: 8px !important;
          left: ${(props) => props.width && `-${(props.width - 300) / 5}px`} !important;
        }
      }
    }

    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
    }

    input.ant-select-selection-search-input {
      height: ${(props) => !props.mode && '45px !important'};
    }

    span.ant-select-selection-search {
      left: 6px;
    }
  }
`;

export default ITVSelect;
