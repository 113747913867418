import { DatePicker, Space, Tabs } from 'antd';
import { useAppSelector } from 'app/hooks';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { selectConfiguration } from 'features/configuration/configurationSlice';
import PageHeader from 'components/PageHeader';
import { PayRuns, PayrollByYear, History } from './components';
import { YEAR_FORMAT } from 'constants/payroll';

const { TabPane } = Tabs;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
`;

const AllPayRuns = () => {
  const { t } = useTranslation(['menu', 'action', 'overtime', 'timesheet', 'payroll']);
  const location: any = useLocation();
  const [year, setYear] = useState(moment());
  const [titleHeader, setTitleHeader] = useState('');

  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = (menuStore?.dataScreenManage as any)?.result;

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item: any) => location.pathname === item.path)
      ?.map((item: any) => item.nameScreen);

    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  const onChangeYear = (value: any) => {
    const newValue = moment(value);
    setYear(newValue);
  };

  return (
    <Container>
      <Space>
        <PageHeader title={titleHeader} />
        <DatePicker value={year} picker="year" onChange={onChangeYear} allowClear={false} />
      </Space>

      <Tabs destroyInactiveTabPane defaultActiveKey="1">
        <TabPane tab={t('payroll:payRuns')} key="1">
          <PayRuns year={year} setYear={setYear} />
        </TabPane>

        <TabPane tab={t('payroll:history')} key="2">
          <History year={year} setYear={setYear} />
        </TabPane>

        <TabPane tab={t('payroll:payroll_by_year')} key="3">
          <PayrollByYear year={moment(year).format(YEAR_FORMAT)} />
        </TabPane>
      </Tabs>
    </Container>
  );
};

export default AllPayRuns;
