import { Checkbox, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

import EmployeeColumn from 'components/EmployeeColumn';
import ITVTable from 'components/ITVTable';
import { AdjustmentSalaryMap } from 'types';
import { formatCurrencyUnitVND } from 'features/insurances/constants/common';
import StatusColumnCell from 'features/payroll/pages/ViewEmployee/components/StatusColumnCell';
import { useAppSelector } from 'app/hooks';
import { selectPermissions } from 'features/auth/authSlice';

interface AdjustmentSalaryListProps {
  loading: boolean;
  totalResult: number;
  adjustmentSalaryList: AdjustmentSalaryMap[];
  page: number;
  limit: number;
  setPage: Dispatch<SetStateAction<number>>;
  setLimit: Dispatch<SetStateAction<number>>;
  onTableChange?: (
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any>,
  ) => void;
  onRefresh?: () => void;
}

export function AdjustmentSalaryList({
  loading,
  totalResult,
  adjustmentSalaryList,
  page,
  limit,
  setPage,
  setLimit,
  onTableChange,
  onRefresh,
}: AdjustmentSalaryListProps) {
  const { t } = useTranslation(['employee']);
  const permissions = useAppSelector(selectPermissions);

  const COLUMNS: any = [
    {
      title: t('employee:employee_information_fields.id'),
      dataIndex: 'employeeIdParent',
      key: 'employeeIdParent',
      ellipsis: true,
      width: 60,
      minWidth: 60,
      align: 'center',
      fixed: 'left',
      sorter: true,
      render: function (text: any, record: any, index: any) {
        const trueIndex =
          page === 1 ? index + limit * (page - 1) : index + limit * (page - 1) - limit;

        const obj: any = {
          children: record?.employeeIdParent,
          props: {}, //{ style: { verticalAlign: 'top' } },
        };
        if (index >= 1 && record?.userId === adjustmentSalaryList[trueIndex - 1]?.userId) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== adjustmentSalaryList?.length &&
            record?.userId === adjustmentSalaryList[trueIndex + i]?.userId;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
    {
      title: t('employee:employee_information_fields.employee'),
      dataIndex: 'fullNameParent',
      key: 'fullNameParent',
      fixed: 'left',
      width: 220,
      minWidth: 220,
      ellipsis: true,
      sorter: true,
      render: function (text: any, record: any, index: any) {
        const trueIndex =
          page === 1 ? index + limit * (page - 1) : index + limit * (page - 1) - limit;
        const obj: any = {
          children: (
            <EmployeeColumn
              avatar={record?.fullNameParent}
              fullName={record?.fullNameParent}
              email={record?.emailParent}
            />
          ),
          props: {}, //{ style: { verticalAlign: 'top' } },
        };
        if (index >= 1 && record?.userId === adjustmentSalaryList?.[trueIndex - 1]?.userId) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== adjustmentSalaryList?.length &&
            record?.userId === adjustmentSalaryList?.[trueIndex + i]?.userId;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
    {
      title: t('employee:adjustment_information_fields.adjust_name'),
      dataIndex: 'typeName',
      key: 'typeName',
      width: 200,
      minWidth: 200,
      render: (text: any) => {
        return <div>{text}</div>;
      },
    },
    {
      title: t('employee:allowance_information_fields.is_pay_taxes'),
      dataIndex: 'isTax',
      key: 'isTax',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any) => <Checkbox disabled checked={text} />,
    },
    {
      title: (
        <div>
          <PlusOutlined /> / <MinusOutlined />
        </div>
      ),
      dataIndex: 'increaseFlag',
      key: 'increaseFlag',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any) => (text ? <PlusOutlined /> : <MinusOutlined />),
    },
    {
      title: t('employee:bonus_information_fields.col_month'),
      dataIndex: 'monthYear',
      key: 'monthYear',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: t('employee:advance_information_fields.amount'),
      children: [
        {
          title: t('employee:adjustment_information_fields.detail'),
          dataIndex: 'amount',
          key: 'amount',
          width: 150,
          minWidth: 150,
          align: 'right',
          render: (text: any) => formatCurrencyUnitVND(text, 'VND'),
        },
        {
          title: t('employee:adjustment_information_fields.sum'),
          dataIndex: 'amount',
          key: 'amount',
          width: 150,
          minWidth: 150,
          align: 'right',
          render: function (text: any, record: any, index: any) {
            const trueIndex = index + limit * (page - 1);
            const listUser = adjustmentSalaryList.filter(
              (item: any) => item.userId === record?.userId,
            );
            const obj: any = {
              children: (
                <>
                  {formatCurrencyUnitVND(listUser[listUser?.length - 1]?.sumAdjustment ?? 0, 'VND')}
                </>
              ),
              props: {},
            };
            if (index >= 1 && record?.userId === adjustmentSalaryList?.[trueIndex - 1]?.userId) {
              obj.props.rowSpan = 0;
            } else {
              for (
                let i = 0;
                trueIndex + i !== adjustmentSalaryList?.length &&
                record?.userId === adjustmentSalaryList?.[trueIndex + i]?.userId;
                i += 1
              ) {
                obj.props.rowSpan = i + 1;
              }
            }
            return obj;
          },
        },
      ],
    },
    {
      title: t('employee:adjustment_information_fields.description'),
      dataIndex: 'description',
      key: 'description',
      width: 200,
      align: 'left',
      minWidth: 200,
    },
    {
      title: t('employee:employee_information_fields.action'),
      dataIndex: 'action',
      key: 'action',
      width: 60,
      minWidth: 60,
      align: 'center',
      fixed: 'right',
      render: (status: any, record: any, index: any) => {
        const trueIndex = index + limit * (page - 1);
        const obj: any = {
          children: (
            <StatusColumnCell
              showMenu={'adjustment'}
              t={t}
              isShowFullMenu={true}
              item={{
                id: record.userId,
                fullName: record.fullNameParent,
                employeeId: record.employeeIdParent,
                email: record.emailParent,
              }}
              permissions={permissions}
              callBack={() => onRefresh?.()}
            />
          ),
          props: {},
        };
        if (index >= 1 && record?.userId === adjustmentSalaryList?.[trueIndex - 1]?.userId) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== adjustmentSalaryList?.length &&
            record?.userId === adjustmentSalaryList?.[trueIndex + i]?.userId;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
  ];

  return (
    <ITVTable
      columns={COLUMNS}
      data={adjustmentSalaryList}
      rowKeyMerge={['userId']}
      setSelectedUser={() => {}}
      loading={loading}
      totalResult={totalResult}
      setOffset={setPage}
      offset={page}
      setLimit={setLimit}
      limit={limit}
      onChange={onTableChange}
    />
  );
}
