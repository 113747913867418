import { Table } from 'antd';
import styled from 'styled-components';

import { MONTH_NAMES } from 'constants/commons';
import { formatCurrencyUnitVND } from 'features/insurances/constants/common';
import { PayrollYear } from 'types';

interface PayrollByYearTotalRowProps {
  employeePayrollList: PayrollYear[];
}

const TotalRecordMoney = styled.div`
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
`;

export function PayrollByYearTotalRow({ employeePayrollList }: PayrollByYearTotalRowProps) {
  const totalCompanyCost12MonthsOfAllRecords = employeePayrollList.reduce((acc, employee) => {
    const totalOneRecord = employee?.array.reduce((a, b) => {
      return a + (b?.dataPayroll?.totalSalaryCost || 0);
    }, 0);

    return acc + totalOneRecord;
  }, 0);

  const totalNetPay12MonthsOfAllRecords = employeePayrollList.reduce((acc, employee) => {
    const totalOneRecord = employee?.array.reduce((a, b) => {
      return a + (b?.dataPayroll?.actualReceiveSalary || 0);
    }, 0);

    return acc + totalOneRecord;
  }, 0);

  return (
    <Table.Summary.Row>
      <Table.Summary.Cell index={0} colSpan={3}>
        <strong>Total:</strong>
      </Table.Summary.Cell>

      {MONTH_NAMES.map((_, index) => {
        const totalSalaryCost = employeePayrollList.reduce((acc, employee) => {
          return acc + (employee?.array?.[index]?.dataPayroll?.totalSalaryCost || 0);
        }, 0);

        const actualReceiveSalary = employeePayrollList.reduce((acc, employee) => {
          return acc + (employee?.array?.[index]?.dataPayroll?.actualReceiveSalary || 0);
        }, 0);

        return (
          <>
            <Table.Summary.Cell index={4} colSpan={1} className="ant-table-cell">
              <TotalRecordMoney>{formatCurrencyUnitVND(totalSalaryCost, 'VND')}</TotalRecordMoney>
            </Table.Summary.Cell>

            <Table.Summary.Cell index={4} colSpan={1} className="ant-table-cell">
              <TotalRecordMoney>
                {formatCurrencyUnitVND(actualReceiveSalary, 'VND')}
              </TotalRecordMoney>
            </Table.Summary.Cell>
          </>
        );
      })}

      <Table.Summary.Cell index={4} colSpan={1} className="ant-table-cell">
        <TotalRecordMoney>
          {formatCurrencyUnitVND(totalCompanyCost12MonthsOfAllRecords, 'VND')}
        </TotalRecordMoney>
      </Table.Summary.Cell>

      <Table.Summary.Cell index={4} colSpan={1} className="ant-table-cell">
        <TotalRecordMoney>
          {formatCurrencyUnitVND(totalNetPay12MonthsOfAllRecords, 'VND')}
        </TotalRecordMoney>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  );
}
