export const createRootElement = (id: string) => {
  const rootContainer = document.createElement('div');

  rootContainer.setAttribute('id', id);

  return rootContainer;
};

export const addRootElement = (rootElement: any) => {
  document.body.insertBefore(rootElement, document.body.lastElementChild!.nextElementSibling);
};

export const scrollIntoView = (id: string) => {
  document.getElementById(id)?.scrollIntoView();
};
