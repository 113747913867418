import React, { useMemo } from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';
import Avatar from 'components/Avatar';
import { Tooltip } from 'antd';

const TooltipTitle = (props: any) => {
  const { fullName, email } = props;
  return (
    <div>
      <div>Name: {fullName}</div>
      <div>Email: {email}</div>
    </div>
  );
};

TooltipTitle.propTypes = {
  fullName: string.isRequired,
  email: string.isRequired,
};

const EmployeeColumn = (props: any) => {
  const { avatar, fullName, email } = props;
  const TooltipTitleMemo = useMemo(
    () => <TooltipTitle fullName={fullName} email={email} />,
    [fullName, email],
  );
  return (
    <Container>
      <Avatar name={avatar} />
      <Tooltip title={TooltipTitleMemo}>
        <Info>
          <InfoName>{fullName}</InfoName>
          <InfoEmail>{email}</InfoEmail>
        </Info>
      </Tooltip>
    </Container>
  );
};

EmployeeColumn.propTypes = {
  fullName: string.isRequired,
  email: string.isRequired,
  avatar: string.isRequired,
};

export default React.memo(EmployeeColumn);

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 320px;
  min-width: 270px;
`;

export const Info = styled.div`
  margin-left: 1rem;
  width: 320px;
  min-width: 270px;
`;

export const InfoName = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* color: #111827; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InfoEmail = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #9ca3af;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
