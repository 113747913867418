import { MailOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Card, Col, Input, InputNumber, Menu, Row, Select, Tooltip } from 'antd';
import { selectCurrencyFormat } from 'app/commonRedux/appSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import EmployeeColumn from 'components/EmployeeColumn';
import { getPayrollGroupUser, getPayrollUser } from 'features/payroll/payrollAction';
import { selectPayroll } from 'features/payroll/payrollSlice';
import { TaxProfiles } from 'hrm-common/extensions/enums/personel';
import moment from 'moment';
import numeral from 'numeral';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import { getUser } from 'utils/auth';
import { capitalizeFirstLetter } from 'utils/text';
import EmployeeUpdateModal from '../ViewEmployee/EmployeeUpdateModal';
import { checkPermissionCurrentUser } from './functionForWorkFlow';

type MenuItem = Required<MenuProps>['items'][number];
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const listOptionType = [
  {
    label: <PlusOutlined />,
    value: true,
  },
  {
    label: <MinusOutlined />,
    value: false,
  },
];

const ViewEdit = (props: any) => {
  const { t } = useTranslation([
    'action',
    'dashboard',
    'employee',
    'onboard',
    'timesheet',
    'payroll',
  ]);
  const { id }: any = useParams();
  const { currentItem, workflow, status, callBack: callGroupUser } = props;
  const dispatch = useAppDispatch();
  const [isVisibleEmployeeUpdate, setIsVisibleEmployeeUpdate] = useState(false);
  const [nameModalEdit, setNameModalEdit] = useState('');
  const payrollUser = useAppSelector(selectPayroll)?.payrollUser;
  const [data, setData] = useState<any>({}); //dataMockDetail
  const currencyFormat = useAppSelector(selectCurrencyFormat);

  useEffect(() => {
    const params = {
      userId: currentItem?.user?.id,
      payrollDetailsId: id,
    };
    dispatch(getPayrollUser(params));
  }, [id, currentItem, dispatch]);

  useEffect(() => {
    setData(payrollUser);
  }, [payrollUser]);

  const handleEditAllowance = () => {
    setIsVisibleEmployeeUpdate(true);
    setNameModalEdit('allowance');
  };
  const handleEditBonus = () => {
    setIsVisibleEmployeeUpdate(true);
    setNameModalEdit('bonus');
  };
  const handleEditAdvance = () => {
    setIsVisibleEmployeeUpdate(true);
    setNameModalEdit('advances');
  };
  const handleEditAdjustment = () => {
    setIsVisibleEmployeeUpdate(true);
    setNameModalEdit('adjustment');
  };
  const handleEditDeduction = () => {
    setIsVisibleEmployeeUpdate(true);
    setNameModalEdit('dependent');
  };

  const handleEditContract = () => {
    setIsVisibleEmployeeUpdate(true);
    setNameModalEdit('contract');
  };

  const enableEdit = () => {
    return checkPermissionCurrentUser(workflow, 'edit', status, getUser()?.id);
  };

  const items: MenuItem[] = [
    getItem(
      <TitleMenu>
        <span>{t('payroll:total_income_period')}</span>
        <span>
          {t('payroll:total')}:{' '}
          {numeral(data?.dataPayroll?.totalIncomeBeforeTax || 0).format(currencyFormat)}
        </span>
      </TitleMenu>,
      'sub1',
      <MailOutlined />,
      [
        getItem(
          <CustomCard style={{ background: '#fafafa' }}>
            <CustomRow gutter={[16, 16]}>
              <Col span={6}>
                {t('payroll:gross')} {t('payroll:salary')}
              </Col>
              <Col span={18}>
                <CustomInputNumber
                  maxLength={255}
                  value={data?.dataPayroll?.dataPayroll?.salary?.grossSalary || 0}
                  formatter={(value: any) => numeral(value).format(currencyFormat)}
                  parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
                  disabled
                />
              </Col>
            </CustomRow>

            <CustomRow gutter={[16, 16]}>
              <Col span={6}>{t('employee:contract_information_fields.ratio')}</Col>
              <Col span={18}>
                <CustomInputNumber
                  maxLength={255}
                  value={data?.dataPayroll?.dataPayroll?.ratio || 0}
                  formatter={(value: any) => numeral(value).format(currencyFormat)}
                  parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
                  disabled
                />
              </Col>
            </CustomRow>

            <CustomRow gutter={[16, 16]}>
              <Col span={6}>
                {t('payroll:main')} {t('payroll:salary')}
              </Col>
              <Col span={18}>
                <CustomInputNumber
                  maxLength={255}
                  value={data?.dataPayroll?.dataPayroll?.salary?.mainSalary || 0}
                  formatter={(value: any) => numeral(value).format(currencyFormat)}
                  parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
                  disabled
                />
              </Col>
            </CustomRow>

            {data?.dataPayroll?.dataPayroll?.salary?.allowances
              ?.filter((item: any) => item?.isGross)
              .map((item: any, idx: any) => {
                return (
                  <CustomRow gutter={[16, 16]}>
                    <Col
                      span={6}
                      style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                    >
                      <Tooltip title={capitalizeFirstLetter(item?.name)}>
                        {capitalizeFirstLetter(item?.name)}
                      </Tooltip>
                    </Col>
                    <Col span={18}>
                      <CustomInputNumber
                        maxLength={255}
                        value={item?.money || 0}
                        formatter={(value: any) => numeral(value).format(currencyFormat)}
                        parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
                        disabled
                      />
                    </Col>
                  </CustomRow>
                );
              })}

            <CustomRow gutter={[16, 16]}>
              <Col span={6}>{t('payroll:actual_hours')}</Col>
              <Col span={18}>
                <CustomInputNumber
                  maxLength={255}
                  value={data?.dataPayroll?.dataPayroll?.timeSheet?.actualHours || 0}
                  disabled
                />
              </Col>
            </CustomRow>
            <CustomRow gutter={[16, 16]}>
              <Col span={6}>{t('payroll:leave_off_hours')}</Col>
              <Col span={18}>
                <CustomInputNumber
                  maxLength={255}
                  value={data?.dataPayroll?.dataPayroll?.timeSheet?.leaveOffHours || 0}
                  disabled
                />
              </Col>
            </CustomRow>
            <CustomRow gutter={[16, 16]}>
              <Col span={6}>{t('payroll:total_paid_hours')}</Col>
              <Col span={18}>
                <CustomInputNumber
                  maxLength={255}
                  value={data?.dataPayroll?.dataPayroll?.timeSheet?.totalPaidHours || 0}
                  disabled
                />
              </Col>
            </CustomRow>
            <CustomRow gutter={[16, 16]}>
              <Col span={6}>
                {t('payroll:taxable')} {t('payroll:overtime')}
              </Col>
              <Col span={18}>
                <CustomInputNumber
                  maxLength={255}
                  value={data?.dataPayroll?.taxableOvertimePayment || 0}
                  formatter={(value: any) => numeral(value).format(currencyFormat)}
                  parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
                  disabled
                />
              </Col>
            </CustomRow>
            <CustomRow gutter={[16, 16]}>
              <Col span={6}>
                {t('payroll:non_taxable')} {t('payroll:overtime')}
              </Col>
              <Col span={18}>
                <CustomInputNumber
                  maxLength={255}
                  value={data?.dataPayroll?.nonTaxableOvertimePayment || 0}
                  formatter={(value: any) => numeral(value).format(currencyFormat)}
                  parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
                  disabled
                />
              </Col>
            </CustomRow>

            <CustomRow gutter={[16, 16]}>
              <Col span={6}>{t('dashboard:contract.contract_type')}</Col>
              <Col span={18}>
                <Input
                  maxLength={255}
                  value={
                    data?.dataPayroll?.dataPayroll?.salary?.name ===
                    TaxProfiles.MORE_THAN_THREE_MONTH
                      ? t('timesheet:full_time')
                      : data?.dataPayroll?.dataPayroll?.salary?.name ===
                        TaxProfiles.LESS_THAN_THREE_MONTH
                      ? t('timesheet:part_time')
                      : data?.dataPayroll?.dataPayroll?.salary?.name === TaxProfiles.INTERN
                      ? t('employee:contract_information_fields.intern')
                      : ''
                  }
                  disabled
                />
              </Col>
            </CustomRow>

            {enableEdit() && (
              <WrapButtonEdit>
                <CustomButton type="primary" style={{ marginLeft: 5 }} onClick={handleEditContract}>
                  {t('action:edit')}
                </CustomButton>
              </WrapButtonEdit>
            )}
          </CustomCard>,
          '1',
        ),
      ],
    ),

    getItem(
      <TitleMenu>
        <span>{t('payroll:allowance')}</span>
        <span>
          {t('payroll:total')}:{' '}
          {numeral(data?.dataPayroll?.totalAllowanceNotGross || 0).format(currencyFormat)}
        </span>
      </TitleMenu>,
      'sub2',
      <MailOutlined />,
      [
        getItem(
          <CustomCard style={{ background: '#fafafa' }}>
            {data?.dataPayroll?.dataPayroll?.salary?.allowances
              ?.filter((item: any) => !item?.isGross)
              ?.map((item: any, idx: any) => {
                return (
                  <CustomRow gutter={[16, 16]}>
                    <Col span={6}>{capitalizeFirstLetter(item?.name)}</Col>
                    <Col span={18}>
                      <CustomInputNumber
                        maxLength={255}
                        value={item.money || 0}
                        formatter={(value: any) => numeral(value).format(currencyFormat)}
                        parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
                        disabled
                      />
                    </Col>
                  </CustomRow>
                );
              })}
            {enableEdit() && (
              <WrapButtonEdit>
                <CustomButton
                  type="primary"
                  style={{ marginLeft: 5 }}
                  onClick={handleEditAllowance}
                >
                  {t('action:edit')}
                </CustomButton>
              </WrapButtonEdit>
            )}
          </CustomCard>,
          '2',
        ),
      ],
    ),
    getItem(
      <TitleMenu>
        <span>{t('payroll:bonus')}</span>
        <span>
          {t('payroll:total')}:{' '}
          {numeral(data?.dataPayroll?.totalIncomeBonus || 0).format(currencyFormat)}
        </span>
      </TitleMenu>,
      'sub3',
      <MailOutlined />,
      [
        getItem(
          <CustomCard>
            {data?.dataPayroll?.dataPayroll?.bonus?.map((item: any, idx: any) => {
              return (
                <CustomRow gutter={[16, 16]}>
                  <Col span={6}>{capitalizeFirstLetter(item?.name)}</Col>
                  <Col span={18}>
                    <CustomInputNumber
                      maxLength={255}
                      value={item.money || 0}
                      formatter={(value: any) => numeral(value).format(currencyFormat)}
                      parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
                      disabled
                    />
                  </Col>
                </CustomRow>
              );
            })}
            {enableEdit() && (
              <WrapButtonEdit>
                <CustomButton type="primary" style={{ marginLeft: 5 }} onClick={handleEditBonus}>
                  {t('action:edit')}
                </CustomButton>
              </WrapButtonEdit>
            )}
          </CustomCard>,
          '3',
        ),
      ],
    ),

    getItem(
      <TitleMenu>
        <span>{t('payroll:deduction')}</span>
        <span>
          {t('payroll:total')}:{' '}
          {numeral(data?.dataPayroll?.totalAmountDeduction || 0).format(currencyFormat)}
        </span>
      </TitleMenu>,
      'sub4',
      <MailOutlined />,
      [
        getItem(
          <CustomCard style={{ background: '#fafafa' }}>
            <CustomRow gutter={[16, 16]}>
              <Col span={6}>{t('payroll:total_amount_for_dependents')}</Col>
              <Col span={18}>
                <CustomInputNumber
                  maxLength={255}
                  value={data?.dataPayroll?.dataPayroll?.deduction?.dependant || 0}
                  formatter={(value: any) => numeral(value).format(currencyFormat)}
                  parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
                  disabled
                />
              </Col>
            </CustomRow>

            <CustomRow gutter={[16, 16]}>
              <Col span={6}>{t('payroll:total_amount_for_person')}</Col>
              <Col span={18}>
                <CustomInputNumber
                  maxLength={255}
                  value={data?.dataPayroll?.dataPayroll?.deduction?.dependantForPerson || 0}
                  formatter={(value: any) => numeral(value).format(currencyFormat)}
                  parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
                  disabled
                />
              </Col>
            </CustomRow>

            <CustomRow gutter={[16, 16]}>
              <Col span={6}>{t('payroll:no_of_dependents')}</Col>
              <Col span={18}>
                <CustomInputNumber
                  maxLength={255}
                  value={data?.dataPayroll?.dataPayroll?.deduction?.totalPersonDependant || 0}
                  disabled
                />
              </Col>
            </CustomRow>

            {enableEdit() && (
              <WrapButtonEdit>
                <CustomButton
                  type="primary"
                  style={{ marginLeft: 5 }}
                  onClick={handleEditDeduction}
                >
                  {t('action:edit')}
                </CustomButton>
              </WrapButtonEdit>
            )}
          </CustomCard>,
          '4',
        ),
      ],
    ),

    getItem(
      <TitleMenu>
        <span>{t('payroll:personal_income_tax')}</span>
        <span>
          {t('payroll:total')}:{' '}
          {numeral(data?.dataPayroll?.totalTaxPersonIncome || 0).format(currencyFormat)}
        </span>
      </TitleMenu>,
      'sub5',
      <MailOutlined />,
      [
        getItem(
          <CustomCard>
            <CustomRow gutter={[16, 16]}>
              <Col span={6}>{t('payroll:pitDeduction')}</Col>
              <Col span={18}>
                <CustomInputNumber
                  maxLength={255}
                  value={data?.dataPayroll?.totalNonTaxPersonIncome || 0}
                  formatter={(value: any) => numeral(value).format(currencyFormat)}
                  parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
                  disabled
                />
              </Col>
            </CustomRow>

            <CustomRow gutter={[16, 16]}>
              <Col span={6}>{t('payroll:taxedIncome')}</Col>
              <Col span={18}>
                <CustomInputNumber
                  maxLength={255}
                  value={data?.dataPayroll?.taxedIncome || 0}
                  formatter={(value: any) => numeral(value).format(currencyFormat)}
                  parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
                  disabled
                />
              </Col>
            </CustomRow>

            <CustomRow gutter={[16, 16]}>
              <Col span={6}>{t('payroll:personal_income_tax')}</Col>
              <Col span={18}>
                <CustomInputNumber
                  maxLength={255}
                  value={data?.dataPayroll?.totalTaxPersonIncome || 0}
                  formatter={(value: any) => numeral(value).format(currencyFormat)}
                  parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
                  disabled
                />
              </Col>
            </CustomRow>
          </CustomCard>,
          '5',
        ),
      ],
    ),
    getItem(
      <TitleMenu>
        <span>{t('payroll:employee_contributions')}</span>
        <span>
          {t('payroll:total')}:{' '}
          {numeral(
            data?.dataPayroll?.dataPayroll?.insuranceIncomeTax?.contribuEmployee || 0,
          ).format(currencyFormat)}
        </span>
      </TitleMenu>,
      'sub6',
      <MailOutlined />,
      [
        getItem(
          <CustomCard>
            <CustomRow gutter={[16, 16]}>
              <Col span={6}>{t('payroll:shuInsurance')}</Col>
              <Col span={18}>
                <CustomInputNumber
                  maxLength={255}
                  value={data?.dataPayroll?.dataPayroll?.insuranceIncomeTax?.contribuEmployee || 0}
                  formatter={(value: any) => numeral(value).format(currencyFormat)}
                  parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
                  disabled
                />
              </Col>
            </CustomRow>
          </CustomCard>,
          '6',
        ),
      ],
    ),
    getItem(
      <TitleMenu>
        <span>{t('payroll:advance')}</span>
        <span>
          {t('payroll:total')}:{' '}
          {numeral(data?.dataPayroll?.totalAdvance || 0).format(currencyFormat)}
        </span>
      </TitleMenu>,
      'sub7',
      <MailOutlined />,
      [
        getItem(
          <CustomCard>
            {data?.dataPayroll?.dataPayroll?.advance?.map((item: any, idx: any) => {
              return (
                <CustomRow gutter={[16, 16]}>
                  <Col span={6}>{capitalizeFirstLetter(item?.name)}</Col>
                  <Col span={18}>
                    <CustomInputNumber
                      maxLength={255}
                      value={item?.amount || 0}
                      formatter={(value: any) => numeral(value).format(currencyFormat)}
                      parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
                      disabled
                    />
                  </Col>
                </CustomRow>
              );
            })}
            {enableEdit() && (
              <WrapButtonEdit>
                <CustomButton type="primary" style={{ marginLeft: 5 }} onClick={handleEditAdvance}>
                  {t('action:edit')}
                </CustomButton>
              </WrapButtonEdit>
            )}
          </CustomCard>,
          '7',
        ),
      ],
    ),
    getItem(
      <TitleMenu>
        <span>{t('payroll:adjustment')}</span>
        <span>
          {t('payroll:total')}:{' '}
          {numeral(data?.dataPayroll?.totalIncomeAdjustment || 0).format(currencyFormat)}
        </span>
      </TitleMenu>,
      'sub8',
      <MailOutlined />,
      [
        getItem(
          <CustomCard>
            {data?.dataPayroll?.dataPayroll?.adjustment?.map((item: any, idx: any) => {
              return (
                <CustomRow gutter={[16, 16]}>
                  <Col span={6}>{capitalizeFirstLetter(item?.name)}</Col>
                  <Col span={5}>
                    <Select
                      disabled
                      value={item?.increaseFlag ? listOptionType[0] : listOptionType[1]}
                      style={{ width: '100%' }}
                    >
                      {listOptionType.map((item: any, key: any) => (
                        <Select.Option key={key} value={item.value}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={13}>
                    <CustomInputNumber
                      maxLength={255}
                      value={item?.amount || 0}
                      formatter={(value: any) => numeral(value).format(currencyFormat)}
                      parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
                      disabled
                    />
                  </Col>
                </CustomRow>
              );
            })}
            {enableEdit() && (
              <WrapButtonEdit>
                <CustomButton
                  type="primary"
                  style={{ marginLeft: 5 }}
                  onClick={handleEditAdjustment}
                >
                  {t('action:edit')}
                </CustomButton>
              </WrapButtonEdit>
            )}
          </CustomCard>,
          '8',
        ),
      ],
    ),
    getItem(
      <TitleMenu>
        <span>{t('payroll:totalCompanyCost')}</span>
        <span>
          {t('payroll:total')}:{' '}
          {numeral(data?.dataPayroll?.totalSalaryCost || 0).format(currencyFormat)}
        </span>
      </TitleMenu>,
      'sub9',
      <MailOutlined />,
      [
        getItem(
          <CustomCard>
            <CustomRow gutter={[16, 16]}>
              <Col span={6}>{t('payroll:totalIncomeThisPeriod')}</Col>
              <Col span={18}>
                <CustomInputNumber
                  maxLength={255}
                  // value={data?.dataPayroll?.totalIncomeBeforeTax || 0}
                  value={184.49}
                  formatter={(value: any) => numeral(value).format(currencyFormat)}
                  parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
                  disabled
                />
              </Col>
            </CustomRow>

            <CustomRow gutter={[16, 16]}>
              <Col span={6}>{t('payroll:employer_contributions')}</Col>
              <Col span={18}>
                <CustomInputNumber
                  maxLength={255}
                  value={data?.dataPayroll?.dataPayroll?.insuranceIncomeTax?.contribuEmployer || 0}
                  formatter={(value: any) => numeral(value).format(currencyFormat)}
                  parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
                  disabled
                />
              </Col>
            </CustomRow>
          </CustomCard>,
          '9',
        ),
      ],
    ),
  ];
  const onClick: MenuProps['onClick'] = (e) => {};
  const handleCloseEmployeeUpdate = () => {
    setIsVisibleEmployeeUpdate(false);
  };

  const callBack = () => {
    const params = {
      userId: currentItem?.user?.id,
      payrollDetailsId: id,
    };
    dispatch(getPayrollUser(params));
    callGroupUser && callGroupUser();
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <EmployeeColumn
            avatar={
              data?.user?.fullName
                ? `${data?.user?.fullName}`
                : `${data?.user?.lastName} ${data?.user?.firstName}`
            }
            fullName={
              data?.user?.fullName
                ? `${data?.user?.fullName}`
                : `${data?.user?.lastName} ${data?.user?.firstName}`
            }
            email={data?.user?.email}
          />
        </Col>
        <Col span={8}>
          <ColTitle span={12}>{t('payroll:net_pay')}</ColTitle>
          <ColValue span={12}>
            {numeral(data?.dataPayroll?.actualReceiveSalary || 0).format(currencyFormat)}
          </ColValue>
        </Col>
        <Col span={8}>
          <ColTitle span={12}>{t('payroll:total_cost')}</ColTitle>
          <ColValue span={12}>
            {numeral(data?.dataPayroll?.totalSalaryCost || 0).format(currencyFormat)}
          </ColValue>
        </Col>
      </Row>
      <CustomMenu
        onClick={onClick}
        // style={{ width: 256 }}
        defaultOpenKeys={['sub1']}
        mode="inline"
        items={items}
      />

      <EmployeeUpdateModal
        showMenu={nameModalEdit}
        isVisible={isVisibleEmployeeUpdate}
        isShowFullMenu={true}
        itemId={currentItem?.user?.id}
        onClose={handleCloseEmployeeUpdate}
        payrollUpdate={{
          user: currentItem?.user,
          userId: currentItem?.user?.id,
          monthYear: data?.monthYear
            ? moment(data?.monthYear).format('YYYY-MM')
            : moment().format('YYYY-MM'),
          callBack: callBack,
        }}
      />
    </>
  );
};

export default ViewEdit;
export const ColTitle = styled(Col)`
  font-size: 20px;
  font-weight: 700;
`;
export const ColValue = styled(Col)`
  font-size: 20px;
  font-weight: 700;
  color: #fea481;
`;
export const CustomRow = styled(Row)`
  margin-top: 15px;
`;
export const CustomCard = styled(Card)`
  background: #fafafa;
  border: none;
`;
export const CustomMenu = styled(Menu)`
  .ant-menu .ant-menu-item {
    height: 100% !important;
  }
  &.ant-menu {
    width: 100% !important;
  }
  &.ant-menu .ant-menu-submenu {
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-menu-inline .ant-menu-item::after {
    border-right: none;
  }
  .ant-menu-item:active {
    background: none;
  }
  .ant-menu .ant-menu-item-selected {
    background: none !important;
  }
`;
export const TitleMenu = styled.div`
  font-weight: 700;
  display: flex;
  justify-content: space-between;
`;
export const CustomInputNumber = styled(InputNumber)`
  &.ant-input-number {
    width: 100%;
  }
  .ant-input-number-input {
    text-align: right;

    // padding-right:1px;
  }
  .ant-input-number-handler-wrap {
    padding-right: 5px;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
`;
export const WrapButtonEdit = styled.div`
  text-align: right;
  margin-top: 5px;
`;
